import React from 'react';
import { Container } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Link, useLocation } from 'react-router-dom';

import doctor from '../../common/assets/images/doctor.png';
import logo from '../../common/assets/images/loginLogo.png';
import { doctorRoute, route } from '../../common/constants/routes';
import ContactSupport from '../ContactSupport/ContactSupport';
import './AuthFormStyles.scss';

interface AuthFormProps {
    responsiveClass?: string;
    children: React.ReactNode;
    backgroundClass?: string;
    isShowContactSupport?: boolean;
    isShowLogo?: boolean;
    isShowRedirect?: boolean;
    textRedirect?: string;
    linkRedirect?: string;
    currentLocation?: string;
    cardClass?: React.ReactNode;
    cardContainerClass?: React.ReactNode;
}

const AuthForm: React.FunctionComponent<AuthFormProps> = ({
    children,
    responsiveClass,
    backgroundClass,
    isShowContactSupport,
    isShowLogo,
    isShowRedirect,
    textRedirect,
    linkRedirect,
    currentLocation,
    cardClass,
    cardContainerClass,
}) => {
    const doctorRouteValues = Object.values(doctorRoute);
    const location = useLocation();
    const isPathActive = location.pathname;
    const isDoctorAuth = !!doctorRouteValues.find((route) => route === location.pathname);

    return (
        <Container className={`forgot-password ${responsiveClass} ${backgroundClass}`} fluid>
            <div className="row-container">
                {isPathActive !== currentLocation && isPathActive !== '/editor/login' && (
                    <div className="image">
                        <Image src={doctor} className="image-doctor" />
                    </div>
                )}
                <div className={`top-space mb-5 ${cardContainerClass}`}>
                    <div className={`card ${cardClass}`}>
                        <div className="pt-0 card-body">
                            {!isShowLogo && (
                                <div className="p-3 text-center">
                                    <Image src={logo} className="image-logo" />
                                </div>
                            )}
                            {children}
                            {isShowRedirect && (
                                <div className="mt-5 px-0 mb-3">
                                    {textRedirect && linkRedirect ? (
                                        <Link to={linkRedirect} className="paragraph-extra backBtn">
                                            {textRedirect}
                                        </Link>
                                    ) : (
                                        <Link
                                            to={isDoctorAuth ? doctorRoute.SIGN_IN : route.LOGIN}
                                            className="paragraph-extra backBtn"
                                        >
                                            Back to Sign in
                                        </Link>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {isShowContactSupport && <ContactSupport cardClass={cardClass} />}
                </div>
            </div>
        </Container>
    );
};

export default AuthForm;
