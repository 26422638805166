import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { doctorRoute } from '../../../../common/constants/routes';
import { useQuery } from '../../../../common/custom-hooks/queryHook';
import ButtonForm from '../../../../components/ButtonForm/ButtonForm';
import DoctorBackground from '../../../../components/DoctorAuthBackground/DoctorAuthBackground';

import './WelcomeStyles.scss';

const Welcome: React.FC = (): React.ReactElement => {
    const history = useHistory();
    const query = useQuery();
    const invitationCode = query.get('invitationCode') as string;
    const name = query.get('name') as string;

    useEffect(() => {
        if (invitationCode && name)
            history.push(doctorRoute.CHANGE_PASSWORD_FIRST_TIME, {
                invitationCode: invitationCode,
                name: name,
            });
    }, []);

    return (
        <DoctorBackground>
            <div className="button-group">
                <ButtonForm
                    className="btn-welcome"
                    buttonName="SIGN IN"
                    handleSubmit={() => {
                        history.push(doctorRoute.SIGN_IN);
                    }}
                />
                <ButtonForm
                    buttonName="SIGN UP"
                    className="btn-welcome"
                    handleSubmit={() => {
                        history.push(doctorRoute.REGISTRATION);
                    }}
                />
            </div>
            <div className="contact note">
                <label className="d-block m-0">Need help?</label>
                <label className="d-block mt-2">
                    <span>
                        Please contact&nbsp;
                        <a href="mailto:support@jivescribe.com" className="text-note">
                            <span>support@jivescribe.com&nbsp;</span>
                        </a>
                        for support.
                    </span>
                </label>
            </div>
        </DoctorBackground>
    );
};

export default Welcome;
