import React, { useEffect, useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';

import './SearchStyles.scss';

interface SearchPropsType {
    onSearch: (value: string) => void;
    defaultInputSearch?: string;
    className?: string;
    isOpen?: boolean;
}

export const Search = (props: SearchPropsType) => {
    const [searchKey, setSearchKey] = useState<string>('');

    const handleChangeSearchKey = (e: any) => {
        setSearchKey(e.target.value);
    };

    const handleSearch = () => {
        setSearchKey(searchKey.trim());
        props.onSearch(searchKey.trim());
    };

    useEffect(() => {
        if (props.defaultInputSearch?.length) {
            setSearchKey(props.defaultInputSearch);
        } else setSearchKey(searchKey.trim());
    }, [props.defaultInputSearch]);

    return (
        <div className="col-md-3 p-0 search-component">
            <InputGroup>
                <FormControl
                    aria-describedby="basic-addon2"
                    className="text-truncate"
                    value={searchKey}
                    onChange={handleChangeSearchKey}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />
                <InputGroup.Append>
                    {props.isOpen ? (
                        <Button
                            active
                            variant="light"
                            onClick={handleSearch}
                            className={`px-3 search-btn ${props.className}`}
                        >
                            Search
                        </Button>
                    ) : (
                        <Button variant="primary" onClick={handleSearch} className={`px-3 search-btn`}>
                            Search
                        </Button>
                    )}
                </InputGroup.Append>
            </InputGroup>
        </div>
    );
};
