import React from 'react';

import { UserStatus } from '../../../../common/constants/enum';
import { UserSelected } from '../../../../pages/editor-pages/users/UserHelper';
import './CustomStatusUserCell.scss';

export interface Row {
    id: number;
    firstName: string;
    lastName: string;
    status: string;
    email: string;
    isActivated: boolean;
}
export interface ChangeStatusAction {
    handleChangeStatus: (userSelected: UserSelected) => void;
    isAdminRole: boolean;
}

interface ActionsCustomStatusUserCellProps {
    row: Row;
    actions: ChangeStatusAction;
}

export const CustomStatusUserCell: React.FunctionComponent<ActionsCustomStatusUserCellProps> = ({
    row,
    actions,
}): React.ReactElement => {
    const userSelected: UserSelected = {
        id: row.id,
        firstName: row.firstName,
        lastName: row.lastName,
        status: row.status,
        email: row.email,
    };
    return (
        <div className="toggle-container">
            {!row.isActivated ? (
                <span>Inactivated</span>
            ) : actions.isAdminRole ? (
                <div
                    className={`toggle ${row.status === UserStatus.Activated ? 'activated' : 'deactivated'}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        actions.handleChangeStatus(userSelected);
                    }}
                >
                    <div className="toggle__status">{row.status}</div>
                    <div className="toggle__rounded"></div>
                </div>
            ) : (
                <span>{row.status}</span>
            )}
        </div>
    );
};
