import React from 'react';

import './DeleteColumnFormatterStyles.scss';
import trashIcon from '../../../../../common/assets/images/trashIcon2.png';

export interface Row {
    id: number;
    fileName?: string;
}

export interface DeleteTemplateAction {
    handleDelete: (id: number) => void;
}

interface ActionsColumnFormatterProps {
    row: Row;
    actions: DeleteTemplateAction;
}

export const DeleteColumnFormatter: React.FunctionComponent<ActionsColumnFormatterProps> = ({
    row,
    actions,
}): React.ReactElement => {
    return (
        <div className="delete-icon" onClick={() => actions.handleDelete(row.id)}>
            {/* <img src={trashIcon} alt="" /> */}
        </div>
    );
};
