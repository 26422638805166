import React from 'react';
import { Button } from 'react-bootstrap';

import { TranscriptionResponse } from '../../../../../common/services/apiTypes';
import dictationsServices from '../../../../../common/services/dictationsServices';
import { baseURL } from '../../../../../common/utils/axiosClient';
import fileIcon from '../../../../../common/assets/images/fileIcon.png';
import { formatter } from '../../DictationsDetailHelper';
import { sliceFileName } from '../../../../../common/utils/converter';

const fileFormatter = (cell: string, row: TranscriptionResponse): JSX.Element => (
    <Button
        href={`${baseURL}/dictation/transcription/get-file/${row.id}`}
        variant="light"
        className="py-1 pl-2 button-row"
    >
        <img className="pr-2" src={fileIcon} alt="" />
        {sliceFileName(cell)}
    </Button>
);

export const attachTranscriptionRenderColumns = (
    handleAction: (id: number) => void,
    isExistEIC: boolean,
    hideAllTrashIcon: boolean,
) => [
    {
        dataField: 'fileName',
        text: 'Upload Files',
        sort: false,
        headerStyle: () => {
            return { borderRight: 'unset' };
        },
        formatter: fileFormatter,
    },
    {
        dataField: '',
        text: '',
        formatter: formatter,
        formatExtraData: {
            actionDelete: handleAction,
            hideAllTrashIcon,
        },
        classes: 'text-center icon-table',
        style: {
            minWidth: '140px',
            opacity: isExistEIC ? '1' : '0.5',
        },
        headerStyle: () => {
            return { width: '7%' };
        },
    },
];

export const attachTranscriptionTableActions = {
    getAttachTranscriptions: async (dictationId: string, setListAT: (listAt: TranscriptionResponse[]) => void) => {
        try {
            const dictationIdNumber = +dictationId;
            const response = await dictationsServices.getTranscriptions({ dictationId: dictationIdNumber });
            setListAT([...response.data.list]);
        } catch (error) {
            console.log(error);
        }
    },
    uploadTranscription: async (dictationId: string, file: File) => {
        const dictationIdNumber = +dictationId;
        const response = await dictationsServices.uploadTranscription({
            dictationId: dictationIdNumber,
            file: file,
        });
        return response;
    },
    deleteTranscription: async (id: number) => {
        try {
            const response = await dictationsServices.deleteTranscription({ id: id });
            return response;
        } catch (error) {
            console.log(error);
        }
    },
};
