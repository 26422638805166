import React from 'react';
import { Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { doctorRoute } from '../../common/constants/routes';
import { doctorAuthGuard, unDoctorAuthGuard } from '../../common/utils/routeGuards';
import PrivateRoute from '../../components/Route/PrivateRoute';
import ChangePassword from '../common/change-password/ChangePassword';
import ForgotPassword from '../common/forgot-password/ForgotPassword';
import ResetPassword from '../common/reset-password/ResetPassword';
import Verification from '../common/verification/Verification';

import Registration from './auth/registration/Registration';
import SignIn from './auth/sign-in/SignIn';
import SignUp from './auth/sign-up/SignUp';
import Welcome from './auth/welcome/Welcome';
import MainPage from './MainPage';

const Doctor = (): React.ReactElement => {
    const wrapper = React.createRef();

    return (
        <div className="App" ref={wrapper as any}>
            <ToastContainer />
            <Switch>
                <PrivateRoute path={doctorRoute.WELCOME} component={Welcome} guards={[unDoctorAuthGuard]} />
                <PrivateRoute path={doctorRoute.SIGN_IN} component={SignIn} guards={[unDoctorAuthGuard]} />
                <PrivateRoute path={doctorRoute.REGISTRATION} component={Registration} guards={[unDoctorAuthGuard]} />
                <PrivateRoute path={doctorRoute.SIGN_UP} component={SignUp} guards={[unDoctorAuthGuard]} />
                <PrivateRoute
                    path={doctorRoute.FORGOT_PASS_CODE}
                    component={() => <ForgotPassword isDoctorAuth={true} />}
                    guards={[unDoctorAuthGuard]}
                />
                <PrivateRoute
                    path={doctorRoute.VERIFICATION}
                    component={() => <Verification isDoctorAuth={true} />}
                    guards={[unDoctorAuthGuard]}
                />
                <PrivateRoute
                    path={doctorRoute.RESET_PASS_CODE}
                    component={() => <ResetPassword isDoctorAuth={true} />}
                    guards={[unDoctorAuthGuard]}
                />
                <PrivateRoute
                    path={doctorRoute.CHANGE_PASSWORD_FIRST_TIME}
                    component={() => <ChangePassword currentPage={'passcode'} />}
                    guards={[unDoctorAuthGuard]}
                />
                <PrivateRoute path={doctorRoute.ROOT} component={MainPage} guards={[doctorAuthGuard]} />
            </Switch>
        </div>
    );
};

export default Doctor;
