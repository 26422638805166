import React, { useState } from 'react';
import './ButtonFormStyles.scss';

interface ButtonFormProps {
    toggleSwitch: () => void;
    isOn: boolean;
}

const SwitchButton: React.FunctionComponent<ButtonFormProps> = ({ toggleSwitch, isOn }): React.ReactElement => {
    return (
        <div onClick={toggleSwitch} className={`switch-button ${isOn ? 'on' : 'off'}`}>
            <div className="slider"></div>
        </div>
    );
};

export default SwitchButton;
