import dayjs from 'dayjs';

export const formatDate = (date: Date | string) => {
    return dayjs(date).format('DD/MM/YYYY');
};

export const formatDateByDay = (date: Date | string) => {
    return dayjs(date).format('MMM D, YYYY');
};
export const formatDateByMinute = (date: Date | string) => {
    return dayjs(date).format('h:mm:ss DD/MM/YYYY');
};
