export const validateFile = (file: File, validFiles: string[], maxSize: number) => {
    const numberSize = Math.floor(maxSize / 1048576);
    if (file.size > maxSize) {
        return `Sorry, we could not upload this file. Allowed Size is less than ${numberSize}MB`;
    } else if (
        !validFiles.find(
            (rule) => rule === file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase(),
        )
    ) {
        return `Sorry, we could not upload this file, allowed extensions are: ${validFiles.map((item) =>
            item.toUpperCase(),
        )}`;
    }
    return '';
};

export const formatFileName = (filename: string) => {
    const formatName = filename.slice(filename.indexOf('_') + 1);
    return formatName;
};
