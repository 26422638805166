import React from 'react';

import { sortCaret } from '../../../common/helpers/TableHelper';

import { Actions, ActionsColumnFormatter, Row } from './ActionsColumnFormatter/ActionsColumnFormatter';

const formatter = (cell: any, row: Row, rowIndex: number, actions: Actions): JSX.Element => (
    <ActionsColumnFormatter row={row} actions={actions} />
);

export const renderColumns = (handleAction: any) => [
    {
        dataField: 'createdAt',
        text: 'Date of Upload',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'templateName',
        text: 'Template Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        formatter: formatter,
        formatExtraData: {
            downloadFileTemplate: handleAction(),
        },
        dataField: 'filePath',
        text: 'Template File',
    },
];
