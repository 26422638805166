import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';

import { AssigneeDTO } from '../../../../common/services/apiTypes';
import InputForm from '../../../../components/InputForm/InputForm';
import appServices from '../../../../common/services/appServices';
import { notify } from '../../../../common/utils/notify';
import { ActionSubject } from '../../../../common/constants/enum';
import './AssigneeActionDialogStyles.scss';
import DropdownForm from '../../../../components/DropdownForm/DropdownForm';
import { listOptionRole } from '../../../../common/constants/app';

interface Props {
    isShowDialog: boolean;
    setShowDialog: (show: boolean) => void;
    listItemDelete: string[];
    setListItemDelete: (list: string[]) => void;
    action: ActionSubject;
    setReloadPageType: (action: ActionSubject) => void;
    setDisableDeleteButton: (show: boolean) => void;
}

export interface AssigneeFields extends Omit<AssigneeDTO, 'id' | 'createdAt' | 'updatedAt'> {
    userRole: string;
}

const initialAssignee: AssigneeFields = {
    userRole: '',
    lastName: '',
    firstName: '',
    email: '',
};

export const AssigneeActionDialog: React.FunctionComponent<Props> = ({
    isShowDialog,
    setShowDialog,
    listItemDelete,
    setListItemDelete,
    action,
    setReloadPageType,
    setDisableDeleteButton,
}) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const formAddAssigneeSchema = Yup.object().shape({
        userRole: Yup.string().trim().required('This field is required'),
        lastName: Yup.string()
            .trim()
            .required('This field is required')
            .max(50, 'Last name must be less than 50 characters'),
        firstName: Yup.string()
            .trim()
            .required('This field is required')
            .max(50, 'First name must be less than 50 characters'),
        email: Yup.string()
            .trim()
            .required('This field is required')
            .email('Please enter a valid email address')
            .max(70, 'Email length must be less than or equal to 70 characters long'),
    });

    const handleSubmitForm = async (values: AssigneeFields) => {
        try {
            setLoading(true);
            values.firstName = values.firstName.trim();
            values.lastName = values.lastName.trim();
            values.email = values.email.trim();

            if (action === ActionSubject.ADD) {
                const response = await appServices.createAssignee(values);
                if (response.success) {
                    setReloadPageType(ActionSubject.ADD);
                    setShowDialog(false);
                    notify.success('Member successfully added.', 'Success');
                } else {
                    notify.error(response.message, 'Error');
                }
            } else {
                const response = await appServices.deleteAssignee({ emails: listItemDelete });
                if (response.success) {
                    setReloadPageType(ActionSubject.DELETE);
                    setDisableDeleteButton(true);
                    setShowDialog(false);
                    setListItemDelete([]);
                    notify.success('Member successfully deleted.', 'Success');
                } else {
                    notify.error(response.message, 'Error');
                }
            }
        } catch (error) {
            notify.error(error.message, 'Error');
        } finally {
            setLoading(false);
        }
    };

    const renderContent = () => {
        switch (action) {
            case ActionSubject.ADD:
                return { title: 'Add a Member', content: '', acceptButton: 'Invite', rejectButton: 'Cancel' };
            case ActionSubject.DELETE:
                return {
                    title: 'Delete Member',
                    content: 'Deleting will remove the record of this member. Would you like to continue?',
                    acceptButton: 'Yes',
                    rejectButton: 'No',
                };
            default:
                return;
        }
    };

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-center"
            centered
            show={isShowDialog}
            onHide={() => setShowDialog(false)}
        >
            <Modal.Header closeButton className={action === ActionSubject.ADD ? 'model-add' : 'model-delete'}>
                <Modal.Title id="contained-modal-title-center">{renderContent()?.title}</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialAssignee}
                validateOnChange
                validationSchema={formAddAssigneeSchema}
                enableReinitialize
                onSubmit={(values) => {
                    if (isLoading) {
                        return;
                    }
                    handleSubmitForm(values);
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form
                        className="my-2"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    >
                        <Modal.Body>
                            {action === ActionSubject.ADD ? (
                                <>
                                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                                    <DropdownForm
                                        controlId="formRole"
                                        label="Role"
                                        handleChange={handleChange}
                                        name="userRole"
                                        value={values.userRole}
                                        labelStyle="label-add-assignee mb-1"
                                        error={(touched.userRole && errors.userRole) || ''}
                                        defaultText="Select a Role"
                                        listOption={listOptionRole}
                                        setErrorMessageForm={setErrorMessage}
                                    />
                                    <InputForm
                                        controlId="formLastName"
                                        label="Last Name"
                                        handleChange={handleChange}
                                        name="lastName"
                                        value={values.lastName}
                                        labelStyle="label-add-assignee mb-1"
                                        error={(touched.lastName && errors.lastName) || ''}
                                        setErrorMessageForm={setErrorMessage}
                                    />
                                    <InputForm
                                        controlId="formFirstName"
                                        label="First Name"
                                        handleChange={handleChange}
                                        name="firstName"
                                        value={values.firstName}
                                        labelStyle="label-add-assignee mb-1"
                                        error={(touched.firstName && errors.firstName) || ''}
                                        setErrorMessageForm={setErrorMessage}
                                    />
                                    <InputForm
                                        controlId="formEmail"
                                        label="Email"
                                        handleChange={handleChange}
                                        name="email"
                                        value={values.email}
                                        labelStyle="label-add-assignee mb-1"
                                        error={(touched.email && errors.email) || ''}
                                        setErrorMessageForm={setErrorMessage}
                                    />
                                </>
                            ) : (
                                <p>{renderContent()?.content}</p>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-primary cancel-button"
                                onClick={() => setShowDialog(false)}
                                className="px-4 py-1"
                            >
                                {renderContent()?.rejectButton}
                            </Button>
                            <Button
                                variant="primary"
                                className={`px-4 py-1 ${
                                    action === ActionSubject.DELETE ? 'yes-button' : 'save-button'
                                }`}
                                onClick={
                                    action === ActionSubject.DELETE
                                        ? () => handleSubmitForm(initialAssignee)
                                        : () => handleSubmit()
                                }
                                disabled={isLoading}
                            >
                                {renderContent()?.acceptButton}
                                {isLoading && (
                                    <span className="ml-2">
                                        <Spinner animation="border" size="sm" variant="light" />
                                    </span>
                                )}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
