import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { MenuSidebarItemType } from '../../MenuSidebar';
import './MenuSidebarItemStyles.scss';
import { storage, storageKey } from '../../../../common/utils/storage';

interface MenuSidebarItemProps {
    option: MenuSidebarItemType;
    currentRoute: string;
    setCurrentRoute: (path: string) => void;
}

const MenuSidebarItem: React.FunctionComponent<MenuSidebarItemProps> = ({
    option,
    currentRoute,
    setCurrentRoute,
}): React.ReactElement => {
    const history = useHistory();
    const [activeIcon, setActiveIcon] = useState(true);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleClickItem = (path: string) => {
        setActiveIcon(true);
        setCurrentRoute(path);
        history.push(path);
        handleScrollToTop();
    };

    const role = storage.getToken(storageKey.TOKEN)?.length ? 'editor' : 'doctor';

    return (
        <div
            className={`option ${currentRoute.includes(option.path || 'home') ? 'option--chosen' : ''}`}
            onClick={() => handleClickItem(option.path || '')}
        >
            <div className="option__icon ml-4">
                <img
                    src={
                        activeIcon && option.path && currentRoute.includes(option.path)
                            ? option.iconActive
                            : option.icon
                    }
                    alt=""
                />
            </div>
            <div
                className={`option__text option__text-${role} ${
                    activeIcon && option.path && currentRoute.includes(option.path) ? 'text-active ' : ''
                }`}
            >
                {option.text}
            </div>
        </div>
    );
};

export default MenuSidebarItem;
