import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ActionSubject, SortType, UserRole } from '../../../common/constants/enum';
import { AssigneeDTO } from '../../../common/services/apiTypes';
import appServices from '../../../common/services/appServices';
import SubHeaderPage from '../../../components/SubHeaderPage/SubHeaderPage';
import Table, { PaginationOptions, TableConfig } from '../../../components/Table/Table';
import TableToolbar from '../../../components/Table/components/TableToolbar/TableToolbar';
import AppConstants from '../../../common/constants/app';
import { GlobalState } from '../../../redux';

import { renderColumns } from './AssigneeHelper';
import { AssigneeActionDialog } from './components/AssigneeActionDialog';

export const initialTableConfig: TableConfig = {
    page: 1,
    pageSize: 15,
    searchValue: '',
    sortBy: '',
    sortDirection: SortType.DESC,
};

const Assignee: React.FunctionComponent = () => {
    const [totalSize, setTotalSize] = useState<number>(0);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [listAssignee, setListAssignee] = useState<AssigneeDTO[]>([]);
    const [isShowDialog, setShowDialog] = useState(false);
    const [action, setAction] = useState<ActionSubject>(ActionSubject.UNDEFINED);
    const [reloadPageType, setReloadPageType] = useState<ActionSubject>(ActionSubject.UNDEFINED);
    const [isDisableDeleteButton, setDisableDeleteButton] = useState(true);
    const [listItemDelete, setListItemDelete] = useState<string[]>([]);
    const [tableConfig, setTableConfig] = useState<TableConfig>(initialTableConfig);
    const { userInfo } = useSelector((state: GlobalState) => state.user);

    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: totalSize,
        sizePerPageList: [
            { text: '15', value: 15 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    const handleAction = (action: ActionSubject) => {
        setAction(action);
        setShowDialog(true);
    };

    const getData = async (tableConfigProps: TableConfig) => {
        setIsDataLoading(true);
        try {
            const response = await appServices.getAssignees(tableConfigProps);
            if (response.success) {
                setListAssignee(response.data.list);
                setTableConfig({ ...tableConfigProps, page: parseInt(response.data.current_page) });
                setTotalSize(response.data.total);
                setReloadPageType(ActionSubject.UNDEFINED);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    const getHandleTableChange = (type: string, newState: any) => {
        const params = {
            page: newState.page || 1,
            pageSize: newState.sizePerPage || AppConstants.pagination.pageSize,
            searchValue: newState.searchText || '',
            sortBy: newState.sortField || '',
            sortDirection: newState.sortOrder || SortType.DESC,
        };
        if (type === 'search') {
            return getData({ ...params, page: 1 });
        }
        getData(params);
    };

    const handleOnSelect = (row: AssigneeDTO, isSelect: boolean) => {
        if (isSelect) {
            listItemDelete.push(row.email);
            setDisableDeleteButton(false);
        } else {
            if (listItemDelete.length === 1) setDisableDeleteButton(true);
            const newListDelete = listItemDelete.filter((item) => item !== row.email);
            setListItemDelete(newListDelete);
        }
    };

    const handleOnSelectAll = (isSelect: boolean, rows: AssigneeDTO[]) => {
        if (isSelect) {
            rows.map((item) => listItemDelete.push(item.email));
        } else {
            setListItemDelete([]);
            setDisableDeleteButton(true);
        }
        setDisableDeleteButton(!isSelect);
    };

    const selectRow = {
        mode: 'checkbox',
        nonSelectable: listAssignee?.filter((item) => item.userRole === UserRole.Admin).map((item) => item.id),
        clickToSelect: true,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
    };

    useEffect(() => {
        if (reloadPageType !== ActionSubject.UNDEFINED) {
            getData(initialTableConfig);
            setAction(ActionSubject.UNDEFINED);
            setShowDialog(false);
            setReloadPageType(ActionSubject.UNDEFINED);
        }
    }, [reloadPageType]);

    return (
        <>
            <AssigneeActionDialog
                isShowDialog={isShowDialog}
                setShowDialog={setShowDialog}
                listItemDelete={listItemDelete}
                setListItemDelete={setListItemDelete}
                action={action}
                setReloadPageType={setReloadPageType}
                setDisableDeleteButton={setDisableDeleteButton}
            />
            <SubHeaderPage title="Staff" />
            <Table
                keyField="id"
                tableConfig={tableConfig}
                paginationOptions={paginationOptions}
                data={listAssignee}
                isDataLoading={isDataLoading}
                getData={getData}
                columns={renderColumns()}
                getHandleTableChange={getHandleTableChange}
                selectRow={userInfo.userRole === UserRole.Admin ? selectRow : undefined}
                tableToolbar={
                    userInfo.userRole === UserRole.Admin ? (
                        <TableToolbar
                            textAddNewButton="Add a Member"
                            textDeleteButton="Delete"
                            isDisableDeleteButton={isDisableDeleteButton}
                            handleAction={handleAction}
                        />
                    ) : undefined
                }
            />
        </>
    );
};

export default Assignee;
