import React from 'react';

import { sortCaret } from '../../../common/helpers/TableHelper';
import {
    DropdownSelectCell,
    Row,
    SelectActions,
} from '../../../components/Table/components/DropdownSelectCell/DropdownSelectCell';
import constant from '../../../common/constants/app';
import arrowRight from '../../../common/assets/images/PolygonRight.png';
import arrowDown from '../../../common/assets/images/PolygonDown.png';
import { DictationStatus } from '../../../common/constants/enum';

const statusFormatter = (cell: any, row: Row, rowIndex: number, actions: SelectActions): JSX.Element => (
    <DropdownSelectCell
        value={cell}
        options={constant.dictationStatus}
        statusClassName={`status-text ${
            cell === DictationStatus.Unassign
                ? 'unassigned-select'
                : cell === DictationStatus.Submitted
                ? 'submitted-select'
                : 'in-progress-select'
        }`}
        actions={actions}
        row={row}
    />
);

const arrowFnc = (cell: any, row: any, rowIndex: number, actions: SelectActions) => {
    return row?.opened ? <img src={arrowDown} alt="" /> : <img src={arrowRight} alt="" />;
};

export const renderColumns = () => [
    {
        dataField: 'id',
        text: 'Unique Code',
        sort: true,
        sortCaret: sortCaret,
        headerStyle: () => {
            return { textAlign: 'center', Width: '150px' };
        },
        style: {
            textAlign: 'center',
        },
    },
    {
        dataField: 'createdAt',
        text: 'Date of Upload',
        sort: true,
        sortCaret: sortCaret,
        headerStyle: () => {
            return { Width: '170px' };
        },
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'userName',
        text: 'Username',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'turnAroundTime.value',
        text: 'Turn around TAT',
    },
    {
        dataField: 'status',
        text: 'Status',
        classes: 'icon-table status-text',
        formatter: statusFormatter,
        headerStyle: () => {
            return { textAlign: 'start', width: '150px' };
        },
        style: {
            textAlign: 'start',
        },
    },
    {
        dataField: '',
        text: '',
        classes: 'text-center icon-table',
        headerClasses: 'text-center',
        style: {
            minWidth: '140px',
        },
        formatter: arrowFnc,
        headerStyle: () => {
            return { width: '7%' };
        },
    },
];
