import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import DoctorBackground from '../../../../components/DoctorAuthBackground/DoctorAuthBackground';
import { doctorRoute } from '../../../../common/constants/routes';
import ContentLoginPage from '../../../../components/ContentLoginPage/ContentLoginPage';
import { login, reset } from '../../../../redux/doctor-auth/actions';
import { GlobalState } from '../../../../redux';
import './SignInStyles.scss';

const SignIn: React.FC = (): React.ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { loginMessage, isLoggingIn } = useSelector((state: GlobalState) => state.doctorAuth);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleLogin = async (values: { email: string; password: string }) => {
        try {
            dispatch(
                login(
                    {
                        email: values.email.trim(),
                        password: values.password,
                    },
                    history,
                ),
            );
        } catch (error) {
            setErrorMessage('Please try again.');
        }
    };

    const handleClickBackBtn = () => {
        history.push(doctorRoute.WELCOME);
    };

    useEffect(() => {
        dispatch(reset());
    }, []);

    const formLoginSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .required('This field is required')
            .email('Please enter a valid email address')
            .max(70, 'Email length must be less than or equal to 70 characters long'),
        password: Yup.string()
            .required('This field is required')
            .matches(/^[0-9]{4}$/, '4 numbers is required'),
    });

    return (
        <DoctorBackground>
            <div className="mt-5">
                <ContentLoginPage
                    errorMessage={loginMessage || errorMessage}
                    setErrorMessage={setErrorMessage}
                    formLoginSchema={formLoginSchema}
                    isLoading={isLoggingIn}
                    handleLogin={handleLogin}
                    forgotString="Passcode"
                    forgotLink={doctorRoute.FORGOT_PASS_CODE}
                />
            </div>
            <div className="contact">
                <label className="d-block m-0">Need help Signing in?</label>
                <label className="d-block mt-2">
                    Please contact&nbsp;
                    <a href="mailto:support@jivescribe.com" className="text-color-orange">
                        support@jivescribe.com&nbsp;
                    </a>
                    for help.
                </label>
            </div>

            <button onClick={handleClickBackBtn} className="backBtn">
                Back
            </button>
        </DoctorBackground>
    );
};

export default SignIn;
