import * as React from 'react';
const SortByDateIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={19.891} height={13.145} viewBox="0 0 19.891 13.145" {...props}>
        <g id="Group_626" data-name="Group 626" transform="translate(-141.236 -18.428)">
            <g id="Group_147" data-name="Group 147" transform="translate(142.297 19.178)">
                <path
                    id="Path_1794"
                    data-name="Path 1794"
                    d="M0,0V11"
                    transform="translate(3.386)"
                    fill="none"
                    stroke="#6a7891"
                    strokeLinecap="round"
                    strokeWidth={1.5}
                />
                <path
                    id="Path_272"
                    data-name="Path 272"
                    d="M10972.171,11741l3.337,3.145,3.434-3.145"
                    transform="translate(-10972.171 -11732.5)"
                    fill="none"
                    stroke="#6a7891"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                />
            </g>
            <g id="Group_148" data-name="Group 148" transform="translate(153.297 19.178)">
                <path
                    id="Path_1795"
                    data-name="Path 1795"
                    d="M0,11V0"
                    transform="translate(3.386 0.645)"
                    fill="none"
                    stroke="#6a7891"
                    strokeLinecap="round"
                    strokeWidth={1.5}
                />
                <path
                    id="Path_272-2"
                    data-name="Path 272"
                    d="M10972.171,11744.145l3.337-3.145,3.434,3.145"
                    transform="translate(-10972.171 -11741)"
                    fill="none"
                    stroke="#6a7891"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                />
            </g>
        </g>
    </svg>
);
export default SortByDateIcon;
