import React from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Alert, Form } from 'react-bootstrap';

import ButtonForm from '../ButtonForm/ButtonForm';
import InputForm, { InputType } from '../InputForm/InputForm';

interface ContentLoginPageProps {
    errorMessage: string;
    setErrorMessage: (error: string) => void;
    formLoginSchema: any;
    isLoading: boolean;
    handleLogin: (values: { email: string; password: string; rememberMe: boolean }) => void;
    forgotString: string;
    emailString?: string;
    forgotLink: string;
}
const ContentLoginPage: React.FC<ContentLoginPageProps> = ({
    errorMessage,
    setErrorMessage,
    formLoginSchema,
    isLoading,
    handleLogin,
    forgotString,
    forgotLink,
    emailString,
}): React.ReactElement => {
    return (
        <>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Formik
                initialValues={{ email: '', password: '', rememberMe: false }}
                validateOnChange
                validationSchema={formLoginSchema}
                enableReinitialize
                onSubmit={(values) => {
                    handleLogin({ ...values, email: values.email.toLowerCase() });
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form
                        className="my-2"
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    >
                        <InputForm
                            controlId="formEmail"
                            label={emailString ? emailString : 'Username'}
                            handleChange={handleChange}
                            name="email"
                            value={values.email}
                            labelStyle=" mb-2 mt-5 paragraph-medium"
                            error={(touched.email && errors.email) || ''}
                            setErrorMessageForm={setErrorMessage}
                        />
                        <InputForm
                            formGroupStyle="mt-5"
                            controlId="formPassword"
                            label={forgotString}
                            type={InputType.Password}
                            name="password"
                            handleChange={handleChange}
                            setErrorMessageForm={setErrorMessage}
                            labelStyle=" mb-2 paragraph-medium"
                            value={values.password}
                            error={(touched.password && errors.password) || ''}
                        />
                        <Form.Group className="d-flex justify-content-between mb-5">
                            <Form.Check type="checkbox" label="Remember me?" id="formBasicCheckbox" />
                            <Link to={forgotLink} className="paragraph-extra">
                                <Form.Label className="lb-forgot-password">{`Forgot ${forgotString}?`}</Form.Label>
                            </Link>
                        </Form.Group>
                        <ButtonForm
                            buttonName="SIGN IN"
                            handleSubmit={handleSubmit}
                            isLoading={isLoading}
                            className="mb-4"
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ContentLoginPage;
