const ns = `${process.env.REACT_APP_NAME_PROJECT}`;

export const storageKey = {
    TOKEN: `${ns}-token`,
    DOCTOR_TOKEN: `${ns}-doctor-token`,
};

export const storage = {
    setToken: (token: string, key: string) => localStorage.setItem(key, token),

    getToken: (key: string) => localStorage.getItem(key),

    removeToken: (key: string) => localStorage.removeItem(key),
};
