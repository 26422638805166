import API from '../constants/api';
import { TableConfig } from '../../components/Table/Table';
import { AssigneeFields } from '../../pages/editor-pages/assignee/components/AssigneeActionDialog';
import {
    DictationTableConfig,
    SearchParamListDictation,
    getHistoricalDetail,
} from '../../pages/editor-pages/dictations-in-progress/DictationsInProgress';
import { AxiosClient, AxiosDoctorClient } from '../utils/axiosClient';
import { UserInviteFields } from '../../pages/editor-pages/users/components/UserActionDialog';
import { AccountFields } from '../../pages/editor-pages/account/components/CreateAccountDialog/CreateAccountDialog';
import { ReturnEmailUpdateFields } from '../../pages/editor-pages/account/components/ChangeReturnEmailDialog/ChangeReturnEmailDialog';
import { AccountActiveType } from '../../redux/account/actionTypes';

import {
    AssigneesTableResponse,
    CreateAssigneeResponse,
    StatisticType,
    DictationResourceResponse,
    DictationsTableResponse,
    ResponseType,
    TimezoneOffsetType,
    AccountTableResponse,
    AccountDTO,
} from './apiTypes';

const appServices = {
    getAssignees: async (params: TableConfig): Promise<AssigneesTableResponse> => {
        const { data } = await AxiosClient.get(API.GET_STAFFS, { params });
        return data;
    },
    createAssignee: async (params: AssigneeFields): Promise<CreateAssigneeResponse> => {
        const { data } = await AxiosClient.post(API.CREATE_STAFF, {
            ...params,
        });
        return data;
    },
    inviteUser: async (params: UserInviteFields): Promise<CreateAssigneeResponse> => {
        const { data } = await AxiosClient.post(API.CREATE_STAFF, {
            ...params,
        });
        return data;
    },
    deleteAssignee: async (params: { emails: string[] }): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.post(API.DELETE_STAFF, { ...params });
        return data;
    },
    getListDictation: async (params: DictationTableConfig): Promise<DictationsTableResponse> => {
        const { data } = await AxiosClient.get(API.DICTATIONS.GET_LIST_DICTATION, {
            params,
        });
        return data;
    },

    getDictationResource: async (params: number): Promise<DictationResourceResponse> => {
        const { data } = await AxiosClient.get(API.DICTATIONS.GET_DICTATION_RESOURCE + params);
        return data;
    },
    downloadFileCsv: async (params: {
        fromDate?: Date;
        status: string;
        searchValue?: string;
        turnAroundTime?: string;
    }): Promise<any> => {
        const { data } = await AxiosClient.get(API.DICTATIONS.DOWNLOAD_FILE_CSV, { params });
        return data;
    },
    getDashboard: async (params: TimezoneOffsetType): Promise<ResponseType<StatisticType>> => {
        const { data } = await AxiosClient.get(API.GET_DASHBOARD, { params });
        return data;
    },
    updateDictationStatus: async (params: { status: string }, dictationId: number): Promise<ResponseType<null>> => {
        const timezoneHrs = -(new Date().getTimezoneOffset() / 60);
        const { data } = await AxiosClient.put(API.DICTATIONS.UPDATE_STATUS + dictationId, null, {
            params: { ...params, timezoneOffset: timezoneHrs },
        });
        return data;
    },
    getListAccount: async (params: TableConfig): Promise<AccountTableResponse> => {
        const { data } = await AxiosClient.get(API.ACCOUNT.GET_LIST_ACCOUNT, { params });
        return data;
    },
    getListAccountActive: async (): Promise<ResponseType<AccountActiveType[]>> => {
        const { data } = await AxiosClient.get(API.ACCOUNT.GET_LIST_ACCOUNT_ACTIVE);
        return data;
    },
    createAccount: async (params: AccountFields): Promise<ResponseType<{ account: AccountDTO }>> => {
        const { data } = await AxiosClient.post(API.ACCOUNT.GET_LIST_ACCOUNT, {
            ...params,
        });
        return data;
    },
    updateAccountStatus: async (params: { accountStatus: string }, accountId: string): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.put(API.ACCOUNT.UPDATE_ACCOUNT + accountId, {
            ...params,
        });
        return data;
    },
    updateReturnEmail: async (params: ReturnEmailUpdateFields, accountId: string): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.put(API.ACCOUNT.UPDATE_ACCOUNT + accountId, {
            ...params,
        });
        return data;
    },
    updateReturnEmailStatus: async (
        params: { returnEmailStatus: string },
        accountId: string,
    ): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.put(API.ACCOUNT.UPDATE_ACCOUNT + accountId, {
            ...params,
        });
        return data;
    },
    // Doctor role
    getListDictationDoctorRole: async (params: DictationTableConfig): Promise<DictationsTableResponse> => {
        const { data } = await AxiosDoctorClient.get(API.DICTATIONS.GET_LIST_DICTATION, {
            params,
        });
        return data;
    },
    getDictationResourceDoctorRole: async (params: number): Promise<DictationResourceResponse> => {
        const { data } = await AxiosDoctorClient.get(API.DICTATIONS.GET_DICTATION_RESOURCE + params);
        return data;
    },
    getFileDownload: async (params: number): Promise<DictationResourceResponse> => {
        const { data } = await AxiosDoctorClient.get(API.DICTATIONS.DOWNLOAD_FILE_TRANSCRIPTION + params);
        return data;
    },
    updateDictationStatusDownload: async (params: number): Promise<DictationResourceResponse> => {
        const { data } = await AxiosDoctorClient.put(API.DICTATIONS.DOWNLOADED_FILE_TRANSCRIPTION_STATUS + params);
        return data;
    },
    getDictationTranscriptions: async (params: SearchParamListDictation): Promise<any> => {
        const { data } = await AxiosDoctorClient.get(API.DICTATIONS.GET_LIST_DICTATION_TRANSCRIPTION, {
            params,
        });
        return data;
    },
    getListFileFinishedTranscriptionsDoctorRole: async (params: getHistoricalDetail): Promise<ResponseType<any>> => {
        const { data } = await AxiosDoctorClient.get(
            `${API.DICTATIONS.GET_LIST_DICTATION}/${params.dictationId}/transcriptions`,
            {
                params,
            },
        );
        return data;
    },
};
export default appServices;
