import React from 'react';

import { sliceFileName } from '../../../../common/utils/converter';
import './Styles.scss';

export interface Actions {
    downloadFileTemplate: (id: number) => HandlerAction;
}

export type HandlerAction = () => void;
export interface Row {
    id: number;
    filePath: string;
}

interface ActionsColumnFormatterProps {
    row: Row;
    actions: Actions;
}

export const ActionsColumnFormatter: React.FunctionComponent<ActionsColumnFormatterProps> = ({
    row,
    actions,
}): React.ReactElement => {
    return (
        <div onClick={() => actions.downloadFileTemplate(row.id)} className="file-template-box">
            <div className="file-template">{sliceFileName(row.filePath)}</div>
        </div>
    );
};
