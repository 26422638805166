import React from 'react';
import { PaginationOptions } from 'react-bootstrap-table-next';

import { PaginationLinks } from './PaginationLinks';
import { PaginationToolbar } from './PaginationToolbar';

export interface PaginationProps extends PaginationOptions {
    tableId?: string;
    bootstrap4?: boolean;
}

interface PaginationWrapperProps extends PaginationOptions {
    isLoading: boolean;
    paginationProps: PaginationProps;
    children: React.ReactNode;
    isHidePagination?: boolean;
}

export const Pagination: React.FC<PaginationWrapperProps> = (props) => {
    const { children, isLoading, paginationProps, isHidePagination } = props;

    return (
        <>
            {children}
            {!isHidePagination && (
                <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                    <PaginationToolbar isLoading={isLoading} paginationProps={paginationProps} />
                    <PaginationLinks paginationProps={paginationProps} />
                </div>
            )}
        </>
    );
};
