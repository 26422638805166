import { History } from 'history';

import { LoginFailureResponse, LoginParams, LoginResponse } from '../auth/actionTypes';

import {
    DoctorLoginFailureAction,
    DOCTOR_LOGIN,
    DOCTOR_LOGIN_SUCCESS,
    DOCTOR_LOGIN_FAILURE,
    DOCTOR_RESET,
    DoctorLoginType,
} from './actionTypes';

export const login = (params: LoginParams, history?: History): DoctorLoginType => {
    return {
        type: DOCTOR_LOGIN,
        payload: params,
        history: history,
    };
};

export const doctorLoginSuccess = (payload: LoginResponse): DoctorLoginType => {
    return {
        type: DOCTOR_LOGIN_SUCCESS,
        payload,
    };
};

export const loginFailure = (payload: LoginFailureResponse): DoctorLoginFailureAction => {
    return {
        type: DOCTOR_LOGIN_FAILURE,
        payload,
    };
};

export const reset = (): DoctorLoginType => {
    return {
        type: DOCTOR_RESET,
    };
};
