import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse } from '@mui/material';

import emptyPage from '../../../../common/assets/images/historical-empty.png';
import filesIcon from '../../../../common/assets/images/file-icon.png';
import arrowRight from '../../../../common/assets/images/PolygonRight.png';
import downloadedIcon from '../../../../common/assets/images/downloadedIcon.png';
import arrowDown from '../../../../common/assets/images/PolygonDown.png';
import SubHeaderPage from '../../../../components/SubHeaderPage/SubHeaderPage';
import { SearchParamListDictation } from '../finished-transcriptions-modal/DownloadLog';
import appServices from '../../../../common/services/appServices';
import { formatDate, formatDateByDay, formatDateByMinute } from '../../../../common/helpers/DateFormatHelper';
import { sliceFileName } from '../../../../common/utils/converter';
import { GlobalState } from '../../../../redux';
import { Search } from '../../../../components/Table/components/Search/Search';

import './TranscriptionOnlyList.scss';

interface TranscriptionDTO {
    fileName: string;
    createdAt: string;
    updatedAt: string;
    dictation: {
        dateUpload: string;
        dateDone: string;
    };
    id: number;
    dictationId: number;
    isDownloaded: boolean;
}

const TranscriptionOnlyList: React.FunctionComponent = () => {
    const { userInfo } = useSelector((state: GlobalState) => state.user);
    const [listTranscription, setListTranscription] = useState<TranscriptionDTO[]>([]);
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState<Number[]>([]);
    console.log('expandedRows', expandedRows);
    const initialTableConfig: any = {
        userId: userInfo.id,
    };
    const [tableConfig, setTableConfig] = useState<SearchParamListDictation>(initialTableConfig);

    const getData = async (prop: SearchParamListDictation) => {
        try {
            const response = await appServices.getDictationTranscriptions(prop);
            if (response.success) {
                const listFormatDate = response.data.transcriptions.map((item: any) => ({
                    ...item,
                    createdAt: formatDate(item.createdAt),
                    updatedAt: formatDateByDay(item.updatedAt),
                    fileName: sliceFileName(item.fileName),
                }));
                setListTranscription([...listFormatDate]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSearch = (prop: string) => {
        setIsSearch(true);
        getData({ ...tableConfig, searchValue: prop });
    };

    useEffect(() => {
        getData(tableConfig);
    }, []);

    const itemsDownload: any[] = listTranscription.filter((items) => items.isDownloaded === false);
    const itemsDownloaded: any[] = listTranscription.filter((items) => items.isDownloaded === true);

    const itemsIsDownload: Record<string, any[]> = {};
    const itemsIsDownloaded: Record<string, any[]> = {};

    itemsDownload.forEach((items: any) => {
        if (!itemsIsDownload[items.dictationId]) {
            itemsIsDownload[items.dictationId] = [];
        }
        itemsIsDownload[items.dictationId].push(items);
    });

    itemsDownloaded.forEach((items: any) => {
        if (!itemsIsDownloaded[items.dictationId]) {
            itemsIsDownloaded[items.dictationId] = [];
        }
        itemsIsDownloaded[items.dictationId].push(items);
    });

    const handleClickExpandRow = (clickIndex: Number) => {
        if (expandedRows.includes(clickIndex)) {
            const expandedCopy = expandedRows.filter((element) => {
                return element !== clickIndex;
            });
            setExpandedRows(expandedCopy);
        } else {
            const expandedCopy = [...expandedRows];
            expandedCopy.push(clickIndex);
            setExpandedRows(expandedCopy);
        }
    };

    const renderCells = (data: any) => {
        console.log('data', data);
        let dateUpload, dateDone, itemsIsDownload;
        const dictationId = data[0].dictationId;
        const ID = data[0].id;
        const fileName: any[] = [];
        data.map((item: any) => {
            fileName.push(item.fileName);
            itemsIsDownload = item.isDownloaded;
            if (!itemsIsDownload) {
                dateUpload = formatDateByMinute(item.dictation.dateUpload);
                dateDone = formatDateByMinute(item.dictation.dateDone);
            }
        });
        return (
            <Fragment key={ID}>
                <TableRow
                    className={`table-rows ${expandedRows.includes(ID) ? 'remove-border-button' : ''}`}
                    onClick={() => (data.length > 1 ? handleClickExpandRow(ID) : '')}
                >
                    <TableCell sx={{ maxWidth: 600 }} align="center" className="column table-id">
                        {dictationId}
                    </TableCell>
                    <TableCell
                        style={{ width: '20%' }}
                        sx={{ maxWidth: 600 }}
                        className="column table-file"
                        align="left"
                    >
                        {fileName.slice(0, 1).map((item: any) => (
                            <div key={item} className="fileDataName py-2">
                                <img src={filesIcon} className="iconDownloaded" alt="" />
                                <div>{item}</div>
                            </div>
                        ))}
                    </TableCell>
                    <TableCell sx={{ maxWidth: 600 }} className="column table-dateUpload" align="left">
                        {dateUpload}
                    </TableCell>
                    <TableCell sx={{ maxWidth: 600 }} className="column table-dateUpload" align="left">
                        {dateDone}
                    </TableCell>
                    <TableCell style={{ width: '20%' }} className="table-downloaded-1 table-arrow-1" align="center">
                        {itemsIsDownload === true ? (
                            <img src={downloadedIcon} className="status-downloaded"></img>
                        ) : (
                            <img src={expandedRows.includes(ID) ? arrowDown : arrowRight}></img>
                        )}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        colSpan={1}
                        sx={{ paddingBottom: 0, paddingTop: 0, borderLeft: '0px', borderTop: '0px' }}
                    >
                        <Collapse in={expandedRows.includes(ID)} timeout="auto" unmountOnExit></Collapse>
                    </TableCell>
                    <TableCell
                        colSpan={4}
                        sx={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderLeft: '0px',
                            borderTop: '0px',
                        }}
                    >
                        <Collapse in={expandedRows.includes(ID)} timeout="auto" unmountOnExit>
                            {fileName.slice(1).map((item: any) => (
                                <div key={item} className="fileDataName py-4 expanded">
                                    <img src={filesIcon} className="iconDownloaded" alt="" />
                                    <div>{item}</div>
                                </div>
                            ))}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    };

    return (
        <>
            {listTranscription.length > 0 || isSearch ? (
                <div className="container">
                    <div className="transcription">
                        <div className="header-historical">
                            <SubHeaderPage title="Transcription Only List" />
                        </div>
                        <Search onSearch={handleSearch}></Search>

                        <div className="table-container">
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650, maxHeight: 1000, overflowY: 'auto' }}
                                    aria-label="a dense table"
                                >
                                    <TableHead>
                                        <TableRow className="header-table">
                                            <TableCell
                                                sx={{ maxWidth: 600, height: 60 }}
                                                className="header-table-id"
                                                align="center"
                                            >
                                                ID
                                            </TableCell>
                                            <TableCell
                                                sx={{ maxWidth: 600, height: 60 }}
                                                className="header-table-file"
                                                align="left"
                                            >
                                                File
                                            </TableCell>
                                            <TableCell
                                                sx={{ maxWidth: 600, height: 60 }}
                                                className="header-table-upload"
                                                align="left"
                                            >
                                                Date of Upload
                                            </TableCell>
                                            <TableCell
                                                sx={{ maxWidth: 600, height: 60 }}
                                                className="header-table-done"
                                                align="left"
                                            >
                                                Date Done
                                            </TableCell>
                                            <TableCell
                                                style={{ width: '20%' }}
                                                sx={{ width: 172, maxWidth: 600, height: 60 }}
                                                className="header-table-arrow"
                                                align="right"
                                            ></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(itemsIsDownload).length === 0 && (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell className="nothing-to-show" colSpan={5} align="center">
                                                    There is nothing to show here yet.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {Object.keys(itemsIsDownload).map((dictationId) =>
                                            renderCells(itemsIsDownload[dictationId]),
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    <div className="transcription mt-4">
                        <div className="header-historical">
                            <SubHeaderPage title="Download" />
                        </div>
                        <div className="table-container pb-5">
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650, maxHeight: 1000, overflowY: 'auto' }}
                                    aria-label="a dense table"
                                >
                                    <TableBody>
                                        {Object.keys(itemsIsDownloaded).length === 0 && (
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell className="nothing-to-show" colSpan={5} align="center">
                                                    There is nothing to show here yet.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {Object.keys(itemsIsDownloaded).map((dictationId) =>
                                            renderCells(itemsIsDownloaded[dictationId]),
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="empty-page">
                    <div className="empty-element">
                        <img src={emptyPage} className="empty-image" alt="" />
                    </div>
                </div>
            )}
        </>
    );
};

export default TranscriptionOnlyList;
