import React from 'react';
import { Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { route } from '../../common/constants/routes';
import { authGuard, unAuthGuard } from '../../common/utils/routeGuards';
import PrivateRoute from '../../components/Route/PrivateRoute';
import ChangePassword from '../common/change-password/ChangePassword';
import ForgotPassword from '../common/forgot-password/ForgotPassword';
import ResetPassword from '../common/reset-password/ResetPassword';
import Verification from '../common/verification/Verification';

import Login from './auth/login-page/Login';
import EditorLayout from './MainPage';

const Editor = (): React.ReactElement => {
    const wrapper = React.createRef();

    return (
        <div className="App" ref={wrapper as any}>
            <ToastContainer />
            <Switch>
                <PrivateRoute
                    path={route.FORGOT_PASSWORD}
                    component={() => <ForgotPassword isDoctorAuth={false} />}
                    guards={[unAuthGuard]}
                />
                <PrivateRoute
                    path={route.VERIFICATION}
                    component={() => <Verification isDoctorAuth={false} />}
                    guards={[unAuthGuard]}
                />
                <PrivateRoute path={route.LOGIN} component={Login} guards={[unAuthGuard]} />
                <PrivateRoute
                    path={route.RESET_PASSWORD}
                    component={() => <ResetPassword isDoctorAuth={false} />}
                    guards={[unAuthGuard]}
                />
                <PrivateRoute
                    exact
                    path={route.CHANGE_PASSWORD_FIRST_TIME}
                    component={() => <ChangePassword currentPage={'password'} />}
                    guards={[unAuthGuard]}
                />
                <PrivateRoute path={route.ROOT} component={EditorLayout} guards={[authGuard]} />
            </Switch>
        </div>
    );
};

export default Editor;
