import file from '../../common/assets/images/file.png';
import homeActive from '../../common/assets/images/home-active.png';
import home from '../../common/assets/images/home.png';
import microphoneActive from '../../common/assets/images/microphone-active.png';
import microphone from '../../common/assets/images/microphone.png';
import historical from '../../common/assets/images/historical.png';
import historicalActive from '../../common/assets/images/historical-active.png';
import templateActive from '../../common/assets/images/template-active.png';
import transcriptionActive from '../../common/assets/images/transcription-active.png';
import transcription from '../../common/assets/images/transcription.png';
import userActive from '../../common/assets/images/user-active.png';
import user from '../../common/assets/images/user.png';
import settingActive from '../../common/assets/images/settingIcon-active.png';
import setting from '../../common/assets/images/settingIcon.png';
import template from '../../common/assets/images/template.png';
import { OptionSelectType } from '../../components/DropdownForm/DropdownForm';

import { doctorRoute, route } from './routes';

export default {
    tabSetting: [
        {
            title: 'Home',
            path: '/',
        },
    ],
    passwordHelper: {
        passwordRuleRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,32}$/,
    },
    pagination: { pageSize: 30 },
    optionsSideBar: [
        {
            icon: home,
            iconActive: homeActive,
            text: 'Home',
            path: route.HOME,
            dropdown: false,
            nestedOptions: [],
        },
        {
            icon: microphone,
            iconActive: microphoneActive,
            text: 'Dictations',
            dropdown: true,
            nestedOptions: [
                { path: route.DICTATIONS_IN_PROGRESS, text: 'In Progress' },
                { path: route.DICTATIONS_SUBMITTED, text: 'Submitted' },
            ],
        },
        {
            icon: historical,
            iconActive: historicalActive,
            text: 'Historical Record',
            path: route.HISTORICAL_RECORD,
            dropdown: false,
            nestedOptions: [],
        },
        {
            icon: user,
            iconActive: userActive,
            text: 'Users',
            path: route.USERS,
            dropdown: false,
            nestedOptions: [],
        },
        {
            icon: setting,
            iconActive: settingActive,
            text: 'Settings',
            dropdown: true,
            nestedOptions: [
                { path: route.SETTINGS_TURN_AROUND_TIME, text: 'Turnaround Time' },
                { path: route.SETTINGS_ACCOUNT, text: 'Account' },
                { path: route.SETTINGS_ASSIGNEE, text: 'Staff' },
            ],
        },
    ],
    dictationStatus: [
        {
            id: 'Canceled',
            value: 'Canceled',
            title: 'Canceled',
        },
        {
            id: 'Finished',
            value: 'Finished',
            title: 'Finished',
        },
        {
            id: 'InProgress',
            value: 'InProgress',
            title: 'In Progress',
        },
        {
            id: 'Submitted',
            value: 'Submitted',
            title: 'Submitted',
        },
        {
            id: 'Unassign',
            value: 'Unassign',
            title: 'Unassigned',
        },
    ],
    optionsDoctorSideBar: [
        {
            icon: home,
            iconActive: homeActive,
            text: 'Home',
            path: doctorRoute.HOME,
            dropdown: false,
            nestedOptions: [],
        },
        {
            icon: microphone,
            iconActive: microphoneActive,
            text: 'Upload Dictation',
            path: doctorRoute.UPLOAD_DICTATION,
            dropdown: false,
            nestedOptions: [],
        },
        {
            icon: file,
            iconActive: file,
            text: 'Finished dictation',
            dropdown: true,
            nestedOptions: [
                {
                    icon: historical,
                    iconActive: historicalActive,
                    path: doctorRoute.FINISHED_TRANSCRIPTIONS,
                    text: 'Historical audio & Files list',
                },
                {
                    icon: transcription,
                    iconActive: transcriptionActive,
                    path: doctorRoute.TRANSCRIPTION_ONLY_LIST,
                    text: 'Transcription Only List',
                },
            ],
        },
        {
            icon: template,
            iconActive: templateActive,
            text: 'Templates',
            path: doctorRoute.TEMPLATES,
            dropdown: false,
            nestedOptions: [],
        },
    ],

    quarters: [
        { quarter: 0, title: 'Select all' },
        { quarter: 1, title: 'Quarter 1: January - March' },
        { quarter: 2, title: 'Quarter 2: April - June' },
        { quarter: 3, title: 'Quarter 3: July - September' },
        { quarter: 4, title: 'Quarter 4: October - December' },
    ],

    quarterTitles: ['Jan_Mar', 'Apr_June', 'July_Sept', 'Oct_Dec'],

    csvBaseFileName: 'HistoricalRecord_Jivescribe',
};

export enum WindowSize {
    SMALL = 576,
    MEDIUM = 768,
    LARGE = 992,
    EXTRA_LARGE = 1200,
}

export const MAX_SIZE_PHOTO_UPLOAD = 10485760;

export const MAX_SIZE_ATTACH_TRANSCRIPTION_UPLOAD = 52428800;

export const MAX_SIZE_DOCTOR_AUDIO_UPLOAD = 104857600;

export const MAX_SIZE_DOCTOR_ATTACHMENT_UPLOAD = 26214400;

export const listOptionRole: OptionSelectType[] = [
    { text: 'Admin', value: 'Admin' },
    { text: 'Editor', value: 'Editor' },
    { text: 'Assignee', value: 'Assignee' },
];
export const YOUR_ACCOUNT_IS_DEACTIVATED = 'Your account is deactivated';

export const UNAUTHORIZED = 'Unauthorized';
