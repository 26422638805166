import React from 'react';

import { sortCaret } from '../../../common/helpers/TableHelper';
import {
    ChangeStatusAction,
    CustomStatusUserCell,
    Row,
} from '../../../components/Table/components/CustomStatusUserCell/CustomStatusUserCell';

const changeStatusFormatter = (_cell: any, row: Row, _rowIndex: number, actions: ChangeStatusAction): JSX.Element => (
    <CustomStatusUserCell row={row} actions={actions} />
);
export interface UserSelected {
    id: number;
    firstName: string;
    lastName: string;
    status: string;
    email: string;
}
export const renderColumns = (isAdminRole: boolean, handleChangeStatus: (userSelected: UserSelected) => void) => [
    {
        dataField: 'name',
        text: 'Username',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'accountName',
        text: 'Account',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'email',
        text: 'Email',
    },
    isAdminRole
        ? {
              dataField: 'Status',
              text: 'Status',
              formatter: changeStatusFormatter,
              formatExtraData: {
                  handleChangeStatus: handleChangeStatus,
                  isAdminRole: isAdminRole,
              },
              classes: 'text-center icon-table',
              headerClasses: 'text-center',
              style: {
                  minWidth: '140px',
              },
              headerStyle: () => {
                  return { width: '160px' };
              },
          }
        : {
              dataField: 'status',
              text: 'Status',
              classes: 'text-center',
              headerClasses: 'text-center',
              formatter: changeStatusFormatter,
              formatExtraData: {
                  isAdminRole: isAdminRole,
              },
          },
];
