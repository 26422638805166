import {
    createTurnAroundTimeParams,
    CREATE_TURN_AROUND_TIME,
    CREATE_TURN_AROUND_TIME_FAILURE,
    deleteTurnAroundTimeParams,
    DELETE_TURN_AROUND_TIME,
    DELETE_TURN_AROUND_TIME_FAILURE,
    getTurnAroundTimeParams,
    getTurnAroundTimeResponse,
    getTurnAroundTimesAction,
    GET_TURN_AROUND_TIMES,
    GET_TURN_AROUND_TIMES_FAILURE,
    GET_TURN_AROUND_TIMES_SUCCESS,
    SettingsType,
} from './actionTypes';

export const getTurnAroundTimes = (params: getTurnAroundTimeParams): getTurnAroundTimesAction => {
    return {
        type: GET_TURN_AROUND_TIMES,
        payload: params,
    };
};

export const getTurnAroundTimesSuccess = (payload: getTurnAroundTimeResponse): SettingsType => {
    return {
        type: GET_TURN_AROUND_TIMES_SUCCESS,
        payload,
    };
};

export const getTurnAroundTimesFailure = (): SettingsType => {
    return {
        type: GET_TURN_AROUND_TIMES_FAILURE,
    };
};

export const createTurnAroundTime = (params: createTurnAroundTimeParams) => {
    return {
        type: CREATE_TURN_AROUND_TIME,
        payload: params,
    };
};

export const createTurnAroundTimeFailure = (): SettingsType => {
    return {
        type: CREATE_TURN_AROUND_TIME_FAILURE,
    };
};

export const deleteTurnAroundTime = (params: deleteTurnAroundTimeParams) => {
    return {
        type: DELETE_TURN_AROUND_TIME,
        payload: params,
    };
};

export const deleteTurnAroundTimeFailure = (): SettingsType => {
    return {
        type: DELETE_TURN_AROUND_TIME_FAILURE,
    };
};
