import React from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { UserRole } from '../../../../common/constants/enum';
import { GlobalState } from '../../../../redux';
import './DropdownSelectCell.scss';

export interface Row {
    fileName: string;
    id: number;
    isDownloaded: boolean;
}

export interface DropdownOptionItem {
    id: number | string;
    value: string;
    title: string;
}
export interface SelectActions {
    handleSelected: (value: string, id: number) => void;
}
interface ActionsDropdownSelectCellProps {
    status?: string;
    value: string;
    options: DropdownOptionItem[];
    disabled?: boolean;
    statusClassName?: string;
    actions?: SelectActions;
    row?: Row;
}

export const DropdownSelectCell: React.FunctionComponent<ActionsDropdownSelectCellProps> = ({
    options,
    value,
    disabled,
    statusClassName,
    actions,
    row,
}): React.ReactElement => {
    const { userInfo } = useSelector((state: GlobalState) => state.user);
    return (
        <>
            <Form.Group className="m-0">
                <Form.Control
                    as="select"
                    value={value}
                    disabled={disabled || userInfo.userRole === UserRole.Assignee}
                    className={`select-button ${statusClassName}`}
                    onClick={(e: React.MouseEvent<HTMLSelectElement, MouseEvent>) => e.stopPropagation()}
                    onChange={(e) => {
                        actions && row && actions.handleSelected(e.target.value, row.id);
                    }}
                >
                    {options.map((option) => (
                        <option key={option.id} value={option.value}>
                            {option.title}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
        </>
    );
};
