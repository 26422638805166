import { AxiosDoctorClient } from '../utils/axiosClient';
import API from '../constants/api';
import {
    ChangePassword,
    ChangePasswordFirstTime,
    ForgotPasswordType,
    LoginFields,
    ResponseType,
    SignUpFields,
} from '../services/apiTypes';
import { VerificationLoginResponse, VerifyRegistrationResponse } from '../../redux/auth/actionTypes';

const doctorAuthServices = {
    login: async (params: LoginFields): Promise<ResponseType<VerificationLoginResponse>> => {
        const { data } = await AxiosDoctorClient.post(API.DOCTOR_AUTH.LOGIN, params);
        return data;
    },
    verifyRegistrationCode: async (params: {
        registrationCode: string;
    }): Promise<ResponseType<VerifyRegistrationResponse>> => {
        const { data } = await AxiosDoctorClient.post(API.DOCTOR_AUTH.VERIFY_REGISTRATION_CODE, params);
        return data;
    },
    signUp: async (params: SignUpFields): Promise<ResponseType<any>> => {
        const { data } = await AxiosDoctorClient.post(API.DOCTOR_AUTH.SIGN_UP, params);
        return data;
    },
    verifyCodeToSignUp: async (params: ForgotPasswordType): Promise<ResponseType<VerificationLoginResponse>> => {
        const { data } = await AxiosDoctorClient.post(API.DOCTOR_AUTH.VERIFY_CODE_TO_SIGN_UP, params);
        return data;
    },
    changePassword: async (params: ChangePassword) => {
        const { data } = await AxiosDoctorClient.post(API.AUTH.CHANGE_PASSWORD, params);
        return data;
    },
    changePasswordFirstTime: async (
        params: ChangePasswordFirstTime,
    ): Promise<ResponseType<VerificationLoginResponse>> => {
        const { data } = await AxiosDoctorClient.post(API.DOCTOR_AUTH.CHANGE_PASSWORD_FIRST_TIME, params);
        return data;
    },
};

export default doctorAuthServices;
