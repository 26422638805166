import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { DictationResponse } from '../../../../common/services/apiTypes';
import { GlobalState } from '../../../../redux';
import './AddAccountCellStyles.scss';

export interface AddAccountActions {
    handleAddAccount: (id: number) => void;
    isLoading: boolean;
    idSelect: number;
}
interface ActionsAddAccountProps {
    actions: AddAccountActions;
    row: DictationResponse;
}

export const AddAccountCell: React.FunctionComponent<ActionsAddAccountProps> = ({
    row,
    actions,
}): React.ReactElement => {
    const userInfo = useSelector((state: GlobalState) => state.user.userInfo);
    const isAddingAccount = actions.isLoading && row.id === actions.idSelect;
    const isAdded = row.editorInCharges.findIndex((item) => +item.user.id === userInfo.id) !== -1;
    return (
        <>
            {isAdded ? (
                <>Added</>
            ) : (
                <Button
                    variant="primary"
                    className="px-4 py-1 add-account-button"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        actions.handleAddAccount(row.id);
                    }}
                    disabled={isAddingAccount}
                >
                    + Add account
                    {isAddingAccount && (
                        <span className="ml-2">
                            <Spinner animation="border" size="sm" variant="light" />
                        </span>
                    )}
                </Button>
            )}
        </>
    );
};
