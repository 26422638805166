import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { route, doctorRoute } from './common/constants/routes';
import Doctor from './pages/doctor-pages/Doctor';
import DownloadApp from './pages/DownloadApp';
import Editor from './pages/editor-pages/Editor';

const App: React.FunctionComponent = (): React.ReactElement => {
    return (
        <BrowserRouter>
            <Switch>
                {/* editor page */}
                <Route path={route.ROOT} component={Editor} />
                {/* doctor page */}
                <Route path={doctorRoute.ROOT} component={Doctor} />
                <Route path={route.APP} component={DownloadApp} />
                <Redirect to={doctorRoute.HOME} />
            </Switch>
        </BrowserRouter>
    );
};

export default App;
