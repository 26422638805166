import { AxiosClient, AxiosDoctorClient } from '../utils/axiosClient';
import API from '../constants/api';

import { ResponseType, TurnAroundTimeResponse } from './apiTypes';

const settingsServices = {
    getTurnAroundTimes: async (params: {
        sortBy?: string;
        page?: number;
        pageSize?: number;
        searchValue?: string;
        sortDirection?: string;
    }): Promise<ResponseType<{ list: TurnAroundTimeResponse[]; total: number; current_page: number }>> => {
        const { data } = await AxiosClient.get(API.SETTINGS.TURN_AROUND_TIME, { params });
        return data;
    },
    createTurnAroundTime: async (params: {
        value: string;
    }): Promise<ResponseType<{ turnAroundTime: TurnAroundTimeResponse }>> => {
        const { data } = await AxiosClient.post(API.SETTINGS.TURN_AROUND_TIME, params);
        return data;
    },
    deleteTurnAroundTime: async (params: {
        id: string;
    }): Promise<ResponseType<{ turnAroundTime: TurnAroundTimeResponse }>> => {
        const { data } = await AxiosClient.delete(`${API.SETTINGS.TURN_AROUND_TIME}/${params.id}`);
        return data;
    },
    // Doctor Role
    getTurnAroundTimesDoctorRole: async (params: {
        sortBy?: string;
        page?: number;
        pageSize?: number;
        searchValue?: string;
        sortDirection?: string;
    }): Promise<ResponseType<{ list: TurnAroundTimeResponse[]; total: number; current_page: number }>> => {
        const { data } = await AxiosDoctorClient.get(API.SETTINGS.TURN_AROUND_TIME, { params });
        return data;
    },
};

export default settingsServices;
