export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';

export interface UserInfo {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    avatar: string;
    userRole: string;
    turnAroundTimeId: number;
    status: string;
    name: string;
}

interface GetUserInfo {
    type: typeof GET_USER_INFO;
    payload: true;
}

interface GetUserInfoSuccess {
    type: typeof GET_USER_INFO_SUCCESS;
    payload: UserInfo;
}

interface UserLogout {
    type: typeof USER_LOGOUT;
}

type UserType = GetUserInfo | GetUserInfoSuccess | UserLogout;

export type { UserType, GetUserInfo, GetUserInfoSuccess, UserLogout };
