import React from 'react';

import { sortCaret } from '../../../common/helpers/TableHelper';
import { FileDownLoadDTO } from '../../../common/services/apiTypes';

import { ActionsColumnFormatter } from './components/ActionsColumnFormatter/ActionsColumnFormatter';
import {
    DeleteColumnFormatter,
    DeleteTemplateAction,
    Row,
} from './components/DeleteColumnFormatter/DeleteColumnFormatter';

const formatter = (cell: any, row: FileDownLoadDTO): JSX.Element => <ActionsColumnFormatter row={row} />;
const deleteTemplateFormatter = (
    cell: any,
    row: Row,
    _rowIndex: number,
    actions: DeleteTemplateAction,
): JSX.Element => <DeleteColumnFormatter actions={actions} row={row} />;

export const renderColumns = (handleDelete: (id: number) => void) => [
    {
        dataField: 'createdAt',
        text: 'Date of Upload',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'templateName',
        text: 'Template Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'filePath',
        text: 'Template File',
        formatter: formatter,
        sort: true,
        sortCaret: sortCaret,
        headerStyle: { width: '200px' },
        style: {
            width: '200px',
            textAlign: 'center',
            textOverFlow: 'ellipsis',
            overFlow: 'hidden',
            whiteSpace: 'nowrap',
        },
    },
    {
        dataField: '',
        text: '',
        formatter: deleteTemplateFormatter,
        formatExtraData: {
            handleDelete: handleDelete,
        },
        headerStyle: { width: '100px' },
    },
];
