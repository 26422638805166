import { LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE, RESET, LoginType, LoginStatus } from './actionTypes';

export interface AuthState {
    token: string;
    isLoggingIn: boolean;
    isLoggedIn: boolean;
    loginMessage: string | undefined;
    status: LoginStatus | undefined;
}

const initialState: AuthState = {
    token: '',
    isLoggingIn: false,
    isLoggedIn: false,
    loginMessage: undefined,
    status: undefined,
};

const reducer = (state = initialState, action: LoginType): AuthState => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLoggingIn: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                status: LoginStatus.success,
                isLoggedIn: true,
                loginMessage: undefined,
                isLoggingIn: false,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                status: LoginStatus.failure,
                loginMessage: action.payload.loginMessage,
            };
        case RESET:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
