import { all, call, put, takeEvery } from 'redux-saga/effects';

import { ResponseType } from '../../common/services/apiTypes';
import { storage, storageKey } from '../../common/utils/storage';
import { route } from '../../common/constants/routes';
import authServices from '../../common/services/authServices';

import { LOGIN, LoginAction, LoginResponse, LOGOUT } from './actionTypes';
import { loginSuccess, loginFailure, reset } from './actions';

function* loginSaga(action: LoginAction) {
    try {
        const response: ResponseType<LoginResponse | null> = yield call(() => authServices.login(action.payload));
        if (response.success && !!response.data?.token) {
            storage.removeToken(storageKey.DOCTOR_TOKEN);
            storage.setToken(response.data.token, storageKey.TOKEN);
            yield put(loginSuccess({ token: response.data.token, status: response.data.status }));
            window.location.pathname = route.HOME;
        } else {
            yield put(loginFailure({ loginMessage: response.message, status: response.data?.status }));
        }
    } catch (error) {
        yield put(loginFailure({ loginMessage: 'Login Failure. Please try again!' }));
    }
}

function* logoutSaga() {
    try {
        storage.removeToken(storageKey.TOKEN);
        yield put(reset());
        //* waiting for state updating
        setTimeout(() => {
            window.location.reload();
        }, 400);
    } catch (error) {
        console.log(error);
    }
}

function* watchAll() {
    yield all([takeEvery(LOGIN, loginSaga), takeEvery(LOGOUT, logoutSaga)]);
}

export default watchAll();
