import React from 'react';

import { route } from '../../common/constants/routes';
import { authGuard } from '../../common/utils/routeGuards';
import Layout from '../../components/Layout/Layout';
import PrivateRoute from '../../components/Route/PrivateRoute';
import ChangePassword from '../common/change-password/ChangePassword';

import Assignee from './assignee/Assignee';
import Account from './account/Account';
import DictationsDetail from './dictations-detail/DictationsDetail';
import DictationsInProgress from './dictations-in-progress/DictationsInProgress';
import DictationsSubmitted from './dictations-submitted/DictationsSubmitted';
import HistoryRecord from './historical-record/HistoricalRecord';
import Home from './home/Home';
import SettingsTurnAroundTime from './settings-turn-around-time/SettingsTurnAroundTime';
import Template from './templates/Template';
import User from './users/User';

const MainPage = () => (
    <Layout>
        <PrivateRoute
            exact
            path={route.SETTINGS_TURN_AROUND_TIME}
            component={SettingsTurnAroundTime}
            guards={[authGuard]}
        />
        <PrivateRoute exact path={route.HOME} component={Home} guards={[authGuard]} />
        <PrivateRoute
            exact
            path={route.CHANGE_PASSWORD}
            component={() => <ChangePassword currentPage={'password'} />}
            guards={[authGuard]}
        />
        <PrivateRoute exact path={route.SETTINGS_ASSIGNEE} component={Assignee} guards={[authGuard]} />
        <PrivateRoute exact path={route.SETTINGS_ACCOUNT} component={Account} guards={[authGuard]} />
        <PrivateRoute exact path={route.TEMPLATE} component={Template} guards={[authGuard]} />
        <PrivateRoute exact path={route.USERS} component={User} guards={[authGuard]} />
        <PrivateRoute exact path={route.HISTORICAL_RECORD} component={HistoryRecord} guards={[authGuard]} />
        <PrivateRoute
            exact
            path={route.HISTORICAL_RECORD_DETAIL}
            component={() => <DictationsDetail titlePage="Historical Record" />}
            guards={[authGuard]}
        />
        <PrivateRoute exact path={route.DICTATIONS_IN_PROGRESS} component={DictationsInProgress} guards={[authGuard]} />
        <PrivateRoute
            path={route.DICTATIONS_IN_PROGRESS_DETAIL}
            component={() => <DictationsDetail titlePage="In Progress" />}
            guards={[authGuard]}
        />
        <PrivateRoute exact path={route.DICTATIONS_SUBMITTED} component={DictationsSubmitted} guards={[authGuard]} />
        <PrivateRoute
            path={route.DICTATIONS_SUBMITTED_DETAIL}
            component={() => <DictationsDetail titlePage="Submitted" />}
            guards={[authGuard]}
        />
        <PrivateRoute
            path={route.DICTATIONS_UNASSIGNED_DETAIL}
            component={() => <DictationsDetail titlePage="Home" />}
            guards={[authGuard]}
        />
    </Layout>
);
export default React.memo(MainPage);
