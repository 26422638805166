import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import TableToolbar, { ToolbarFilter } from '../../../components/Table/components/TableToolbar/TableToolbar';
import Table, { PaginationOptions, TableConfig } from '../../../components/Table/Table';
import { ChangeStatusUserParams, UserDTO } from '../../../common/services/apiTypes';
import SubHeaderPage from '../../../components/SubHeaderPage/SubHeaderPage';
import userServices from '../../../common/services/userServices';
import AppConstants from '../../../common/constants/app';
import { route } from '../../../common/constants/routes';
import { ActionSubject, SortType, UserRole, UserStatus } from '../../../common/constants/enum';
import './UserStyles.scss';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import { notify } from '../../../common/utils/notify';
import { GlobalState } from '../../../redux';
import { getTurnAroundTimes } from '../../../redux/settings/action';
import { tableConfigGettingTAT } from '../../../common/helpers/TableHelper';
import { getListAccountActive } from '../../../redux/account/action';

import { renderColumns, UserSelected } from './UserHelper';
import { UserActionDialog } from './components/UserActionDialog';

const initialTableConfig: TableConfig & { accountId: string } = {
    page: 1,
    pageSize: AppConstants.pagination.pageSize,
    searchValue: '',
    sortBy: 'createdAt',
    sortDirection: SortType.DESC,
    accountId: '',
};
interface UsersDTO extends UserDTO {
    accountName: string | undefined;
}

const User: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state: GlobalState) => state.user);
    const [totalSize, setTotalSize] = useState<number>(0);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [listUser, setListUser] = useState<UsersDTO[]>([]);
    const [itemAccountFilter, setItemAccountFilter] = useState<string>('');
    const [isShowDialog, setShowDialog] = useState(false);
    const [action, setAction] = useState<ActionSubject>(ActionSubject.UNDEFINED);
    const [reloadPageType, setReloadPageType] = useState<ActionSubject>(ActionSubject.UNDEFINED);
    const history = useHistory();
    const [tableConfig, setTableConfig] = useState<TableConfig & { accountId: string }>(initialTableConfig);
    const [isShowChangeStatusDialog, setShowChangeStatusDialog] = useState<boolean>(false);
    const [userSelected, setUserSelected] = useState<UserSelected>({
        id: 0,
        firstName: '',
        lastName: '',
        status: '',
        email: '',
    });
    const [isChangeStatusLoading, setChangeStatusLoading] = useState<boolean>(false);
    const listAccountActive = useSelector((state: GlobalState) => state.account.listAccountActive);
    const listAccount: ToolbarFilter[] = listAccountActive.map((item) => {
        return {
            value: item.accountName,
            id: item.id.toString(),
        };
    });

    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: totalSize,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    useEffect(() => {
        if (itemAccountFilter) getData({ ...tableConfig, page: 1, accountId: itemAccountFilter });
    }, [itemAccountFilter]);

    useEffect(() => {
        dispatch(getTurnAroundTimes(tableConfigGettingTAT));
        dispatch(getListAccountActive());
    }, []);

    const getData = async (tableConfigProps: TableConfig & { accountId: string }) => {
        setIsDataLoading(true);
        try {
            const response = await userServices.getListUser(tableConfigProps);
            const listUserFormat = response.data.list.map((item) => ({
                ...item,
                accountName: item.account?.accountName,
            }));
            setListUser(listUserFormat);
            setTotalSize(response.data.total);
            setTableConfig({ ...tableConfigProps, page: parseInt(response.data.current_page) });
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    const getHandleTableChange = (type: string, newState: any) => {
        const params = {
            page: newState.page || tableConfig.page,
            pageSize: newState.sizePerPage || tableConfig.pageSize,
            searchValue: newState.searchText || tableConfig.searchValue,
            sortBy: newState.sortField || tableConfig.sortBy,
            sortDirection: newState.sortOrder || tableConfig.sortDirection,
            accountId: tableConfig.accountId,
        };
        if (type === 'search') {
            return getData({ ...params, page: 1 });
        }
        getData(params);
    };

    const handleChangeStatusUser = async () => {
        setChangeStatusLoading(true);
        try {
            const statusNew =
                userSelected.status === UserStatus.Activated ? UserStatus.Deactivated : UserStatus.Activated;
            const request: ChangeStatusUserParams = {
                email: userSelected.email,
                status: statusNew,
            };
            const response = await userServices.changeStatusUser(request);
            if (response.success) {
                getData(tableConfig);
                notify.success(`${statusNew} successfully`, 'Success');
            } else notify.error(response.message, 'Error');
        } catch (error) {
            console.log(error);
        } finally {
            setChangeStatusLoading(false);
            setShowChangeStatusDialog(false);
        }
    };

    const handleClickRow = (row: any) => {
        history.push(`${route.USERS}/${row.id}?name=${row.name}`);
    };

    const handleOpenChangeStatusDialog = (userSelected: UserSelected) => {
        setUserSelected(userSelected);
        setShowChangeStatusDialog(true);
    };

    const handleAction = (action: ActionSubject) => {
        setAction(action);
        setShowDialog(true);
    };

    useEffect(() => {
        if (reloadPageType !== ActionSubject.UNDEFINED) {
            getData(initialTableConfig);
            setAction(ActionSubject.UNDEFINED);
            setShowDialog(false);
            setReloadPageType(ActionSubject.UNDEFINED);
        }
    }, [reloadPageType]);

    return (
        <div id="userBox">
            <UserActionDialog
                isShowDialog={isShowDialog}
                setShowDialog={setShowDialog}
                action={action}
                setReloadPageType={setReloadPageType}
            />
            <SubHeaderPage title="Client Users" />
            <Table
                tableConfig={tableConfig}
                paginationOptions={paginationOptions}
                data={listUser}
                isDataLoading={isDataLoading}
                rowEvents={handleClickRow}
                getData={getData}
                columns={renderColumns(userInfo?.userRole === UserRole.Admin, handleOpenChangeStatusDialog)}
                getHandleTableChange={getHandleTableChange}
                tableToolbar={
                    <>
                        {userInfo.userRole === UserRole.Admin && (
                            <TableToolbar textAddNewButton="Invite Client User" handleAction={handleAction} />
                        )}
                        <TableToolbar
                            isShowFilterGroup
                            listFilter={listAccount}
                            setFilterValue={setItemAccountFilter}
                            placeholderSelect="Account"
                        />
                    </>
                }
            />
            <ConfirmModal
                isOpen={isShowChangeStatusDialog}
                setIsOpen={setShowChangeStatusDialog}
                actionConfirm={() => handleChangeStatusUser()}
                titleModal={`${
                    userSelected?.status === UserStatus.Activated ? 'Deactivate' : 'Activate'
                } Client User Account`}
                textConfirm={userSelected?.status === UserStatus.Activated ? 'Deactivate' : 'Activate'}
                textReject="Cancel"
                className={`update-status-confirm-modal__${userSelected?.status?.toLocaleLowerCase()}`}
                confirmButtonClassName={`update-status-button`}
                rejectButtonClassName="cancel-button"
                isLoading={isChangeStatusLoading}
            >
                {userSelected?.status === UserStatus.Activated ? (
                    <>
                        <p>
                            You have selected to deactivate
                            <span className="text-danger">{` ${userSelected.lastName} ${userSelected.firstName}`}</span>
                            .
                        </p>
                        <p className="text-justify">
                            Deactivating this client account will disable client access to the portal. All information
                            related to this account such as historical information and transactions will be unavailable
                            until they&apos;re activated again.
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            You have selected to activate
                            <span className="text-primary">{` ${userSelected.lastName} ${userSelected.firstName}`}</span>
                            .
                        </p>
                        <p className="text-justify">
                            Activating this client account will enable client access to the portal. All information
                            related to this account such as historical information and transactions will be made
                            available.
                        </p>
                    </>
                )}
                <p>Are you sure you want to continue?</p>
            </ConfirmModal>
        </div>
    );
};

export default User;
