import { call, put, takeEvery } from 'redux-saga/effects';

import { ResponseType } from '../../common/services/apiTypes';
import appServices from '../../common/services/appServices';

import { getListAccountActiveFailure, getListAccountActiveSuccess } from './action';
import { AccountActiveType, GET_LIST_ACCOUNT_ACTIVE } from './actionTypes';

function* getListAccountActiveSaga() {
    try {
        const response: ResponseType<AccountActiveType[]> = yield call(() => appServices.getListAccountActive());
        yield put(getListAccountActiveSuccess(response.data));
    } catch (error) {
        yield put(getListAccountActiveFailure());
    }
}

function* watchAll() {
    yield takeEvery(GET_LIST_ACCOUNT_ACTIVE, getListAccountActiveSaga);
}

export default watchAll();
