import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Alert } from 'react-bootstrap';

import Table from '../../../../../components/Table/Table';
import TableToolbar from '../../../../../components/Table/components/TableToolbar/TableToolbar';
import ConfirmModal from '../../../../../components/ConfirmModal/ConfirmModal';
import { initialTableConfig, paginationOptions } from '../../DictationsDetailHelper';
import fileIcon from '../../../../../common/assets/images/fileIcon.png';
import { MAX_SIZE_ATTACH_TRANSCRIPTION_UPLOAD } from '../../../../../common/constants/app';
import { notify } from '../../../../../common/utils/notify';
import { TranscriptionResponse } from '../../../../../common/services/apiTypes';

import {
    attachTranscriptionTableActions,
    attachTranscriptionRenderColumns,
} from './AttachTranscriptionContainerHelper';
import './AttachTranscriptionTableContainerStyles.scss';

interface AttachTranscriptionContainerProps {
    isEditable: boolean;
    dictationId: string;
    isHistoricalPage: boolean;
}

const validExtension = ['doc', 'docx'];

const AttachTranscriptionContainer: React.FC<AttachTranscriptionContainerProps> = ({
    isEditable,
    dictationId,
    isHistoricalPage,
}) => {
    const [listAT, setListAT] = useState<TranscriptionResponse[]>([]);
    const [selectedAT, setSelectedAT] = useState(0);
    const [isOpenAddATModal, setIsOpenAddATModal] = useState(false);
    const [errorMessageAddAT, setErrorMessageAddAT] = useState<string | undefined>('');
    const [previewFiles, setPreviewFiles] = useState<File[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getListAT();
        setSelectedAT(0);
    }, [isEditable]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setErrorMessageAddAT('');
            const totalSize = previewFiles.reduce((total, file) => total + file.size, 0);
            let message = '';
            acceptedFiles.forEach((file) => {
                const dotIdx = file.name.lastIndexOf('.');
                const extension = file.name.slice(dotIdx + 1, file.name.length).toLowerCase();
                if (!validExtension.includes(extension)) {
                    message = 'Sorry, we could not upload this file, allowed extensions are: DOC or DOCX';
                    return false;
                }
            });
            if (totalSize > MAX_SIZE_ATTACH_TRANSCRIPTION_UPLOAD) {
                message = 'Sorry, we could not upload this file. Allowed Size is less than 50MB';
            }
            if (message) {
                setErrorMessageAddAT(message);
                return;
            }
            setPreviewFiles([...previewFiles, ...acceptedFiles]);
        },
    });

    const getListAT = async () => {
        await attachTranscriptionTableActions.getAttachTranscriptions(dictationId, setListAT);
    };

    const handleAddATAction = async () => {
        if (!isLoading) {
            if (previewFiles.length) {
                setIsLoading(true);
                try {
                    await Promise.all(
                        previewFiles.map(async (file) => {
                            const response = await attachTranscriptionTableActions.uploadTranscription(
                                dictationId,
                                file,
                            );
                            if (!response?.success) {
                                notify.error(`Fail to upload ${file.name}`, 'Error');
                            }
                        }),
                    );
                    notify.success('Upload Transcriptions', 'Success');
                } catch (error) {
                    notify.error('Failure, please try again', 'Error');
                } finally {
                    cancelModal();
                    await getListAT();
                    setIsLoading(false);
                }
            } else {
                setErrorMessageAddAT('You have to upload at least one file');
            }
        }
    };

    const handleDeleteATAction = async () => {
        if (!isLoading) {
            setIsLoading(true);
            const response = await attachTranscriptionTableActions.deleteTranscription(selectedAT);
            setSelectedAT(0);
            await getListAT();
            if (response?.success) {
                notify.success('Delete Attach Transcription', 'Success');
            } else {
                notify.error(response?.message, 'Error');
            }
            setIsLoading(false);
        }
    };

    const cancelModal = () => {
        setIsOpenAddATModal(false);
        setErrorMessageAddAT('');
        setPreviewFiles([]);
        setSelectedAT(0);
    };

    return (
        <>
            <Table
                title={`${!isHistoricalPage ? 'Attach' : ''} Transcription`}
                isHideSearchBar
                isHidePagination
                tableClassName="mb-4 max-width attach-transcription"
                tableConfig={initialTableConfig}
                paginationOptions={paginationOptions}
                data={listAT}
                isDataLoading={false}
                getData={getListAT}
                columns={attachTranscriptionRenderColumns(setSelectedAT, isEditable, isHistoricalPage)}
                tableToolbar={
                    !isHistoricalPage &&
                    isEditable && (
                        <TableToolbar textAddNewButton="Upload Files" handleAction={() => setIsOpenAddATModal(true)} />
                    )
                }
            />
            <ConfirmModal
                isOpen={isOpenAddATModal}
                setIsOpen={setIsOpenAddATModal}
                actionConfirm={handleAddATAction}
                actionCancel={cancelModal}
                titleModal="Upload Transcriptions"
                textConfirm="Add"
                className="upload-file-modal"
                confirmButtonClassName="add-button"
                isLoading={isLoading}
            >
                <div className="upload-file">
                    <div {...getRootProps({ className: 'drop-area' })}>
                        <input {...getInputProps()} />
                        <p>Drag files here</p>
                        <p>OR</p>
                        <span className="button-upload">Browse Files</span>
                    </div>
                    {errorMessageAddAT && <Alert variant="danger">{errorMessageAddAT}</Alert>}
                    {previewFiles.length ? (
                        <div className="files-area">
                            {previewFiles.map((file, index) => (
                                <div key={index} className="file">
                                    <span>
                                        <img src={fileIcon} alt="" />
                                        {file.name}
                                    </span>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            </ConfirmModal>
            <ConfirmModal
                isOpen={isEditable && selectedAT}
                setIsOpen={setSelectedAT}
                actionConfirm={handleDeleteATAction}
                actionCancel={cancelModal}
                titleModal="Delete Attach Transcription"
                textConfirm="Delete Now"
                className="turn-around-time-confirm-modal"
                confirmButtonClassName="delete-button"
            >
                <p>Are you sure to delete this Attach Transcription?</p>
            </ConfirmModal>
        </>
    );
};

export default AttachTranscriptionContainer;
