import React, { useEffect } from 'react';

const DownloadApp: React.FC = (): React.ReactElement => {
    useEffect(() => {
        window.open(`${process.env.REACT_APP_API_ENDPOINT}app`, '_self');
    }, []);
    return <></>;
};

export default DownloadApp;
