import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// Import all sagas here
import authSagas from './auth/sagas';
import doctorAuthSagas from './doctor-auth/sagas';
import userSagas from './user/sagas';
import settingsSagas from './settings/sagas';
import homeSagas from './home/sagas';
import accountSagas from './account/sagas';
// Import all reducers here
import userReducer, { UserState } from './user/reducer';
import authReducer, { AuthState } from './auth/reducer';
import doctorAuthReducer from './doctor-auth/reducer';
import settingsReducer, { settingsState } from './settings/reducer';
import homeReducer, { HomeState } from './home/reducer';
import accountReducer, { AccountState } from './account/reducer';

export interface GlobalState {
    auth: AuthState;
    doctorAuth: AuthState;
    user: UserState;
    settings: settingsState;
    home: HomeState;
    account: AccountState;
}

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['isLoggedIn'],
    blacklist: ['token', 'isLoggingIn', 'loginMessage', 'status'],
};

const doctorAuthPersistConfig = {
    key: 'doctorAuth',
    storage,
    whitelist: ['isLoggedIn'],
    blacklist: ['token', 'isLoggingIn', 'loginMessage', 'status'],
};

const settingsPersistConfig = {
    key: 'settings',
    storage,
};

const homePersistConfig = {
    key: 'home',
    storage,
};

export const combinedReducer = combineReducers({
    auth: persistReducer(authPersistConfig, authReducer),
    doctorAuth: persistReducer(doctorAuthPersistConfig, doctorAuthReducer),
    user: userReducer,
    settings: persistReducer(settingsPersistConfig, settingsReducer),
    home: persistReducer(homePersistConfig, homeReducer),
    account: accountReducer,
});

export const rootSaga = function* (): any {
    yield all([authSagas, doctorAuthSagas, userSagas, settingsSagas, homeSagas, accountSagas]);
};
