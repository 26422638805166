import { TableConfig } from '../../components/Table/Table';
import API from '../constants/api';
import { AxiosClient, AxiosDoctorClient } from '../utils/axiosClient';
import { storage, storageKey } from '../utils/storage';

import { ChangeStatusUserParams, ResponseType, UserTableResponse } from './apiTypes';

const userServices = {
    getListUser: async (params: TableConfig): Promise<UserTableResponse> => {
        const { data } = await AxiosClient.get(API.USER.GET_LIST_USER, { params });
        return data;
    },
    uploadAvatar: async (file: File): Promise<any> => {
        const formData = new FormData();
        formData.append('file', file);
        const token = storage.getToken(storageKey.DOCTOR_TOKEN);
        const { data } = await AxiosClient.post(API.USER.UPLOAD_AVATAR, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        });
        return data;
    },
    getProfileUser: async () => {
        const { data } = await AxiosClient.get(API.USER.GET_PROFILE_USER);
        return data;
    },
    getProfileDoctor: async () => {
        const { data } = await AxiosDoctorClient.get(API.USER.GET_PROFILE_USER);
        return data;
    },
    changeStatusUser: async (params: ChangeStatusUserParams): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.put(API.USER.UPDATE_STATUS_USER, params);
        return data;
    },
};

export default userServices;
