import { GET_USER_INFO, GET_USER_INFO_SUCCESS, UserInfo, UserType, USER_LOGOUT } from './actionTypes';

export interface UserState {
    userInfo: UserInfo;
    isLoadingGetUserInfo: boolean;
}

const initialState: UserState = {
    userInfo: {
        email: '',
        firstName: '',
        lastName: '',
        avatar: '',
        userRole: '',
        turnAroundTimeId: 0,
        id: 0,
        status: '',
        name: '',
    },
    isLoadingGetUserInfo: false,
};

const reducer = (state = initialState, action: UserType): UserState => {
    switch (action.type) {
        case GET_USER_INFO:
            return {
                ...state,
                isLoadingGetUserInfo: true,
            };
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.payload,
                isLoadingGetUserInfo: false,
            };
        case USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
