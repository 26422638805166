import React from 'react';
import * as Yup from 'yup';

import { sortCaret } from '../../../../../common/helpers/TableHelper';
import { AssigneeDTO, AssigneeResponse } from '../../../../../common/services/apiTypes';
import dictationsServices from '../../../../../common/services/dictationsServices';
import { notify } from '../../../../../common/utils/notify';
import { TableConfig } from '../../../../../components/Table/Table';
import { formatter } from '../../DictationsDetailHelper';

const timeFormatter = (cell: number): JSX.Element => {
    if (cell !== null) {
        const minutes =
            Math.trunc(cell / 60) >= 100
                ? `0${Math.trunc(cell / 60)}`.slice(-3)
                : `0${Math.trunc(cell / 60)}`.slice(-2);
        const seconds = `0${Math.trunc(cell % 60)}`.slice(-2);
        return <>{`${minutes}:${seconds}`} </>;
    }
    return <></>;
};

export const assigneeRenderColumns = (
    handleAction: (id: number) => void,
    isExistEIC: boolean,
    hideAllTrashIcon: boolean,
) => [
    {
        dataField: 'assignee.lastName',
        text: 'Last Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'assignee.firstName',
        text: 'First Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'from',
        text: 'From',
        formatter: timeFormatter,
    },
    {
        dataField: 'to',
        text: 'To',
        formatter: timeFormatter,
    },
    {
        dataField: '',
        text: '',
        formatter: formatter,
        formatExtraData: {
            actionDelete: handleAction,
            hideAllTrashIcon,
        },
        classes: 'text-center icon-table',
        style: {
            minWidth: '140px',
            opacity: isExistEIC ? '1' : '0.5',
        },
        headerStyle: () => {
            return { width: '7%' };
        },
    },
];

export const assigneeTableActions = {
    getListAssignee: async (
        tableConfig: TableConfig,
        setListAssignee: (listAssignee: AssigneeResponse[]) => void,
        dictationId: string,
    ) => {
        try {
            const dictationIdNumber = +dictationId;
            const paramsGetAssignee = {
                sortBy: tableConfig.sortBy,
                sortDirection: tableConfig.sortDirection,
                dictationId: dictationIdNumber,
            };
            const response = await dictationsServices.getAssignee(paramsGetAssignee);
            if (response.success) {
                setListAssignee([...response.data]);
            }
        } catch (error) {
            console.log(error);
        }
    },
    addAssignee: async (dictationId: string, assigneeId: number, from: number | null, to: number | null) => {
        try {
            const dictationIdNumber = +dictationId;
            const paramsAddAssignee = {
                dictationId: dictationIdNumber,
                assigneeId,
                from,
                to,
            };
            const response = await dictationsServices.addAssignee(paramsAddAssignee);
            return response;
        } catch (error) {
            console.log(error);
            notify.error('Failure, please try again!', 'Error');
        }
    },
    getDropdownAssignee: async (
        setListDropdownAssignee: (listDropdownAssignee: AssigneeDTO[]) => void,
        listAssignee: AssigneeResponse[],
    ) => {
        try {
            const response = await dictationsServices.getDropdownAssignee({ pageSize: 50 });
            if (response.success) {
                const filteredArray = response.data.list.filter((assignee: AssigneeDTO) => {
                    const existAssigneeIdx = listAssignee.findIndex(
                        (currentAssignee) => currentAssignee.assignee.id === assignee.id,
                    );
                    if (existAssigneeIdx === -1) {
                        return assignee;
                    }
                });
                setListDropdownAssignee([...filteredArray]);
            }
        } catch (error) {
            console.log(error);
        }
    },
    deleteAssignee: async (idAssignee: number) => {
        try {
            const response = await dictationsServices.deleteAssignee({ id: idAssignee });
            return response;
        } catch (error) {
            console.log(error);
        }
    },
};

export const formAddAssigneeSchema = Yup.object().shape({
    assignee: Yup.number().required('This field is required'),
    from: Yup.string().matches(/^((?!_).)*$/, 'Please type the correct input.'),
    to: Yup.string().matches(/^((?!_).)*$/, 'Please type the correct input.'),
});
