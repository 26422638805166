import { put, takeEvery } from '@redux-saga/core/effects';

import { getStatisticFailure, getStatisticSuccess } from './actions';
import { GET_STATISTIC } from './actionTypes';
function* getStatistic() {
    try {
        yield put(
            getStatisticSuccess({
                registerAccounts: [1, 2],
                currentTranscriptions: [3, 4, 5],
                finishedTranscriptions: [6, 7],
            }),
        );
    } catch (error) {
        yield put(getStatisticFailure());
    }
}
function* watchAll() {
    yield takeEvery(GET_STATISTIC, getStatistic);
}

export default watchAll();
