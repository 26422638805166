import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';

import InputForm from '../../../../../components/InputForm/InputForm';
import appServices from '../../../../../common/services/appServices';
import { notify } from '../../../../../common/utils/notify';
import { ActionSubject, ReturnEmailStatus } from '../../../../../common/constants/enum';
import './CreateAccountDialogStyles.scss';
import { AccountDTO } from '../../../../../common/services/apiTypes';

interface Props {
    isShowDialog: boolean;
    setShowDialog: (show: boolean) => void;
    setReloadPageType: (action: ActionSubject) => void;
}

export interface AccountFields extends Omit<AccountDTO, 'id' | 'accountStatus'> {}

const initialAccount: AccountFields = {
    accountName: '',
    returnEmail: '',
    returnEmailStatus: ReturnEmailStatus.ON,
};

export const CreateAccountDialog: React.FunctionComponent<Props> = ({
    isShowDialog,
    setShowDialog,
    setReloadPageType,
}) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const formAddAccountSchema = Yup.object().shape({
        accountName: Yup.string()
            .trim()
            .required('This field is required')
            .max(70, 'Account name length must be less than or equal to 70 characters long'),
        returnEmail: Yup.string()
            .trim()
            .required('This field is required')
            .email('Please enter a valid email address')
            .max(70, 'Email length must be less than or equal to 70 characters long'),
        returnEmailStatus: Yup.string().trim(),
        accountStatus: Yup.string().trim(),
    });

    const handleSubmitForm = async (values: AccountFields) => {
        try {
            setLoading(true);
            values.accountName = values.accountName.trim();
            values.returnEmail = values.returnEmail.trim();
            const response = await appServices.createAccount(values);
            if (response.success) {
                setReloadPageType(ActionSubject.ADD);
                setShowDialog(false);
                notify.success('Account successfully added.', 'Success');
            } else {
                setErrorMessage(response.message);
            }
        } catch (error: any) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-center"
            centered
            show={isShowDialog}
            onHide={() => setShowDialog(false)}
        >
            <Modal.Header closeButton className="model-add">
                <Modal.Title id="contained-modal-title-center">Create New Client Account</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialAccount}
                validateOnChange
                validationSchema={formAddAccountSchema}
                enableReinitialize
                onSubmit={(values) => {
                    if (isLoading) {
                        return;
                    }
                    handleSubmitForm(values);
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form
                        className="my-2"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    >
                        <Modal.Body>
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            <InputForm
                                controlId="accountName"
                                label="Account Name"
                                handleChange={handleChange}
                                name="accountName"
                                value={values.accountName}
                                labelStyle="label-add-assignee mb-1"
                                error={errors.accountName}
                                touched={touched.accountName}
                                isRequired
                            />
                            <InputForm
                                controlId="returnEmail"
                                label="Return Email"
                                handleChange={handleChange}
                                name="returnEmail"
                                value={values.returnEmail}
                                labelStyle="label-add-assignee mb-1"
                                error={errors.returnEmail}
                                touched={touched.returnEmail}
                                isRequired
                            />
                            <Form.Group controlId="returnEmailStatus">
                                <Form.Label className="label-add-assignee mb-1">
                                    Turn on return email for all client users under this account?
                                </Form.Label>
                                <div className="form-input">
                                    <Form.Check
                                        inline
                                        label="YES"
                                        value={ReturnEmailStatus.ON}
                                        onChange={handleChange}
                                        name="returnEmailStatus"
                                        type="radio"
                                        id="yes-radio"
                                        checked={values.returnEmailStatus === ReturnEmailStatus.ON}
                                    />
                                    <Form.Check
                                        inline
                                        label="NO"
                                        value={ReturnEmailStatus.OFF}
                                        onChange={handleChange}
                                        name="returnEmailStatus"
                                        type="radio"
                                        id="no-radio"
                                        checked={values.returnEmailStatus === ReturnEmailStatus.OFF}
                                    />
                                </div>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-primary cancel-button"
                                onClick={() => setShowDialog(false)}
                                className="px-4 py-1"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="px-4 py-1 save-button"
                                onClick={() => handleSubmit()}
                                disabled={
                                    isLoading ||
                                    !!errors.accountName ||
                                    !!errors.returnEmail ||
                                    !values.accountName ||
                                    !values.returnEmail
                                }
                            >
                                Save
                                {isLoading && (
                                    <span className="ml-2">
                                        <Spinner animation="border" size="sm" variant="light" />
                                    </span>
                                )}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
