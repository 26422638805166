import { AxiosClient } from '../utils/axiosClient';
import API from '../constants/api';

import {
    AddEditorInChargeParams,
    AddEditorInChargeResponse,
    AssigneeResponse,
    EditorInChargeResponse,
    GetEditorInChargeParams,
    ResponseType,
    AddAssigneeParams,
    AddAssigneeResponse,
} from './apiTypes';

const dictationsServices = {
    getEditorInCharge: async (
        params: GetEditorInChargeParams,
    ): Promise<ResponseType<{ editorInCharges: EditorInChargeResponse[] }>> => {
        const { data } = await AxiosClient.get(API.DICTATIONS.EDITOR_IN_CHARGE, { params });
        return data;
    },
    addEditorInCharge: async (
        params: AddEditorInChargeParams,
    ): Promise<ResponseType<{ editorInCharges: AddEditorInChargeResponse }>> => {
        const { data } = await AxiosClient.post(API.DICTATIONS.EDITOR_IN_CHARGE, params);
        return data;
    },
    deleteEditorInCharge: async (params: { id: number }): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.delete(`${API.DICTATIONS.EDITOR_IN_CHARGE}/${params.id}`);
        return data;
    },
    getAssignee: async (params: GetEditorInChargeParams): Promise<ResponseType<AssigneeResponse[]>> => {
        const { data } = await AxiosClient.get(`${API.DICTATIONS.ASSIGNEE_DICTATION}/${params.dictationId}`, {
            params,
        });
        return data;
    },
    addAssignee: async (params: AddAssigneeParams): Promise<ResponseType<AddAssigneeResponse>> => {
        const { data } = await AxiosClient.post(API.DICTATIONS.ASSIGNEE_DICTATION, params);
        return data;
    },
    getDropdownAssignee: async (params: { pageSize: number }): Promise<ResponseType<any>> => {
        const { data } = await AxiosClient.get(API.DICTATIONS.ASSIGNEE, { params });
        return data;
    },
    deleteAssignee: async (params: { id: number }): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.delete(`${API.DICTATIONS.ASSIGNEE_DICTATION}/${params.id}`);
        return data;
    },
    getTranscriptions: async (params: { dictationId: number }): Promise<ResponseType<any>> => {
        const { data } = await AxiosClient.get(
            `${API.DICTATIONS.GET_LIST_DICTATION}/${params.dictationId}/transcriptions`,
        );
        return data;
    },
    uploadTranscription: async (params: {
        dictationId: number;
        file: File;
    }): Promise<ResponseType<any> | undefined> => {
        const formData = new FormData();
        formData.append('file', params.file);
        const { data } = await AxiosClient.post(
            `${API.DICTATIONS.UPLOAD_TRANSCRIPTION}/${params.dictationId}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
        return data;
    },
    deleteTranscription: async (params: { id: number }): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.delete(`${API.DICTATIONS.DELETE_TRANSCRIPTION}/${params.id}`);
        return data;
    },
};

export default dictationsServices;
