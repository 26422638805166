import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import fileSaver from 'file-saver';

import 'react-circular-progressbar/dist/styles.css';
import closeIcon from '../../../common/assets/images/closeIcon.png';
import downloadIcon from '../../../common/assets/images/download.png';
import downloadedIcon from '../../../common/assets/images/downloaded.png';
import fileIcon from '../../../common/assets/images/file-icon.png';
import folderIcon from '../../../common/assets/images/folder.png';
import { doctorRoute } from '../../../common/constants/routes';
import appServices from '../../../common/services/appServices';
import { baseURL } from '../../../common/utils/axiosClient';
import { sliceFileName } from '../../../common/utils/converter';
import AppConstants from '../../../common/constants/app';
import { formatDate } from '../../../common/helpers/DateFormatHelper';
import { DictationStatus, SortType } from '../../../common/constants/enum';

import { DictationTableConfig } from './file-list/FilesList';

interface TranscriptionDTO {
    isDownloaded: boolean;
    fileName: string;
    id: number;
}

const FolderIcon = (dictationId: any) => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [listTranscription, setListTranscription] = useState<TranscriptionDTO[]>([]);
    const [listDownloaded, setListDownloaded] = useState<TranscriptionDTO[]>([]);
    const [percentDownload, setPercentDownload] = useState<{ id: number; percent: number }[]>([]);

    const initialTableConfig: DictationTableConfig = {
        dictationId: dictationId.id || 0,
        page: 1,
        pageSize: AppConstants.pagination.pageSize,
        searchValue: '',
        sortBy: 'updatedAt',
        sortDirection: SortType.DESC,
        status: `${DictationStatus.Finished},${DictationStatus.Submitted}`,
        turnAroundTime: '',
        includeEditorInCharge: true,
    };
    const [tableConfig, setTableConfig] = useState<DictationTableConfig>(initialTableConfig);

    const getDataDetail = async (tableConfigProps: DictationTableConfig) => {
        try {
            const response = await appServices.getListFileFinishedTranscriptionsDoctorRole(tableConfigProps);
            if (response.success) {
                const listFormatDate = response.data.list.map((item: any) => ({
                    ...item,
                    createdAt: formatDate(item.createdAt),
                    updatedAt: formatDate(item.updatedAt),
                    fileName: sliceFileName(item.fileName),
                }));
                setListTranscription(listFormatDate);
                const percentDownloadArr = response.data.list.map((item: any) => ({
                    id: item.id,
                    percent: 0,
                }));
                setPercentDownload([...percentDownloadArr]);
                setTableConfig({ ...tableConfigProps, page: parseInt(response.data.current_page) });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleClose = (e: any) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleClickFolderIcon = (e: any) => {
        handleScrollToTop();
        e.stopPropagation();
        history.push(`${doctorRoute.FINISHED_TRANSCRIPTIONS}/${dictationId.id}`);
    };

    const handleClickDownloadIcon = (e: any) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleDownLoad = async (id: number, fileName: string) => {
        try {
            axios({
                url: `${baseURL}/dictation/transcription/get-file/${id}`,
                method: 'GET',
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    const newPercentDownload = _.map(percentDownload, (item) => {
                        if (item.id === id) {
                            return { ...item, percent: percentCompleted };
                        }
                        return item;
                    });
                    setPercentDownload([...newPercentDownload]);
                },
            })
                .then((res) => {
                    const newListTranscription = _.map(listTranscription, (trans) => {
                        if (trans.id === id) {
                            return { ...trans, isDownloaded: true };
                        }
                        return trans;
                    });
                    setListTranscription([...newListTranscription]);
                    fileSaver.saveAs(res.data, fileName);
                })
                .then(async () => {
                    await appServices.updateDictationStatusDownload(id);
                });
        } catch (error: any) {
            console.log(error);
        }
    };

    useEffect(() => {
        const uniqueID = new Set<number>();
        const filteredArray = listTranscription.reduce((result, obj) => {
            if (obj.isDownloaded === true && !uniqueID.has(obj.id)) {
                uniqueID.add(obj.id);
                result.push(obj);
            }
            return result;
        }, [] as TranscriptionDTO[]);

        setListDownloaded(filteredArray);
    }, [listTranscription]);

    useEffect(() => {
        getDataDetail(tableConfig);
    }, []);

    return (
        <>
            <div className="folder-icon">
                <div className="files-text">{listTranscription.length} Files</div>
                <img onClick={handleClickFolderIcon} src={folderIcon} alt="" />
                <img onClick={handleClickDownloadIcon} className="downloadIcon" src={downloadIcon} alt="" />
            </div>
            <Popover
                id="basic-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography className="dictations" sx={{ p: 2 }}>
                    <div className="dictationsHeaderText">Download file(s)</div>
                    <div className="dictationsIcon" onClick={handleClose}>
                        <img src={closeIcon} className="closeIcon" alt="" />
                    </div>
                </Typography>
                {listTranscription.map((item, index) => (
                    <div key={index}>
                        <Typography sx={{ p: 2 }}>
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDownLoad(item.id, item.fileName);
                                }}
                                className="item-download"
                            >
                                <div className="fileDownload">
                                    <img src={fileIcon} alt="" />
                                    {sliceFileName(item.fileName)}
                                </div>
                                <div
                                    className={`txtProgress txtProgress--${
                                        (percentDownload.find((file) => file.id === item.id)?.percent || 0) < 100 &&
                                        (percentDownload.find((file) => file.id === item.id)?.percent || 0) > 0
                                            ? 'downloading'
                                            : 'downloaded'
                                    } `}
                                >
                                    {(percentDownload.find((file) => file.id === item.id)?.percent || 0) < 100 &&
                                    (percentDownload.find((file) => file.id === item.id)?.percent || 0) > 0
                                        ? `Downloading - ${
                                              percentDownload.find((file) => file.id === item.id)?.percent
                                          }%`
                                        : percentDownload.find((file) => file.id === item.id)?.percent === 100 ||
                                          item.isDownloaded
                                        ? 'Downloaded'
                                        : ''}
                                </div>
                                <div
                                    className="py-1 pl-2 flex-1"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDownLoad(item.id, item.fileName);
                                    }}
                                >
                                    {!item.isDownloaded ? (
                                        (percentDownload.find((file) => file.id === item.id)?.percent || 0) > 0 ? (
                                            <div style={{ width: 20, height: 20 }}>
                                                <CircularProgressbar
                                                    value={
                                                        percentDownload.find((file) => file.id === item.id)?.percent ||
                                                        0
                                                    }
                                                    strokeWidth={50}
                                                    styles={buildStyles({
                                                        strokeLinecap: 'butt',
                                                        pathColor: '#FF9900',
                                                    })}
                                                />
                                            </div>
                                        ) : (
                                            <img src={downloadIcon} alt="download" />
                                        )
                                    ) : (percentDownload.find((file) => file.id === item.id)?.percent || 0) < 100 &&
                                      (percentDownload.find((file) => file.id === item.id)?.percent || 0) > 0 ? (
                                        <div style={{ width: 20, height: 20 }}>
                                            <CircularProgressbar
                                                value={
                                                    percentDownload.find((file) => file.id === item.id)?.percent || 0
                                                }
                                                strokeWidth={50}
                                                styles={buildStyles({
                                                    strokeLinecap: 'butt',
                                                    pathColor: '#FF9900',
                                                })}
                                            />
                                        </div>
                                    ) : (
                                        <img src={downloadedIcon} alt="downloaded" />
                                    )}
                                </div>
                            </div>
                        </Typography>
                    </div>
                ))}
            </Popover>
        </>
    );
};

export default FolderIcon;
