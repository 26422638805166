import { MAX_SIZE_PHOTO_UPLOAD } from '../../../common/constants/app';

const validFiles = ['png', 'jpeg', 'jpg'];

export const validatePhoto = (file: File) => {
    if (file.size > MAX_SIZE_PHOTO_UPLOAD) {
        return 'Sorry, we could not upload this file. Allowed Size is less than 10MB';
    } else if (
        !validFiles.find(
            (rule) => rule === file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase(),
        )
    ) {
        return 'Sorry, we could not upload this file, allowed extensions are: JPEG or PNG';
    }
    return '';
};
