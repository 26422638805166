import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { doctorRoute, route } from '../../common/constants/routes';
import { storage, storageKey } from '../../common/utils/storage';
import { getUserInfo } from '../../redux/user/actions';
import Header from '../Header/Header';
import MenuSidebar from '../MenuSidebar/MenuSidebar';
import useWidth from '../../common/custom-hooks/widthHook';
import { WindowSize } from '../../common/constants/app';

import './LayoutStyles.scss';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children }): React.ReactElement => {
    const { windowWidth } = useWidth();
    const [isShowMenu, setIsShowMenu] = useState(windowWidth > WindowSize.EXTRA_LARGE ? true : false);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getUserInfo());
        validateRoute();
    }, []);

    const validateRoute = () => {
        const { pathname } = location;
        if (storage.getToken(storageKey.TOKEN)?.length) {
            const routesArray = Object.values(route).filter((item) => item !== route.ROOT);
            if (!routesArray.find((route) => pathname === route || pathname.includes(route + '/'))) {
                history.replace(route.HOME);
            }
        } else if (storage.getToken(storageKey.DOCTOR_TOKEN)?.length) {
            const routesArray = Object.values(doctorRoute).filter((item) => item !== doctorRoute.ROOT);
            if (!routesArray.find((doctorRoute) => pathname !== doctorRoute)) {
                history.replace(doctorRoute.HOME);
            }
        } else history.replace(doctorRoute.HOME);
    };

    return (
        <div className="layout-app">
            <div className="menu-app">
                <div className={`menu-content ${isShowMenu ? 'menu-content__show' : ''}`}>
                    <MenuSidebar isShowMenu={isShowMenu} setIsShowMenu={setIsShowMenu} />
                </div>
                {windowWidth <= WindowSize.EXTRA_LARGE && isShowMenu ? (
                    <div className="layer" onClick={() => setIsShowMenu(false)}></div>
                ) : null}
            </div>
            <div className={`layout-content ${isShowMenu && windowWidth > WindowSize.EXTRA_LARGE ? 'resize' : ''}`}>
                <Header isShowMenu={isShowMenu} />
                <div className="children-padding">{children}</div>
            </div>
        </div>
    );
};

export default Layout;
