import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import emptyPage from '../../../common/assets/images/home-empty.png';
import AppConstants from '../../../common/constants/app';
import { ActionSubject, DictationStatus, SortType } from '../../../common/constants/enum';
import { formatDate } from '../../../common/helpers/DateFormatHelper';
import { tableConfigGettingTAT } from '../../../common/helpers/TableHelper';
import { DictationResourceType, DictationResponse } from '../../../common/services/apiTypes';
import appServices from '../../../common/services/appServices';
import SubHeaderPage from '../../../components/SubHeaderPage/SubHeaderPage';
import Table, { PaginationOptions } from '../../../components/Table/Table';
import { ExpandContent } from '../../../components/Table/components/ExpandRowContent/ExpandRowContent';
import TableToolbar from '../../../components/Table/components/TableToolbar/TableToolbar';
import { GlobalState } from '../../../redux';
import { getStatistic } from '../../../redux/home/actions';
import { getTurnAroundTimes } from '../../../redux/settings/action';
import { DictationTableConfig } from '../../editor-pages/dictations-in-progress/DictationsInProgress';

import { renderColumns } from './HomeHelper';

import './HomeStyles.scss';

const initialTableConfig: DictationTableConfig = {
    page: 1,
    pageSize: AppConstants.pagination.pageSize,
    searchValue: '',
    sortBy: 'createdAt',
    sortDirection: SortType.DESC,
    status: `${DictationStatus.Unassign},${DictationStatus.Submitted},${DictationStatus.InProgress}`,
    turnAroundTime: '',
};

const Home: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state: GlobalState) => state.user);
    const [tableConfig, setTableConfig] = useState<DictationTableConfig>(initialTableConfig);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [totalSize, setTotalSize] = useState<number>(0);
    const turnAroundTime = useSelector((state: GlobalState) => state.settings.turnAroundTime.list);
    const [listDictation, setListDictation] = useState<DictationResponse[]>([]);
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [listResourceFile, setListResourceFile] = useState<DictationResourceType[]>([]);
    const [itemTATFilter, setItemTATFilter] = useState<string>('');
    const [reloadPageType, setReloadPageType] = useState<ActionSubject>(ActionSubject.UNDEFINED);
    const [expanded, _] = useState<number[]>([]);
    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: totalSize,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    const getData = async (tableConfigProps: DictationTableConfig) => {
        setIsDataLoading(true);
        try {
            const response = await appServices.getListDictationDoctorRole(tableConfigProps);
            if (response.success) {
                const listFormatDate = response.data.list.map((item) => ({
                    ...item,
                    createdAt: formatDate(item.createdAt),
                    userName: item.user.name,
                }));
                setListDictation([...listFormatDate]);
                setTableConfig({ ...tableConfigProps, page: parseInt(response.data.current_page) });
                setTotalSize(response.data.total);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    const getHandleTableChange = (type: string, newState: any) => {
        const params = {
            page: newState.page || 1,
            pageSize: newState.sizePerPage || AppConstants.pagination.pageSize,
            searchValue: newState.searchText || '',
            sortBy: newState.sortField || 'createdAt',
            sortDirection: newState.sortOrder || 'desc',
            status: tableConfig.status,
            turnAroundTime: tableConfig.turnAroundTime,
        };
        if (params.sortBy === 'userName') {
            params.sortBy = 'name';
        }
        if (type === 'search') {
            setIsSearch(true);
            return getData({ ...params, page: 1 });
        }
        getData(params);
    };

    const getResourceData = async (dictationId: number, setResource: (resource: DictationResourceType) => void) => {
        try {
            const response = await appServices.getDictationResourceDoctorRole(dictationId);
            if (response.success) {
                setListResourceFile([...listResourceFile, response.data]);
                setResource(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const renderExpandContent = (row: any) => {
        return (
            <ExpandContent getResourceData={getResourceData} dictationId={row.id} listResourceFile={listResourceFile} />
        );
    };

    const expandRow = {
        renderer: (row: any) => renderExpandContent(row),
        onExpand: (row: any, isExpand: boolean) => {
            const data = listDictation.map((i) => (i.id === row.id ? { ...row, opened: isExpand } : { ...i }));
            setListDictation(data);
            const index = expanded.indexOf(row.id);
            if (index !== -1) {
                expanded.splice(index, 1);
            } else {
                expanded.push(row.id);
            }
        },
        expanded: expanded,
    };

    //TODO: ListFilter : if you want to get the old logic back
    // const tableFilter =
    //     (turnAroundTime?.length && turnAroundTime.map((t) => ({ id: parseInt(t.id), value: t.value }))) || [];

    useEffect(() => {
        dispatch(getStatistic());
        dispatch(getTurnAroundTimes(tableConfigGettingTAT));
    }, []);

    useEffect(() => {
        if (itemTATFilter) getData({ ...tableConfig, page: 1, turnAroundTime: itemTATFilter });
    }, [itemTATFilter]);

    useEffect(() => {
        if (reloadPageType !== ActionSubject.UNDEFINED) {
            getData(initialTableConfig);
            setReloadPageType(ActionSubject.UNDEFINED);
        }
    }, [reloadPageType]);

    useEffect(() => {
        getData(tableConfig);
    }, []);

    return (
        <>
            {listDictation.length > 0 || itemTATFilter || isSearch ? (
                <div className="container">
                    <SubHeaderPage title="Recent Uploads" />
                    <Table
                        tableConfig={tableConfig}
                        paginationOptions={paginationOptions}
                        data={listDictation}
                        isDataLoading={isDataLoading}
                        getData={getData}
                        columns={renderColumns()}
                        getHandleTableChange={getHandleTableChange}
                        expandRow={expandRow}
                        rowClasses="item-table"
                        //TODO: ListFilter : if you want to get the old logic back
                        // tableToolbar={
                        //     <TableToolbar
                        //         isShowFilterGroup
                        //         listFilter={tableFilter}
                        //         setFilterValue={setItemTATFilter}
                        //     />

                        // }
                        tableToolbar={
                            <TableToolbar
                                isShowFilterGroup
                                listFilter={[
                                    {
                                        value:
                                            turnAroundTime.find((tat) => +tat.id === userInfo.turnAroundTimeId)
                                                ?.value || '',
                                    },
                                ]}
                                setFilterValue={setItemTATFilter}
                            />
                        }
                    />
                </div>
            ) : (
                <div className="empty-page">
                    <div className="empty-element">
                        <img src={emptyPage} className="empty-image" alt="" />
                        <p>Go to “upload dictations” tab to upload your first audio</p>
                    </div>
                </div>
            )}
        </>
    );
};

export default Home;
