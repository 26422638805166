import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { VerificationPage } from '../../../../common/constants/enum';
import { doctorRoute } from '../../../../common/constants/routes';
import { SignUpFields } from '../../../../common/services/apiTypes';
import doctorAuthServices from '../../../../common/services/doctorAuthServices';
import AuthForm from '../../../../components/AuthForm/AuthForm';
import ButtonForm from '../../../../components/ButtonForm/ButtonForm';
import DropdownForm, { OptionSelectType } from '../../../../components/DropdownForm/DropdownForm';
import InputForm, { InputType } from '../../../../components/InputForm/InputForm';
import { GlobalState } from '../../../../redux';
import { getListAccountActive } from '../../../../redux/account/action';
import { getTurnAroundTimes } from '../../../../redux/settings/action';

import './SignUpStyles.scss';

const SignUp: React.FC = (): React.ReactElement => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentLocation = location.pathname;
    const history = useHistory<{ registrationCode: string; email?: string; page?: string }>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const turnaroundTime = useSelector((state: GlobalState) => state.settings.turnAroundTime.list);
    const listAccountActive = useSelector((state: GlobalState) => state.account.listAccountActive);

    const listAccount: OptionSelectType[] = listAccountActive.map((item) => {
        return {
            text: item.accountName,
            value: item.id.toString(),
        };
    });
    const listTAT: OptionSelectType[] = turnaroundTime.map((item) => {
        return {
            text: item.value,
            value: item.id,
        };
    });

    useEffect(() => {
        if (!turnaroundTime?.length) {
            dispatch(
                getTurnAroundTimes({
                    sortBy: '',
                    page: 1,
                    pageSize: 50,
                    sortDirection: '',
                }),
            );
        }
        dispatch(getListAccountActive());
    }, []);

    const handleSignUp = async (values: Omit<SignUpFields, 'registrationCode'>) => {
        setIsLoading(true);
        setErrorMessage('');
        try {
            const response = await doctorAuthServices.signUp({
                firstName: values.firstName,
                lastName: values.lastName,
                accountId: values.accountId,
                email: values.email,
                turnAroundTimeId: values.turnAroundTimeId,
                password: values.password,
                registrationCode: history.location.state?.registrationCode,
                name: values.name,
            });
            if (response.success) {
                history.push(doctorRoute.VERIFICATION, {
                    registrationCode: history.location.state?.registrationCode,
                    email: values.email,
                    page: VerificationPage.SIGN_UP,
                });
            } else {
                setErrorMessage(response.message);
            }
        } catch (error) {
            setErrorMessage('Failure, please try again!');
        } finally {
            setIsLoading(false);
        }
    };

    const signUpSchema = Yup.object().shape({
        name: Yup.string().max(100, 'Length must be less than 100 characters.').required('This field is required'),
        accountId: Yup.string().required('This field is required'),
        turnAroundTimeId: Yup.string().required('This field is required'),
        email: Yup.string()
            .email('Please enter a valid email address')
            .max(70, 'Email length must be less than or equal to 70 characters long')
            .required('This field is required'),
        password: Yup.string()
            .required('This field is required')
            .matches(/^[0-9]{4}$/, '4 numbers is required'),
        confirmationPassword: Yup.string()
            .required('This field is required')
            .matches(/^[0-9]{4}$/, '4 numbers is required')
            .when('password', {
                is: (password: string) => password !== undefined,
                then: Yup.string().oneOf([Yup.ref('password')], 'Passcode does not match'),
            }),
    });

    return (
        <AuthForm
            isShowRedirect={true}
            responsiveClass={'sign-up-responsive'}
            textRedirect="Back to sign in"
            linkRedirect={doctorRoute.WELCOME}
            currentLocation={currentLocation}
        >
            <p className="text-primary mt-3 mb-2 paragraph-main">Sign Up</p>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    accountId: '',
                    turnAroundTimeId: '',
                    email: '',
                    password: '',
                    confirmationPassword: '',
                    name: '',
                }}
                validateOnChange
                validationSchema={signUpSchema}
                enableReinitialize
                onSubmit={(values) => {
                    handleSignUp({
                        ...values,
                        turnAroundTimeId: +values.turnAroundTimeId,
                        email: values.email.toLowerCase(),
                    });
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form
                        className="mt-4"
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                            }
                        }}
                    >
                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        <InputForm
                            controlId="nameSignUp"
                            label="Username"
                            handleChange={handleChange}
                            name="name"
                            value={values.name}
                            labelStyle="text-color-primary mb-1 paragraph-medium"
                            error={(touched.name && errors.name) || ''}
                            setErrorMessageForm={setErrorMessage}
                            formGroupStyle="inputForm"
                        />
                        <DropdownForm
                            controlId="tatSignUp"
                            label="Turnaround Time"
                            handleChange={handleChange}
                            name="turnAroundTimeId"
                            value={values.turnAroundTimeId}
                            labelStyle="text-color-primary dropdownForm mb-1 paragraph-medium"
                            error={(touched.turnAroundTimeId && errors.turnAroundTimeId) || ''}
                            defaultText="Select a TAT"
                            listOption={listTAT}
                            setErrorMessageForm={setErrorMessage}
                            formGroupStyle="dropdownForm text-color-primary"
                        />
                        <DropdownForm
                            controlId="accountSignUp"
                            label="Account"
                            handleChange={handleChange}
                            name="accountId"
                            value={values.accountId}
                            labelStyle="text-color-primary dropdownForm mb-1 paragraph-medium"
                            error={(touched.accountId && errors.accountId) || ''}
                            defaultText="Select an Account"
                            listOption={listAccount}
                            setErrorMessageForm={setErrorMessage}
                            formGroupStyle="dropdownForm"
                        />

                        <InputForm
                            controlId="emailSignUp"
                            label="Email Address"
                            handleChange={handleChange}
                            name="email"
                            value={values.email}
                            labelStyle="text-color-primary mb-1 paragraph-medium"
                            error={(touched.email && errors.email) || ''}
                            setErrorMessageForm={setErrorMessage}
                            formGroupStyle="inputForm"
                        />
                        <InputForm
                            controlId="passwordSignUp"
                            label="Enter new Passcode"
                            handleChange={handleChange}
                            name="password"
                            type={InputType.Password}
                            value={values.password}
                            labelStyle="text-color-primary mb-1 paragraph-medium"
                            error={(touched.password && errors.password) || ''}
                            setErrorMessageForm={setErrorMessage}
                            formGroupStyle="inputForm"
                        />
                        <InputForm
                            controlId="confirmationPasswordSignUp"
                            label="Confirm new Passcode"
                            handleChange={handleChange}
                            name="confirmationPassword"
                            type={InputType.Password}
                            value={values.confirmationPassword}
                            labelStyle="text-color-primary mb-1 paragraph-medium"
                            error={(touched.confirmationPassword && errors.confirmationPassword) || ''}
                            setErrorMessageForm={setErrorMessage}
                            formGroupStyle="inputForm"
                        />
                        <ButtonForm buttonName="REGISTER" handleSubmit={handleSubmit} isLoading={isLoading} />
                    </Form>
                )}
            </Formik>
        </AuthForm>
    );
};

export default SignUp;
