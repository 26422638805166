import React from 'react';
import { Card } from 'react-bootstrap';

interface ContactSupportProps {
    cardClass?: React.ReactNode;
}
const ContactSupport: React.FunctionComponent<ContactSupportProps> = ({ cardClass }): React.ReactElement => {
    return (
        <Card className={`mt-5 ${cardClass}`}>
            <Card.Body className="lb-help py-2">
                <label className="d-block m-0">Need help Signing in?</label>
                <label className="d-block m-0">
                    Please contact&nbsp;
                    <a href="mailto:support@jivescribe.com" className="text-color-orange">
                        support@jivescribe.com&nbsp;
                    </a>
                    for help.
                </label>
            </Card.Body>
        </Card>
    );
};

export default ContactSupport;
