import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { MenuSidebarItemType } from '../../MenuSidebar';
import './MenuSidebarDropdownItemStyles.scss';
import { storage, storageKey } from '../../../../common/utils/storage';

interface MenuSidebarDropdownItemProps {
    option: MenuSidebarItemType;
    currentRoute: string;
    setCurrentRoute: (path: string) => void;
}

const MenuSidebarDropdownItem: React.FunctionComponent<MenuSidebarDropdownItemProps> = ({
    option,
    currentRoute,
    setCurrentRoute,
}): React.ReactElement => {
    const [isDropdown, setIsDropdown] = useState(false);
    const [activeIcon, setActiveIcon] = useState(true);
    const history = useHistory();

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleClickItem = (path: string) => {
        setCurrentRoute(path);
        history.push(path);
        setActiveIcon(true);
        handleScrollToTop();
    };

    useEffect(() => {
        const idxNestedOption = option.nestedOptions.findIndex((nestedOption) =>
            currentRoute.includes(nestedOption.path),
        );
        if (idxNestedOption !== -1) {
            setIsDropdown(true);
        }
    }, []);

    const role = storage.getToken(storageKey.TOKEN)?.length ? 'editor' : 'doctor';

    return (
        <div className={`option-dropdown`}>
            <div
                className={`option ${currentRoute === option.path ? 'option--chosen1' : ''}`}
                onClick={() => setIsDropdown(!isDropdown)}
            >
                <div className="option__icon ml-4">
                    <img src={option.icon} alt="" />
                </div>
                <div className={`option__text option-nested-dropdown option-nested-dropdown-${role}`}>
                    {option.text}
                    {isDropdown ? (
                        <i className="fa fa-caret-up" aria-hidden="true"></i>
                    ) : (
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                    )}
                </div>
            </div>
            <div
                className={`nested-options ${
                    isDropdown
                        ? option.nestedOptions.length === 3
                            ? 'nested-options--show-3'
                            : 'nested-options--show'
                        : ''
                }`}
            >
                {option.nestedOptions.map((nestedOption, index) => (
                    <div
                        key={index}
                        className={`nested-options__text  ${
                            currentRoute.includes(nestedOption.path) ? 'option--chosen nested-option--chosen' : ''
                        }`}
                        onClick={() => handleClickItem(nestedOption.path)}
                    >
                        <div className="option__icon ">
                            {nestedOption.icon && (
                                <img
                                    src={
                                        activeIcon && currentRoute.includes(nestedOption.path)
                                            ? nestedOption.iconActive
                                            : nestedOption.icon
                                    }
                                    alt=""
                                />
                            )}
                        </div>
                        <div
                            className={`option__text ml-3 ${
                                currentRoute.includes(nestedOption.path)
                                    ? 'option__text--active'
                                    : `option__text-${role}`
                            }`}
                        >
                            {nestedOption.text}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MenuSidebarDropdownItem;
