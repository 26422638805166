import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { AccountStatus, ActionAccount, ActionSubject, ReturnEmailStatus } from '../../../../common/constants/enum';
import { AccountDTO } from '../../../../common/services/apiTypes';
import { ChangeReturnEmailDialog } from '../../../../pages/editor-pages/account/components/ChangeReturnEmailDialog/ChangeReturnEmailDialog';
import { ConfirmAccountDialog } from '../../../../pages/editor-pages/account/components/ConfirmAccountDialog/ConfirmAccountDialog';
import './AccountActionCellStyles.scss';

export interface AccountActionType {
    setReloadPageType: (action: ActionSubject) => void;
}
interface ActionAccountCellProps {
    actions: AccountActionType;
    row: AccountDTO;
}

export const AccountActionCell: React.FunctionComponent<ActionAccountCellProps> = ({
    actions,
    row,
}): React.ReactElement => {
    const [isShowDialog, setShowDialog] = useState(false);
    const [isShowConfirmDialog, setShowConfirmDialog] = useState(false);
    const [accountAction, setAccountAction] = useState<ActionAccount | undefined>(undefined);
    const popover = (
        <Popover id="popover-basic" className="p-2">
            <div>
                <a
                    className="btn p-0"
                    onClick={() => {
                        setShowDialog(true);
                        document.body.click();
                    }}
                >
                    Change Return Email Address
                </a>
            </div>
            <div>
                <a
                    className="btn p-0"
                    onClick={() => {
                        setShowConfirmDialog(true);
                        setAccountAction(
                            row.returnEmailStatus === ReturnEmailStatus.ON
                                ? ActionAccount.TURN_OFF_RETURN_EMAIL
                                : ActionAccount.TURN_ON_RETURN_EMAIL,
                        );
                        document.body.click();
                    }}
                >
                    {row.returnEmailStatus === ReturnEmailStatus.ON ? 'Turn OFF' : 'Turn ON'}
                </a>
            </div>
            <div>
                <a
                    className={`btn p-0 ${
                        row.accountStatus === AccountStatus.INACTIVE ? 'active-account' : 'inactive-account'
                    }`}
                    onClick={() => {
                        setShowConfirmDialog(true);
                        setAccountAction(
                            row.accountStatus === AccountStatus.INACTIVE
                                ? ActionAccount.ACTIVE_ACCOUNT
                                : ActionAccount.INACTIVE_ACCOUNT,
                        );
                        document.body.click();
                    }}
                >
                    {row.accountStatus === AccountStatus.INACTIVE ? 'Activate' : 'Deactivate'} Account
                </a>
            </div>
        </Popover>
    );

    return (
        <>
            <ChangeReturnEmailDialog
                isShowDialog={isShowDialog}
                setShowDialog={setShowDialog}
                accountId={row.id.toString()}
                setReloadPageType={actions.setReloadPageType}
            />
            <ConfirmAccountDialog
                isShowDialog={isShowConfirmDialog}
                setShowDialog={setShowConfirmDialog}
                rowAccount={row}
                accountAction={accountAction}
                setReloadPageType={actions.setReloadPageType}
            />
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                <a className="btn btn-icon btn-sm btn-play px-2 py-0">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <i className="fa fa-ellipsis-h" />
                    </span>
                </a>
            </OverlayTrigger>
        </>
    );
};
