import React from 'react';

import { doctorRoute } from '../../common/constants/routes';
import { doctorAuthGuard } from '../../common/utils/routeGuards';
import Layout from '../../components/Layout/Layout';
import PrivateRoute from '../../components/Route/PrivateRoute';
import ChangePassword from '../common/change-password/ChangePassword';

import Home from './home/Home';
import Template from './templates/Template';
import FinishedTranscription from './transcriptions/historical-audio/FinishedTranscriptions';
import UploadDictation from './upload-dictation/UploadDictation';
import TranscriptionOnlyList from './transcriptions/transcription-only-list/TranscriptionOnlyList';
import FilesList from './transcriptions/file-list/FilesList';

const MainPage = () => (
    <Layout>
        <PrivateRoute path={doctorRoute.HOME} component={Home} guards={[doctorAuthGuard]} />
        <PrivateRoute path={doctorRoute.TEMPLATES} component={Template} guards={[doctorAuthGuard]} />
        <PrivateRoute
            exact
            path={doctorRoute.CHANGE_PASS_CODE}
            component={() => <ChangePassword currentPage={'passcode'} />}
            guards={[doctorAuthGuard]}
        />
        <PrivateRoute path={doctorRoute.UPLOAD_DICTATION} component={UploadDictation} guards={[doctorAuthGuard]} />
        <PrivateRoute
            exact
            path={doctorRoute.FINISHED_TRANSCRIPTIONS}
            component={FinishedTranscription}
            guards={[doctorAuthGuard]}
        />
        <PrivateRoute
            exact
            path={doctorRoute.FINISHED_TRANSCRIPTIONS_FILES_LIST}
            component={FilesList}
            guards={[doctorAuthGuard]}
        />
        <PrivateRoute
            path={doctorRoute.TRANSCRIPTION_ONLY_LIST}
            component={TranscriptionOnlyList}
            guards={[doctorAuthGuard]}
        />
    </Layout>
);

export default React.memo(MainPage);
