import {
    AccountActiveType,
    AccountType,
    GET_LIST_ACCOUNT_ACTIVE,
    GET_LIST_ACCOUNT_ACTIVE_SUCCESS,
} from './actionTypes';

export interface AccountState {
    listAccountActive: AccountActiveType[];
}

const initialState: AccountState = {
    listAccountActive: [],
};

const reducer = (state = initialState, action: AccountType): AccountState => {
    switch (action.type) {
        case GET_LIST_ACCOUNT_ACTIVE:
            return {
                ...state,
            };
        case GET_LIST_ACCOUNT_ACTIVE_SUCCESS:
            return {
                ...state,
                listAccountActive: [...action.payload],
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
