import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LOGOUT } from '../../redux/auth/actionTypes';
import changePasswordIcon from '../../common/assets/images/changePasswordIcon.png';
import logoutIcon from '../../common/assets/images/logoutIcon.png';
import { GlobalState } from '../../redux';
import { doctorRoute, route } from '../../common/constants/routes';
import { UserRole } from '../../common/constants/enum';
import { DOCTOR_LOGOUT } from '../../redux/doctor-auth/actionTypes';
import useWidth from '../../common/custom-hooks/widthHook';
import { WindowSize } from '../../common/constants/app';
import { userLogout } from '../../redux/user/actions';
import './HeaderStyles.scss';
import { storage, storageKey } from '../../common/utils/storage';

interface HeaderProps {
    isShowMenu: boolean;
}
const Header: React.FunctionComponent<HeaderProps> = ({ isShowMenu }): React.ReactElement => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userInfo, isLoadingGetUserInfo } = useSelector((state: GlobalState) => state.user);
    const { windowWidth } = useWidth();

    const isUserRoleStaff =
        userInfo.userRole === UserRole.Editor ||
        userInfo.userRole === UserRole.Admin ||
        userInfo.userRole === UserRole.Assignee;

    const handleLogout = () => {
        if (isUserRoleStaff) {
            dispatch({ type: LOGOUT });
        } else {
            dispatch({ type: DOCTOR_LOGOUT });
        }
        dispatch(userLogout());
    };

    const name = isUserRoleStaff ? `${userInfo?.firstName} ${userInfo?.lastName}` : `${userInfo?.name}`;
    const role = storage.getToken(storageKey.TOKEN)?.length ? 'editor' : 'doctor';

    return (
        <div className={`header header-${role}`}>
            <Navbar className="p-0">
                {(windowWidth <= WindowSize.SMALL && !isShowMenu) || windowWidth > WindowSize.SMALL
                    ? !isLoadingGetUserInfo && (
                          <NavDropdown
                              id={role === 'editor' ? 'user-options-editor' : 'user-options'}
                              title={`${name.substring(0, 20)}${name.length <= 20 ? '' : '...'}`}
                              className={
                                  userInfo?.name?.length >= 6 ||
                                  `${userInfo?.firstName} ${userInfo?.lastName}`.length >= 6
                                      ? ''
                                      : 'dropdown-sort-name'
                              }
                          >
                              <NavDropdown.Item
                                  onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                      e.preventDefault();
                                      if (isUserRoleStaff) {
                                          history.push(route.CHANGE_PASSWORD);
                                      } else {
                                          history.push(doctorRoute.CHANGE_PASS_CODE);
                                      }
                                  }}
                              >
                                  <div className="item-icon mr-2">
                                      <img src={changePasswordIcon} alt="" />
                                  </div>
                                  <div className="item-text">
                                      Change {`${isUserRoleStaff ? 'Password' : 'Passcode'}`}
                                  </div>
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                  onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                      e.preventDefault();
                                      handleLogout();
                                  }}
                              >
                                  <div className="item-icon mr-2">
                                      <img src={logoutIcon} alt="" />
                                  </div>
                                  <div className="item-text">Logout</div>
                              </NavDropdown.Item>
                          </NavDropdown>
                      )
                    : null}
            </Navbar>
        </div>
    );
};

export default Header;
