import React from 'react';
import './AccountStatusCellStyles.scss';

interface ActionAccountStatusCellProps {
    value: string;
}

export const AccountStatusCell: React.FunctionComponent<ActionAccountStatusCellProps> = ({
    value,
}): React.ReactElement => {
    return (
        <>
            <span className={value === 'ON' || value === 'ACTIVE' ? 'active-account' : 'inactive-account'}>
                {value}
            </span>
        </>
    );
};
