import {
    LoginFailureAction,
    LoginFailureResponse,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    RESET,
    LoginParams,
    LoginResponse,
    LoginType,
} from './actionTypes';

export const login = (params: LoginParams): LoginType => {
    return {
        type: LOGIN,
        payload: params,
    };
};

export const loginSuccess = (payload: LoginResponse): LoginType => {
    return {
        type: LOGIN_SUCCESS,
        payload,
    };
};

export const loginFailure = (payload: LoginFailureResponse): LoginFailureAction => {
    return {
        type: LOGIN_FAILURE,
        payload,
    };
};

export const reset = (): LoginType => {
    return {
        type: RESET,
    };
};
