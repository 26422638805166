import React, { useEffect, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import trashIcon from '../../../common/assets/images/trashIcon.png';
import { createTurnAroundTime, deleteTurnAroundTime, getTurnAroundTimes } from '../../../redux/settings/action';
import { GlobalState } from '../../../redux';
import Table, { PaginationOptions, TableConfig } from '../../../components/Table/Table';
import { sortCaret } from '../../../common/helpers/TableHelper';
import SubHeaderPage from '../../../components/SubHeaderPage/SubHeaderPage';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import { TurnAroundTimeResponse } from '../../../common/services/apiTypes';
import AppConstants from '../../../common/constants/app';
import { SortType, UserRole } from '../../../common/constants/enum';

import './SettingsTurnAroundTimeStyles.scss';

const SettingsTurnAroundTime: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const currentData = useSelector((state: GlobalState) => state.settings.turnAroundTime);
    const { userInfo } = useSelector((state: GlobalState) => state.user);
    const isUserRoleIsAdminOrEditor = userInfo.userRole === UserRole.Admin || userInfo.userRole === UserRole.Editor;
    const [valueNewItem, setValueNewItem] = useState('');
    const [selectedDeleteItem, setSelectedDeleteItem] = useState('');

    const [tableConfig, setTableConfig] = useState<TableConfig>({
        page: 1,
        pageSize: AppConstants.pagination.pageSize,
        sortBy: '',
        sortDirection: SortType.DESC,
    });

    useEffect(() => {
        if (tableConfig.page > 1 && currentData.total % 10 === 0) {
            if (+currentData.current_page === tableConfig.page - 1) {
                setTableConfig({ ...tableConfig, page: tableConfig.page - 1 });
            }
        }
    }, [currentData.total]);

    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: currentData.total,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    const handleDeleteItem = () => {
        if (tableConfig.page > 1 && (currentData.total - 1) % 10 === 0) {
            dispatch(deleteTurnAroundTime({ ...tableConfig, page: tableConfig.page - 1, id: selectedDeleteItem }));
        } else {
            dispatch(deleteTurnAroundTime({ ...tableConfig, id: selectedDeleteItem }));
        }
        setSelectedDeleteItem('');
    };

    const actionFormatter = (_cell: any, row: TurnAroundTimeResponse) => {
        return (
            <div className="delete-icon" onClick={() => setSelectedDeleteItem(row.id)}>
                <img src={trashIcon} alt="" />
            </div>
        );
    };

    const renderColumns = (isUserRoleIsAdminOrEditor: boolean) =>
        isUserRoleIsAdminOrEditor
            ? [
                  {
                      dataField: 'value',
                      text: 'Turnaround Time',
                      sort: true,
                      sortCaret: sortCaret,
                  },
                  {
                      dataField: 'delete',
                      text: '',
                      isDummyField: true,
                      formatter: actionFormatter,
                      headerStyle: { width: '4rem' },
                  },
              ]
            : [
                  {
                      dataField: 'value',
                      text: 'Turnaround Time',
                      sort: true,
                      sortCaret: sortCaret,
                  },
              ];

    const getData = (tableConfig: TableConfig) => {
        dispatch(getTurnAroundTimes({ ...tableConfig }));
    };

    const getHandleTableChange = (_type: string, newState: any) => {
        const params = {
            page: newState.page || 1,
            pageSize: newState.sizePerPage || AppConstants.pagination.pageSize,
            sortBy: newState.sortField || '',
            sortDirection: newState.sortOrder || SortType.DESC,
        };
        setTableConfig({ ...params });
        getData(params);
    };

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValueNewItem(e.target.value);
    };

    const handleAddNewItem = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (valueNewItem.trim()) {
            dispatch(
                createTurnAroundTime({
                    ...tableConfig,
                    sortBy: '',
                    sortDirection: SortType.DESC,
                    value: valueNewItem.trim(),
                }),
            );
            setTableConfig({ ...tableConfig, sortBy: '', sortDirection: SortType.DESC });
            setValueNewItem('');
        }
    };

    return (
        <div className="turn-around-time-page">
            <SubHeaderPage title="Turnaround Time" />
            <div className="content-page">
                <ConfirmModal
                    className="turn-around-time-confirm-modal"
                    isOpen={selectedDeleteItem}
                    setIsOpen={setSelectedDeleteItem}
                    actionConfirm={handleDeleteItem}
                    titleModal="Confirm delete"
                    textConfirm="Delete Now"
                    confirmButtonClassName="delete-button"
                >
                    <p>Are you sure to delete this Turnaround Time?</p>
                </ConfirmModal>

                <div className={`table-items ${!isUserRoleIsAdminOrEditor ? 'full-with' : ''}`}>
                    <Table
                        data={currentData.list}
                        isDataLoading={currentData.isLoadingData}
                        getData={getData}
                        tableConfig={tableConfig}
                        paginationOptions={paginationOptions}
                        columns={renderColumns(isUserRoleIsAdminOrEditor)}
                        isHideSearchBar
                        getHandleTableChange={getHandleTableChange}
                    />
                </div>
                {isUserRoleIsAdminOrEditor && (
                    <div className="add-new-item">
                        <Card>
                            <Card.Title>
                                <p>Add New Turnaround Time</p>
                            </Card.Title>
                            <Form onSubmit={handleAddNewItem}>
                                <Form.Control
                                    name="newItem"
                                    type="text"
                                    value={valueNewItem}
                                    onChange={handleChangeInput}
                                />
                                <div className="submit-form">
                                    <Button
                                        disabled={currentData.isLoadingData || !valueNewItem.trim()}
                                        type="submit"
                                        className="custom-button mt-4 mb-5"
                                    >
                                        Add
                                    </Button>
                                </div>
                            </Form>
                        </Card>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SettingsTurnAroundTime;
