import React from 'react';

import { getPages, getPagesCount } from '../../common/helpers/TableHelper';

import { PaginationProps } from './Pagination';

export interface PaginationLinksProps {
    paginationProps: PaginationProps;
}

export interface OnPageChangeType {
    onPageChange?: (page: number, sizePerPage: number) => void;
}

export const PaginationLinks: React.FC<PaginationLinksProps> = (props) => {
    const { totalSize, sizePerPage, page, paginationSize } = props.paginationProps;
    const pagesCount = getPagesCount(totalSize, sizePerPage);
    const pages = page && paginationSize && getPages(page, pagesCount, paginationSize);

    const handleFirstPage = ({ onPageChange }: OnPageChangeType) => {
        onPageChange && sizePerPage && onPageChange(1, sizePerPage);
    };

    const handlePrevPage = ({ onPageChange }: OnPageChangeType, page?: number) => {
        page && onPageChange && sizePerPage && onPageChange(page - 1, sizePerPage);
    };

    const handleNextPage = ({ onPageChange }: OnPageChangeType, page?: number) => {
        if (page && page < pagesCount) {
            page && onPageChange && sizePerPage && onPageChange(page + 1, sizePerPage);
        }
    };

    const handleLastPage = ({ onPageChange }: OnPageChangeType) => {
        onPageChange && sizePerPage && onPageChange(pagesCount, sizePerPage);
    };

    const handleSelectedPage = ({ onPageChange }: OnPageChangeType, pageNum: number) => {
        if (pageNum !== page) onPageChange && sizePerPage && onPageChange(pageNum, sizePerPage);
    };

    const disabledClass = pagesCount > 1 ? '' : 'disabled';
    return (
        <>
            <div className={`d-flex flex-wrap py-2 ${disabledClass}`}>
                <button
                    onClick={() => handleFirstPage(props.paginationProps)}
                    className="btn btn-icon btn-light btn-hover-primary mr-1 my-1 border rounded-lg px-3 py-2"
                    disabled={page === 1}
                >
                    First
                </button>
                <button
                    onClick={() => handlePrevPage(props.paginationProps, page)}
                    className="btn btn-icon btn-light btn-hover-primary mr-1 my-1 border rounded-lg px-3 py-2"
                    disabled={page === 1}
                >
                    Previous
                </button>

                {page && page > 1 && (
                    <a className="btn btn-icon border-0 btn-hover-primary mr-1 my-1 border rounded-lg px-2 py-2">...</a>
                )}
                {pages &&
                    pages.map((p) => (
                        <a
                            key={p}
                            onClick={() => handleSelectedPage(props.paginationProps, p)}
                            className={`btn btn-icon border-0 btn-light border rounded-lg ${
                                page === p ? ' btn-hover-primary active' : ''
                            } mr-1 my-1 border rounded-lg px-3 py-2`}
                        >
                            {p}
                        </a>
                    ))}
                {page && page < pagesCount && (
                    <a className="btn btn-icon border-0 btn-hover-primary mr-1 my-1 border rounded-lg px-2 py-2">...</a>
                )}

                <button
                    onClick={() => handleNextPage(props.paginationProps, page)}
                    className="btn btn-icon btn-light btn-hover-primary mr-1 my-1 border rounded-lg px-3 py-2"
                    disabled={page === pagesCount}
                >
                    Next
                </button>
                <button
                    onClick={() => handleLastPage(props.paginationProps)}
                    className="btn btn-icon btn-light btn-hover-primary mr-1 my-1 border rounded-lg px-3 py-2"
                    disabled={page === pagesCount}
                >
                    Last
                </button>
            </div>
        </>
    );
};
