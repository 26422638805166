import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';

import InputForm, { InputType } from '../../../../../components/InputForm/InputForm';
import appServices from '../../../../../common/services/appServices';
import { notify } from '../../../../../common/utils/notify';
import { ActionSubject } from '../../../../../common/constants/enum';
import './ChangeReturnEmailDialogStyles.scss';

interface Props {
    isShowDialog: boolean;
    setShowDialog: (show: boolean) => void;
    setReloadPageType: (action: ActionSubject) => void;
    accountId: string;
}

export interface ReturnEmailUpdateFields {
    returnEmail: string;
    password: string;
}

const initialReturnEmailUpdate: ReturnEmailUpdateFields = {
    returnEmail: '',
    password: '',
};

export const ChangeReturnEmailDialog: React.FunctionComponent<Props> = ({
    isShowDialog,
    setShowDialog,
    setReloadPageType,
    accountId,
}) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const formUpdateReturnEmailSchema = Yup.object().shape({
        returnEmail: Yup.string()
            .trim()
            .required('This field is required')
            .email('Please enter a valid email address')
            .max(70, 'Email length must be less than or equal to 70 characters long'),
        password: Yup.string().trim().required('This field is required'),
    });

    const handleSubmitForm = async (values: ReturnEmailUpdateFields) => {
        try {
            setLoading(true);
            values.returnEmail = values.returnEmail.trim();
            const response = await appServices.updateReturnEmail(values, accountId);
            if (response.success) {
                setReloadPageType(ActionSubject.UPDATE);
                setShowDialog(false);
                notify.success('Update return email successfully.', 'Success');
            } else {
                setErrorMessage(response.message);
            }
        } catch (error: any) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-center"
            centered
            show={isShowDialog}
            onHide={() => setShowDialog(false)}
        >
            <Modal.Header closeButton className="model-add">
                <Modal.Title id="contained-modal-title-center">Change Return Email Address</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialReturnEmailUpdate}
                validateOnChange
                validationSchema={formUpdateReturnEmailSchema}
                enableReinitialize
                onSubmit={(values) => {
                    if (isLoading) {
                        return;
                    }
                    handleSubmitForm(values);
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form
                        className="my-2"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    >
                        <Modal.Body>
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            <InputForm
                                controlId="returnEmail"
                                label="New Return Email Address"
                                handleChange={handleChange}
                                name="returnEmail"
                                value={values.returnEmail}
                                labelStyle="label-add-assignee mb-1"
                                error={errors.returnEmail}
                                touched={touched.returnEmail}
                                setErrorMessageForm={setErrorMessage}
                                isRequired
                            />
                            <InputForm
                                controlId="password"
                                label="Enter Password to Confirm"
                                handleChange={handleChange}
                                name="password"
                                value={values.password}
                                labelStyle="label-add-assignee mb-1"
                                error={errors.password}
                                touched={touched.password}
                                setErrorMessageForm={setErrorMessage}
                                isRequired
                                type={InputType.Password}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-primary cancel-button"
                                onClick={() => setShowDialog(false)}
                                className="px-4 py-1"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="px-4 py-1 save-button"
                                onClick={() => handleSubmit()}
                                disabled={isLoading}
                            >
                                Save
                                {isLoading && (
                                    <span className="ml-2">
                                        <Spinner animation="border" size="sm" variant="light" />
                                    </span>
                                )}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
