import React from 'react';
import { Form } from 'react-bootstrap';

import './DropdownFormStyles.scss';

export interface OptionSelectType {
    text: string;
    value: string | number;
}

interface InputFormProps {
    controlId: string;
    label: string;
    value: string | number | undefined;
    name?: string;
    handleChange: (e: any) => void;
    formGroupStyle?: string;
    labelStyle?: string;
    setErrorMessageForm?: (message: string) => void;
    error?: string;
    placeholder?: string;
    isRequired?: boolean;
    listOption: OptionSelectType[];
    defaultText: string;
}

const DropdownForm: React.FunctionComponent<InputFormProps> = ({
    controlId,
    label,
    value,
    name,
    handleChange,
    setErrorMessageForm,
    formGroupStyle,
    labelStyle,
    error,
    isRequired,
    listOption,
    defaultText,
}): React.ReactElement => {
    return (
        <Form.Group className={formGroupStyle} controlId={controlId}>
            <Form.Label className={labelStyle}>
                {label}
                {isRequired && <span className="text-danger">&nbsp;*</span>}
            </Form.Label>
            <Form.Control
                as="select"
                name={name}
                value={value}
                onChange={(e) => {
                    setErrorMessageForm && setErrorMessageForm('');
                    handleChange(e);
                }}
                className={`form-select-height ${error ? 'error' : ''}`}
            >
                <option>{defaultText}</option>
                {listOption.map((item, index) => (
                    <option key={index} value={item.value}>
                        {item.text}
                    </option>
                ))}
            </Form.Control>
            {error && <Form.Text className="text-danger">{error}</Form.Text>}
        </Form.Group>
    );
};

export default DropdownForm;
