import React from 'react';

import filesIcon from '../../../../common/assets/images/file-icon.png';
import { Row, SelectActions } from '../../../../components/Table/components/DropdownSelectCell/DropdownSelectCell';

import FileIcon from './FileIcon';
import './FilesListStyles.scss';

const fileIcon = (cell: any, row: Row, _rowIndex: number, actions: SelectActions) => {
    return <FileIcon row={row} />;
};
const fileDataName = (cell: any, row: Row, _rowIndex: number, actions: SelectActions) => {
    return (
        <div className="fileDataName">
            <img src={filesIcon} className="iconDownloaded" alt="" />
            <span>{row.fileName}</span>
        </div>
    );
};

export const renderColumns = (handleClick: any) => [
    {
        dataField: 'fileName',
        text: 'Files',
        sort: true,
        formatter: fileDataName,
        headerSortingClasses: 'sortable-active',
        headerStyle: () => {
            return { width: '150px' };
        },
    },
    {
        dataField: 'createdAt',
        text: 'Date of Upload',
        sort: true,
        headerSortingClasses: 'sortable-active',
        headerStyle: () => {
            return { width: '150px' };
        },
    },
    {
        dataField: 'updatedAt',
        text: 'Date Done',
        sort: true,
        headerSortingClasses: 'sortable-active',
        headerStyle: () => {
            return { width: '150px' };
        },
    },

    {
        dataField: '',
        text: '',
        sort: true,
        formatter: fileIcon,
        headerSortingClasses: 'sortable-active',
        classes: 'text-center icon-table',
        headerClasses: 'text-center',
        headerStyle: () => {
            return { width: '150px' };
        },
    },
];
