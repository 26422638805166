import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import headerMenu from '../../common/assets/images/headerMenu.png';
import avatarPlaceholder from '../../common/assets/images/profile.png';
import appConstant from '../../common/constants/app';
import { UserRole } from '../../common/constants/enum';
import { doctorRoute } from '../../common/constants/routes';
import userServices from '../../common/services/userServices';
import { baseURL } from '../../common/utils/axiosClient';
import { notify } from '../../common/utils/notify';
import { storage, storageKey } from '../../common/utils/storage';
import { GlobalState } from '../../redux';
import { getUserInfo } from '../../redux/user/actions';
import ConfirmModal from '../ConfirmModal/ConfirmModal';

import MenuSidebarDropdownItem from './components/MenuSidebarDropdownItem/MenuSidebarDropdownItem';
import MenuSidebarItem from './components/MenuSidebarItem/MenuSidebarItem';
import { validatePhoto } from './components/validatePhotoHelper';

import './MenuSidebarStyles.scss';

export interface MenuSidebarItemType {
    icon: any;
    iconActive: any;
    text: string;
    path?: string;
    dropdown: boolean;
    nestedOptions: {
        icon?: any;
        iconActive?: any;
        path: string;
        text: string;
    }[];
}

export interface MenuSidebarProps {
    isShowMenu: boolean;
    setIsShowMenu: (isShowMenu: boolean) => void;
}

const MenuSidebar: React.FunctionComponent<MenuSidebarProps> = ({ isShowMenu, setIsShowMenu }): React.ReactElement => {
    const location = useLocation();
    const { userInfo, isLoadingGetUserInfo } = useSelector((state: GlobalState) => state.user);
    const [newAvatar, setNewAvatar] = useState<File | undefined>(undefined);
    const avatarUrl = userInfo.avatar ? `${baseURL}/user/avatar/${userInfo.avatar}` : avatarPlaceholder;
    const [avatarPreview, setAvatarPreview] = useState(avatarUrl);
    const [openModalAvatar, setOpenModalAvatar] = useState(false);
    const [currentRoute, setCurrentRoute] = useState<string>(location.pathname);
    const dispatch = useDispatch();
    const [errorValidateImage, setErrorValidateImage] = useState('');
    const doctorRouteValues = Object.values(doctorRoute);
    const isDoctorAuth = !!doctorRouteValues.find((route) => route === location.pathname);
    const isUserRoleStaff =
        userInfo.userRole === UserRole.Editor ||
        userInfo.userRole === UserRole.Admin ||
        userInfo.userRole === UserRole.Assignee;
    useEffect(() => {
        setCurrentRoute(location.pathname);
    }, [location.pathname]);

    const handleChangeAvatar = (files: FileList | null) => {
        setErrorValidateImage('');
        if (files && files.length) {
            const error = validatePhoto(files[0]);
            if (error) {
                setErrorValidateImage(error);
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.addEventListener('load', (e: any) => {
                e.preventDefault();
                setAvatarPreview(e.target && e.target.result);
                setNewAvatar(files[0]);
            });
        }
    };

    const uploadAvatar = async () => {
        try {
            await userServices.uploadAvatar(newAvatar as File);
            dispatch(getUserInfo());
            notify.success('Change Avatar', 'Success');
            setOpenModalAvatar(false);
        } catch (error) {
            notify.error('Failure, please try again', 'Failure');
        }
    };

    const resetPreviewAvatar = () => {
        setOpenModalAvatar(false);
        setErrorValidateImage('');
        setAvatarPreview(avatarUrl);
    };

    const optionsSideBar = storage.getToken(storageKey.TOKEN)?.length
        ? appConstant.optionsSideBar
        : appConstant.optionsDoctorSideBar;

    const role = storage.getToken(storageKey.TOKEN)?.length ? 'editor' : 'doctor';

    return (
        <div className={`menu menu-${role}`}>
            <div className="menu-icon ml-4" onClick={() => setIsShowMenu(!isShowMenu)}>
                <img srcSet={headerMenu} alt="" />
            </div>
            <ConfirmModal
                className="change-avatar-modal"
                isOpen={openModalAvatar}
                setIsOpen={setOpenModalAvatar}
                actionConfirm={uploadAvatar}
                actionCancel={resetPreviewAvatar}
                titleModal="Change profile Picture"
                textConfirm="Save"
                confirmButtonClassName="save-button"
            >
                <div className="new-avatar">
                    <img src={avatarPreview} alt="" />
                </div>
                <div className="input-avatar">
                    <label htmlFor="input-avatar">Browse Files</label>
                    <input
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        id="input-avatar"
                        onChange={(e) => handleChangeAvatar(e.target.files)}
                    />
                    {errorValidateImage ? (
                        <div className="error-validate">
                            <Alert variant="danger">{errorValidateImage}</Alert>
                        </div>
                    ) : null}
                </div>
            </ConfirmModal>

            <div className="info-user  mt-4 mb-4">
                {!isLoadingGetUserInfo && (
                    <div className="info-user__name ">
                        <div className="avatar-user" onClick={() => setOpenModalAvatar(true)}>
                            <Tooltip title="Click to change avatar" placement="top" arrow>
                                <img src={avatarUrl} alt="" />
                            </Tooltip>
                        </div>
                        <p className="mt-2 text-primary info-user__name-name">
                            {isUserRoleStaff ? `${userInfo.firstName} ${userInfo.lastName}` : `${userInfo.name}`}
                        </p>
                    </div>
                )}
            </div>

            <div className="options-sidebar">
                {optionsSideBar.map((option: any, index: number) => {
                    const MenuItem = option.dropdown ? MenuSidebarDropdownItem : MenuSidebarItem;
                    return (
                        <MenuItem
                            option={option}
                            currentRoute={currentRoute}
                            setCurrentRoute={setCurrentRoute}
                            key={index}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default MenuSidebar;
