import React from 'react';
import { useHistory } from 'react-router-dom';

import { DictationTableConfig } from '../../../../pages/editor-pages/dictations-in-progress/DictationsInProgress';

export interface TableConfigActions {
    tableConfig: DictationTableConfig;
}
interface ActionsCustomLinkCellProps {
    link: string;
    actions?: TableConfigActions;
}

export const CustomLinkCell: React.FunctionComponent<ActionsCustomLinkCellProps> = ({
    link,
    actions,
}): React.ReactElement => {
    const history = useHistory();
    const redirect = () => {
        history.push(link, { tableConfigRemember: actions?.tableConfig });
    };
    return (
        <>
            <a title="See details" className="btn btn-icon py-0 btn-sm btn-play p-0" onClick={redirect}>
                <span className="svg-icon svg-icon-md svg-icon-primary">
                    <i className="fa fa-play" />
                </span>
            </a>
        </>
    );
};
