import React from 'react';

import { route } from '../../../common/constants/routes';
import { sortCaret } from '../../../common/helpers/TableHelper';
import { CustomLinkCell, TableConfigActions } from '../../../components/Table/components/CustomLinkCell/CustomLinkCell';
import { DropdownSelectCell, Row } from '../../../components/Table/components/DropdownSelectCell/DropdownSelectCell';
import constant from '../../../common/constants/app';
import { DictationStatus } from '../../../common/constants/enum';
import { DictationTableConfig } from '../dictations-in-progress/DictationsInProgress';

const formatter = (_cell: string, row: Row, _rowIndex: number, actions: TableConfigActions): JSX.Element => (
    <CustomLinkCell link={`${route.HISTORICAL_RECORD}/${row.id}`} actions={actions} />
);

const statusFormatter = (cell: string): JSX.Element => (
    <DropdownSelectCell
        value={cell}
        options={constant.dictationStatus}
        disabled={true}
        statusClassName={cell == DictationStatus.Finished ? 'finished-select' : 'cancel-select'}
    />
);
export const renderColumns = (tableConfig: DictationTableConfig) => [
    {
        dataField: 'id',
        text: 'Unique Code',
        sort: true,
        sortCaret: sortCaret,
        classes: 'border-right',
        headerStyle: () => {
            return { textAlign: 'center' };
        },
        style: {
            textAlign: 'center',
        },
    },
    {
        dataField: 'createdAt',
        text: 'Date of Upload',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
        classes: 'border-right',
    },
    {
        dataField: 'userName',
        text: 'Username',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
        classes: 'border-right',
    },
    {
        dataField: 'turnAroundTime.value',
        text: 'Turn Around Time',
        classes: 'border-right',
    },
    {
        dataField: 'status',
        text: 'Status',
        classes: 'border-right icon-table',
        formatter: statusFormatter,
        headerStyle: () => {
            return { textAlign: 'center' };
        },
    },
    {
        dataField: '',
        text: '',
        formatter: formatter,
        formatExtraData: {
            tableConfig,
        },
        classes: 'text-center icon-table',
        headerClasses: 'text-center',
        style: {
            minWidth: '140px',
        },
        headerStyle: () => {
            return { width: '7%' };
        },
    },
];
