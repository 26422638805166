import React from 'react';

import API from '../../../../../common/constants/api';
import { formatFileName } from '../../../../../common/helpers/ValidateFileHelper';
import { FileDownLoadDTO } from '../../../../../common/services/apiTypes';
import { sliceFileName } from '../../../../../common/utils/converter';
import './ActionsColumnFormatterStyles.scss';

export type HandlerAction = () => void;

interface ActionsColumnFormatterProps {
    row: FileDownLoadDTO;
}

export const ActionsColumnFormatter: React.FunctionComponent<ActionsColumnFormatterProps> = ({
    row,
}): React.ReactElement => {
    const fileName = formatFileName(row.filePath);
    return (
        <div className="file-template-box">
            <a
                href={`${process.env.REACT_APP_API_ENDPOINT}${API.TEMPLATES.DOWNLOAD_FILE_TEMPLATE}${row.id}`}
                download={`${fileName}`}
                className="file-template"
            >
                {sliceFileName(row.filePath.length > 50 ? `${row.filePath.substring(0, 50)}...` : row.filePath)}
            </a>
        </div>
    );
};
