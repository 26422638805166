import axios from 'axios';
import fileSaver from 'file-saver';
import React, { useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import { Row } from '../../../../components/Table/components/DropdownSelectCell/DropdownSelectCell';
import downloadIcon from '../../../../common/assets/images/download.png';
import downloadedIcon from '../../../../common/assets/images/downloaded.png';
import { baseURL } from '../../../../common/utils/axiosClient';
import appServices from '../../../../common/services/appServices';

const FileIcon = ({ row }: { row: Row }) => {
    const [percentDownload, setPercentDownload] = useState(0);
    const [data, setData] = useState({ ...row });

    const handleDownLoad = async (id: number, fileName: string) => {
        try {
            axios({
                url: `${baseURL}/dictation/transcription/get-file/${id}`,
                method: 'GET',
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setPercentDownload(percentCompleted);
                },
            }).then(async (res) => {
                fileSaver.saveAs(res.data, fileName);
                setData({ ...data, isDownloaded: true });
                await appServices.updateDictationStatusDownload(id);
            });
        } catch (error: any) {
            console.log(error);
        }
    };
    return (
        <div className="item-download">
            <div
                className={`txtProgress txtProgress--${
                    percentDownload < 100 && percentDownload > 0 ? 'downloading' : 'downloaded'
                } `}
            >
                {percentDownload < 100 && percentDownload > 0
                    ? `Downloading - ${percentDownload}%`
                    : percentDownload === 100 || data.isDownloaded
                    ? 'Downloaded'
                    : ''}
            </div>
            <div
                className="py-1 pl-2 flex-1"
                onClick={(e) => {
                    e.stopPropagation();
                    handleDownLoad(data.id, data.fileName);
                }}
            >
                {!data.isDownloaded ? (
                    percentDownload > 0 ? (
                        <div style={{ width: 25, height: 25 }}>
                            <CircularProgressbar
                                value={percentDownload}
                                strokeWidth={50}
                                styles={buildStyles({
                                    strokeLinecap: 'butt',
                                    pathColor: '#FF9900',
                                })}
                            />
                        </div>
                    ) : (
                        <img src={downloadIcon} alt="download" />
                    )
                ) : percentDownload < 100 && percentDownload > 0 ? (
                    <div style={{ width: 25, height: 25 }}>
                        <CircularProgressbar
                            value={percentDownload}
                            strokeWidth={50}
                            styles={buildStyles({
                                strokeLinecap: 'butt',
                                pathColor: '#FF9900',
                            })}
                        />
                    </div>
                ) : (
                    <img src={downloadedIcon} alt="downloaded" />
                )}
            </div>
        </div>
    );
};

export default FileIcon;
