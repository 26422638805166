import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';

import { AssigneeDTO, AssigneeResponse } from '../../../../../common/services/apiTypes';
import ConfirmModal from '../../../../../components/ConfirmModal/ConfirmModal';
import Table, { TableConfig } from '../../../../../components/Table/Table';
import TableToolbar from '../../../../../components/Table/components/TableToolbar/TableToolbar';
import { notify } from '../../../../../common/utils/notify';
import { initialTableConfig, paginationOptions } from '../../DictationsDetailHelper';
import { GlobalState } from '../../../../../redux';

import { assigneeTableActions, assigneeRenderColumns, formAddAssigneeSchema } from './AssigneeTableContainerHelper';

import './AssigneeTableContainerStyles.scss';

interface AssigneeTableContainerProps {
    isEditable: boolean;
    dictationId: string;
    isSubmittedPage: boolean;
    isHistoricalPage: boolean;
    setIsExistAssignee?: (isExistAssignee: boolean) => void;
}

const AssigneeTableContainer: React.FC<AssigneeTableContainerProps> = ({
    isEditable,
    dictationId,
    isSubmittedPage,
    isHistoricalPage,
    setIsExistAssignee,
}): React.ReactElement => {
    const userInfo = useSelector((state: GlobalState) => state.user.userInfo);
    const [listAssignee, setListAssignee] = useState<AssigneeResponse[]>([]);
    const [selectedAssignee, setSelectedAssignee] = useState(0);
    const [isOpenAddAssigneeModal, setIsOpenAddAssigneeModal] = useState(false);
    const [errorMessageAddAssignee, setErrorMessageAddAssignee] = useState<string | undefined>('');
    const [listDropdownAssignee, setListDropdownAssignee] = useState<AssigneeDTO[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getListAssignee(initialTableConfig);
        setSelectedAssignee(0);
    }, [isEditable]);

    useEffect(() => {
        setIsExistAssignee &&
            setIsExistAssignee(listAssignee.findIndex((item) => item.assignee.userId === userInfo.id) !== -1);
    }, [listAssignee]);

    const getListAssignee = async (tableConfig: TableConfig) => {
        await assigneeTableActions.getListAssignee(tableConfig, setListAssignee, dictationId);
    };

    const handleAddAssigneeAction = async (
        values: { assigneeId: number; from: number | null; to: number | null },
        resetForm: () => void,
    ) => {
        if (!isLoading) {
            setIsLoading(true);
            const { assigneeId, from, to } = values;
            const response = await assigneeTableActions.addAssignee(dictationId, assigneeId, from, to);
            if (response?.success) {
                setIsOpenAddAssigneeModal(false);
                await getListAssignee(initialTableConfig);
                notify.success('Dictation successfully assigned.', 'Success');
                resetForm();
            } else {
                setErrorMessageAddAssignee(response?.message);
            }
            setIsLoading(false);
        }
    };

    const handleDeleteAssigneeAction = async () => {
        if (!isLoading) {
            setIsLoading(true);
            const response = await assigneeTableActions.deleteAssignee(selectedAssignee);
            setSelectedAssignee(0);
            await getListAssignee(initialTableConfig);
            if (response?.success) {
                notify.success('Assignment successfully deleted.', 'Success');
            } else {
                notify.error(response?.message, 'Error');
            }
            setIsLoading(false);
        }
    };

    const getHandleTableAssigneeChange = (type: string, newState: any) => {
        const dotIdx = newState.sortField.indexOf('.') + 1;
        getListAssignee({
            ...initialTableConfig,
            sortBy: newState.sortField.slice(dotIdx, newState.sortField.length),
            sortDirection: newState.sortOrder,
        });
    };

    const openAddAssigneeModal = async () => {
        setIsOpenAddAssigneeModal(true);
        await assigneeTableActions.getDropdownAssignee(setListDropdownAssignee, listAssignee);
    };

    const cancelModal = () => {
        setIsOpenAddAssigneeModal(false);
        setErrorMessageAddAssignee('');
        setSelectedAssignee(0);
    };

    return (
        <>
            <Table
                title="Assignee"
                isHideSearchBar
                isHidePagination
                tableClassName="mb-4"
                tableConfig={initialTableConfig}
                paginationOptions={paginationOptions}
                data={listAssignee}
                isDataLoading={false}
                getData={getListAssignee}
                columns={assigneeRenderColumns(setSelectedAssignee, isEditable, isHistoricalPage)}
                getHandleTableChange={getHandleTableAssigneeChange}
                tableToolbar={
                    !(isHistoricalPage || isSubmittedPage) && isEditable ? (
                        <TableToolbar textAddNewButton="+ Add assignee" handleAction={openAddAssigneeModal} />
                    ) : null
                }
            />

            <Formik
                initialValues={{ assignee: '', from: '', to: '' }}
                validateOnChange
                validationSchema={formAddAssigneeSchema}
                enableReinitialize
                onSubmit={(values, actions) => {
                    const assigneeId = +values.assignee;
                    const from = +values.from.slice(0, 3) * 60 + +values.from.slice(-2);
                    const to = +values.to.slice(0, 3) * 60 + +values.to.slice(-2);
                    if (+values.from.slice(-2) > 60) {
                        actions.setFieldError('from', 'Seconds must be less than 60.');
                        return;
                    }
                    if (+values.to.slice(-2) > 60) {
                        actions.setFieldError('to', 'Seconds must be less than 60.');
                        return;
                    }
                    if ((from || to) && from >= to) {
                        actions.setFieldError('from', '"From" value must be less than "To" value');
                        return;
                    }
                    if (from === 0 && to === 0) {
                        handleAddAssigneeAction({ assigneeId, from: null, to: null }, actions.resetForm);
                    } else {
                        handleAddAssigneeAction({ assigneeId, from, to }, actions.resetForm);
                    }
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors, resetForm }) => (
                    <Form
                        className="my-2"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    >
                        <ConfirmModal
                            isOpen={isOpenAddAssigneeModal}
                            setIsOpen={setIsOpenAddAssigneeModal}
                            actionConfirm={handleSubmit}
                            actionCancel={() => {
                                cancelModal();
                                resetForm();
                            }}
                            titleModal="Add an Assignee"
                            textConfirm="Add"
                            className="add-assignee-modal"
                            confirmButtonClassName="add-button"
                        >
                            {errorMessageAddAssignee && <Alert variant="danger">{errorMessageAddAssignee}</Alert>}
                            <Form.Group>
                                <Form.Label className="label--required">Assignee</Form.Label>
                                <Form.Control
                                    className="input-select"
                                    as="select"
                                    onChange={(e) => {
                                        setErrorMessageAddAssignee('');
                                        handleChange(e);
                                    }}
                                    name="assignee"
                                >
                                    <option>Select an assignee</option>
                                    {listDropdownAssignee.length &&
                                        listDropdownAssignee.map((assignee, index) => (
                                            <option
                                                value={assignee.id}
                                                key={index}
                                            >{`${assignee.firstName} ${assignee.lastName}`}</option>
                                        ))}
                                </Form.Control>
                                {errors.assignee && touched.assignee ? (
                                    <Form.Text className="text-danger">{errors.assignee}</Form.Text>
                                ) : null}
                            </Form.Group>
                            <div className="time-block">
                                <Form.Group>
                                    <Form.Label>From</Form.Label>
                                    <InputMask
                                        className="input-mask"
                                        mask="999:99"
                                        placeholder="mmm:ss"
                                        name="from"
                                        onChange={(e) => {
                                            setErrorMessageAddAssignee('');
                                            handleChange(e);
                                        }}
                                        value={values.from}
                                    />
                                    {errors.from && touched.from ? (
                                        <Form.Text className="text-danger">{errors.from}</Form.Text>
                                    ) : null}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>To</Form.Label>
                                    <InputMask
                                        className="input-mask"
                                        mask="999:99"
                                        placeholder="mmm:ss"
                                        name="to"
                                        onChange={(e) => {
                                            setErrorMessageAddAssignee('');
                                            handleChange(e);
                                        }}
                                        value={values.to}
                                    />
                                    {errors.to && touched.to ? (
                                        <Form.Text className="text-danger">{errors.to}</Form.Text>
                                    ) : null}
                                </Form.Group>
                            </div>
                        </ConfirmModal>
                    </Form>
                )}
            </Formik>
            <ConfirmModal
                isOpen={isEditable && selectedAssignee}
                setIsOpen={setSelectedAssignee}
                actionConfirm={handleDeleteAssigneeAction}
                actionCancel={cancelModal}
                titleModal="Delete Assignee"
                textConfirm="Delete Now"
                className="turn-around-time-confirm-modal"
                confirmButtonClassName="delete-button"
            >
                <p>Are you sure to delete this Assignee?</p>
            </ConfirmModal>
        </>
    );
};

export default AssigneeTableContainer;
