import React, { useState } from 'react';

import {
    DropdownSelectCell,
    Row,
    SelectActions,
} from '../../../../components/Table/components/DropdownSelectCell/DropdownSelectCell';
import {
    ClickActions,
    CustomOpenModalCell,
} from '../../../../components/Table/components/CustomOpenModalCell/CustomOpenModalCell';
import constant from '../../../../common/constants/app';
import { DictationStatus } from '../../../../common/constants/enum';
import arrowRight from '../../../../common/assets/images/PolygonRight.png';
import arrowDown from '../../../../common/assets/images/PolygonDown.png';
import FolderIcon from '../FolderIconHelper';

import './FinishedTranscriptionsStyles.scss';

const formatter = (cell: any, row: Row, rowIndex: number, actions: ClickActions): JSX.Element => (
    <CustomOpenModalCell actions={actions} row={row} />
);

const statusFormatter = (cell: any, row: Row, _rowIndex: number, actions: SelectActions): JSX.Element => (
    <DropdownSelectCell
        value={cell}
        options={constant.dictationStatus.filter((item) => item.id === 'Finished' || item.id === 'Submitted')}
        statusClassName={cell == DictationStatus.Finished ? 'finished-select' : 'submitted-select'}
        actions={actions}
        disabled={true}
        row={row}
    />
);

const fileIcon = (cell: any, row: Row, _rowIndex: number, actions: SelectActions) => {
    return <FolderIcon id={row.id} />;
};

const arrowFnc = (cell: any, row: any, rowIndex: number, actions: SelectActions) => {
    return row?.opened ? <img src={arrowDown} alt="" /> : <img src={arrowRight} alt="" />;
};

export const renderColumns = (handleClick: any) => [
    {
        dataField: 'id',
        text: 'ID',
        sort: true,
        headerStyle: () => {
            return { textAlign: 'center', width: '100px' };
        },
        style: {
            textAlign: 'center',
        },
    },
    {
        dataField: 'createdAt',
        text: 'Date of Upload',
        sort: true,
        headerSortingClasses: 'sortable-active',
        headerStyle: () => {
            return { width: '150px' };
        },
    },
    {
        dataField: 'editorIncharge',
        text: 'Editor-In-Charge',
        sort: true,
        headerSortingClasses: 'sortable-active',
        headerStyle: () => {
            return { width: '170px' };
        },
    },
    {
        dataField: 'turnAroundTime.value',
        text: 'Turn Around Time',
        headerSortingClasses: 'sortable-active',
        headerStyle: () => {
            return { width: '170px' };
        },
    },
    {
        dataField: 'status',
        text: 'Status',
        classes: 'icon-table',
        headerSortingClasses: 'sortable-active',
        formatter: statusFormatter,
        headerStyle: () => {
            return { textAlign: 'start', width: '150px' };
        },
    },
    {
        dataField: 'updatedAt',
        text: 'Date Done',
        sort: true,
        headerSortingClasses: 'sortable-active',
        headerStyle: () => {
            return { width: '150px' };
        },
    },
    {
        dataField: '',
        text: 'File(s)',
        sort: true,
        formatter: fileIcon,
        headerSortingClasses: 'sortable-active',
        headerStyle: () => {
            return { width: '150px' };
        },
    },
    {
        dataField: '',
        text: '',
        classes: 'text-center icon-table',
        headerClasses: 'text-center',
        style: {
            minWidth: '30px',
        },
        formatter: arrowFnc,
        formatExtraData: {
            handleClick: handleClick,
        },
        headerStyle: () => {
            return { width: '5%' };
        },
    },
];
