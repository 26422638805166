import React from 'react';

import { sortCaret } from '../../../common/helpers/TableHelper';
import {
    DropdownSelectCell,
    Row,
    SelectActions,
} from '../../../components/Table/components/DropdownSelectCell/DropdownSelectCell';
import { CustomLinkCell, TableConfigActions } from '../../../components/Table/components/CustomLinkCell/CustomLinkCell';
import constant from '../../../common/constants/app';
import { DictationTableConfig } from '../dictations-in-progress/DictationsInProgress';
import { AddAccountActions, AddAccountCell } from '../../../components/Table/components/AddAccountCell/AddAccountCell';
import { DictationResponse } from '../../../common/services/apiTypes';
import { UserRole } from '../../../common/constants/enum';
import { UserInfo } from '../../../redux/user/actionTypes';

const formatter = (_cell: string, row: Row, _rowIndex: number, actions: TableConfigActions): JSX.Element => (
    <CustomLinkCell link={`/editor/home/dictations-unassigned/${row.id}`} actions={actions} />
);
const statusFormatter = (cell: string, row: Row, _rowIndex: number, actions: SelectActions): JSX.Element => (
    <DropdownSelectCell
        value={cell}
        options={constant.dictationStatus.filter((item) => item.id !== 'Submitted' && item.id !== 'Finished')}
        statusClassName="unassigned-select"
        actions={actions}
        row={row}
    />
);

const addAccountFormatter = (
    _cell: string,
    row: DictationResponse,
    _rowIndex: number,
    actions: AddAccountActions,
): JSX.Element => <AddAccountCell actions={actions} row={row} />;

export const renderColumns = (
    handleSelected: (value: string, id: number) => void,
    handleAddAccount: (id: number) => void,
    tableConfig: DictationTableConfig,
    isLoading: boolean,
    idSelect: number,
    userInfo: UserInfo,
) => [
    {
        dataField: 'id',
        text: 'Unique Code',
        sort: true,
        sortCaret: sortCaret,
        headerStyle: () => {
            return { textAlign: 'center' };
        },
        style: {
            textAlign: 'center',
        },
    },
    {
        dataField: 'createdAt',
        text: 'Date of Upload',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'userName',
        text: 'Username',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'turnAroundTime.value',
        text: 'Turn Around Time',
    },
    {
        dataField: 'addAccount',
        text: 'Editor In-Charge',
        classes: 'text-center border-right icon-table',
        formatter: addAccountFormatter,
        formatExtraData: {
            handleAddAccount: handleAddAccount,
            isLoading: isLoading,
            idSelect: idSelect,
        },
        headerStyle: () => {
            return { textAlign: 'center' };
        },
        hidden: userInfo.userRole !== UserRole.Admin && userInfo.userRole !== UserRole.Editor,
    },
    {
        dataField: 'status',
        text: 'Status',
        classes: 'border-right icon-table',
        formatter: statusFormatter,
        formatExtraData: {
            handleSelected: handleSelected,
        },
        headerStyle: () => {
            return { textAlign: 'center' };
        },
    },
    {
        dataField: '',
        text: '',
        formatter: formatter,
        formatExtraData: {
            tableConfig,
        },
        classes: 'text-center icon-table',
        headerClasses: 'text-center',
        style: {
            minWidth: '140px',
        },
        headerStyle: () => {
            return { width: '7%' };
        },
    },
];
