export const LOGIN = 'LOGIN';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const RESET = 'RESET';

export enum LoginStatus {
    success = 'SUCCESS',
    invalidAccount = 'INVALID_ACCOUNT',
    inactiveAccount = 'INACTIVE_ACCOUNT',
    failure = 'FAILURE',
}

interface LoginParams {
    email: string;
    password: string;
}

interface LoginResponse {
    token: string;
    status: LoginStatus;
    invitationCode?: string;
    name?: string;
}

interface LoginFailureResponse {
    loginMessage: string;
    status?: LoginStatus;
}

interface VerificationResetPasswordResponse {
    code: string;
}

interface VerifyRegistrationResponse {
    isValidCode: boolean;
}

interface VerificationLoginResponse {
    token: string;
    invitationCode: string;
    isActivated: boolean;
    firstName: string;
    lastName: string;
    name: string;
}

interface LoginAction {
    type: typeof LOGIN;
    payload: LoginParams;
}

interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS;
    payload: LoginResponse;
}

interface LoginFailureAction {
    type: typeof LOGIN_FAILURE;
    payload: LoginFailureResponse;
}

interface ResetAction {
    type: typeof RESET;
}

type LoginType = LoginAction | LoginSuccessAction | LoginFailureAction | ResetAction;

export type {
    LoginType,
    LoginAction,
    LoginSuccessAction,
    LoginFailureAction,
    ResetAction,
    LoginResponse,
    LoginFailureResponse,
    LoginParams,
    VerificationResetPasswordResponse,
    VerificationLoginResponse,
    VerifyRegistrationResponse,
};
