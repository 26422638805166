import React, { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import './ResetPasswordStyles.scss';
import { route } from '../../../common/constants/routes';
import authServices from '../../../common/services/authServices';
import PasswordRules from '../../../components/PasswordRules/PasswordRules';
import AuthForm from '../../../components/AuthForm/AuthForm';
import InputForm, { InputType } from '../../../components/InputForm/InputForm';
import { VerificationPage } from '../../../common/constants/enum';
import appConstants from '../../../common/constants/app';
import ButtonForm from '../../../components/ButtonForm/ButtonForm';

import ResetPasswordSuccess from './components/ResetPasswordSuccess';
export interface ResetPasswordFields {
    newPassword: string;
    confirmationPassword: string;
}

interface ResetPasswordProps {
    isDoctorAuth: boolean;
}

const ResetPassword: React.FunctionComponent<ResetPasswordProps> = ({ isDoctorAuth }): React.ReactElement => {
    const history = useHistory<{ email: string; code: string; page: VerificationPage }>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);

    const resetPasswordSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required('This field is required')
            .matches(appConstants.passwordHelper.passwordRuleRegex, 'Password does not follow the format rules'),
        confirmationPassword: Yup.string()
            .required('This field is required')
            .when('newPassword', {
                is: (newPassword: string) => newPassword !== undefined,
                then: Yup.string().oneOf([Yup.ref('newPassword')], 'Password does not match'),
            }),
    });
    const resetPasscodeSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required('This field is required')
            .matches(/^[0-9]{4}$/, 'Must Contain 4 Numbers'),
        confirmationPassword: Yup.string()
            .required('This field is required')
            .when('newPassword', {
                is: (newPassword: string) => newPassword !== undefined,
                then: Yup.string().oneOf([Yup.ref('newPassword')], 'Passcode does not match'),
            }),
    });

    useEffect(() => {
        if (!history.location.state?.email || !history.location.state?.page || !history.location.state?.code)
            history.push(route.LOGIN);
    }, []);

    const handleSubmitForm = async (values: ResetPasswordFields) => {
        try {
            setIsLoading(true);
            const response = await authServices.resetPassword({
                ...values,
                email: history.location.state.email,
                code: history.location.state.code,
            });
            if (response.success) {
                setIsChanged(true);
            } else {
                setErrorMessage(response.message);
            }
        } catch (error) {
            setErrorMessage('Failure, please try again');
        } finally {
            setIsLoading(false);
            return;
        }
    };
    const currentPage = isDoctorAuth ? 'Passcode' : 'Password';

    return (
        <AuthForm responsiveClass={'reset-password-responsive'} isShowRedirect={true}>
            {!isChanged ? (
                <>
                    <p className="text-primary mt-5 mb-3 paragraph-main">Reset {currentPage}</p>
                    <p className="paragraph-extra text-color-primary mb-4">Set your New {currentPage}.</p>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Formik
                        initialValues={{
                            newPassword: '',
                            confirmationPassword: '',
                        }}
                        validateOnChange
                        validationSchema={currentPage === 'Password' ? resetPasswordSchema : resetPasscodeSchema}
                        enableReinitialize
                        onSubmit={handleSubmitForm}
                    >
                        {({ values, handleSubmit, errors, touched, handleChange }) => (
                            <Form
                                className="mt-2"
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit();
                                    }
                                }}
                            >
                                <InputForm
                                    controlId="formPassword"
                                    label={`Enter new ${currentPage}`}
                                    type={InputType.Password}
                                    name="newPassword"
                                    handleChange={handleChange}
                                    setErrorMessageForm={setErrorMessage}
                                    labelStyle="text-color-primary mb-1 paragraph-medium"
                                    formGroupStyle={`${currentPage == 'Password' && 'pb-3 border-bottom'}`}
                                    value={values.newPassword}
                                    error={(touched.newPassword && errors.newPassword) || ''}
                                />
                                {currentPage == 'Password' && <PasswordRules password={values.newPassword} />}
                                <InputForm
                                    controlId="formConfirmPassword"
                                    label={`Confirm new ${currentPage}`}
                                    type={InputType.Password}
                                    name="confirmationPassword"
                                    handleChange={handleChange}
                                    setErrorMessageForm={setErrorMessage}
                                    labelStyle="text-color-primary mb-1 paragraph-medium"
                                    formGroupStyle="mt-5"
                                    value={values.confirmationPassword}
                                    error={(touched.confirmationPassword && errors.confirmationPassword) || ''}
                                />
                                <ButtonForm
                                    buttonName={`RESET ${currentPage.toUpperCase()}`}
                                    handleSubmit={handleSubmit}
                                    isLoading={isLoading}
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            ) : (
                <ResetPasswordSuccess page={history.location.state.page} />
            )}
            <div className="contact text-center mt-5">
                <label className=" m-0 text-color-primary">
                    Registration code is a unique code given by Jivecribe to our dear clients, to receive one
                    contact&nbsp;
                    <a href="mailto:support@jivescribe.com" className="text-note">
                        support@jivescribe.com
                    </a>
                </label>
            </div>
        </AuthForm>
    );
};

export default ResetPassword;
