import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { TemplateDTO } from '../../../common/services/apiTypes';
import Table, { PaginationOptions, TableConfig } from '../../../components/Table/Table';
import AppConstants from '../../../common/constants/app';
import { route } from '../../../common/constants/routes';
import templateServices from '../../../common/services/templateService';
import API from '../../../common/constants/api';
import { formatDate } from '../../../common/helpers/DateFormatHelper';
import { SortType } from '../../../common/constants/enum';

import { renderColumns } from './TemplateHelper';
import './TemplateStyles.scss';

export interface TableTemplateByBranchConfig extends TableConfig {
    userId: number;
}

const Template: React.FunctionComponent = (): React.ReactElement => {
    const match = useRouteMatch<{ id: string }>();
    const nameBranch = decodeURI(window.location.search.replace('?name=', ''));
    const [totalSize, setTotalSize] = useState<number>(0);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [listUser, setListUser] = useState<TemplateDTO[]>([]);
    const history = useHistory();

    const [tableConfig, setTableConfig] = useState<TableTemplateByBranchConfig>({
        page: 1,
        pageSize: AppConstants.pagination.pageSize,
        searchValue: '',
        sortBy: 'createdAt',
        sortDirection: SortType.DESC,
        userId: +match.params.id,
    });

    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: totalSize,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    const getData = async (tableConfigProps: TableTemplateByBranchConfig) => {
        setIsDataLoading(true);
        try {
            const response = await templateServices.getTemplate(tableConfigProps);
            const templates = response.data.list.map((template) => {
                if (template.createdAt) {
                    template.createdAt = formatDate(new Date(template.createdAt));
                }
                return template;
            });
            setListUser(templates);
            setTotalSize(response.data.total);

            setTableConfig({
                page: tableConfigProps.page,
                pageSize: tableConfigProps.pageSize,
                searchValue: tableConfigProps.searchValue,
                sortBy: tableConfigProps.sortBy,
                sortDirection: tableConfigProps.sortDirection,
                userId: +match.params.id,
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    const getHandleTableChange = (type: string, newState: any) => {
        const params = {
            page: newState.page || 1,
            pageSize: newState.sizePerPage || '',
            searchValue: newState.searchText || '',
            sortBy: newState.sortField || 'createdAt',
            sortDirection: newState.sortOrder || 'asc',
            userId: +match.params.id,
        };
        if (type === 'search') {
            return getData({ ...params, page: 1 });
        }
        getData(params);
    };

    const handleGoBack = () => {
        history.push(route.USERS);
    };

    const downloadFileTemplate = async (id: string) => {
        window.open(`${process.env.REACT_APP_API_ENDPOINT}${API.TEMPLATES.DOWNLOAD_FILE_TEMPLATE}${id}`, '_blank');
    };

    const handleAction = () => (id: string) => {
        downloadFileTemplate(id);
    };

    return (
        <div id="templateBox">
            <div className="d-sm-flex align-items-center justify-content-between my-4">
                <h2 className="h2 mb-0 title-page title-user-template" onClick={handleGoBack}>
                    <div className="go-back" />
                    Template for {nameBranch}
                </h2>
            </div>
            <Table
                tableConfig={tableConfig}
                paginationOptions={paginationOptions}
                data={listUser}
                isDataLoading={isDataLoading}
                getData={getData}
                columns={renderColumns(handleAction)}
                getHandleTableChange={getHandleTableChange}
            />
        </div>
    );
};

export default Template;
