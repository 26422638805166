import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Alert, Form } from 'react-bootstrap';

import { VerificationPage } from '../../../common/constants/enum';
import authServices from '../../../common/services/authServices';
import AuthForm from '../../../components/AuthForm/AuthForm';
import InputForm from '../../../components/InputForm/InputForm';
import { doctorRoute, route } from '../../../common/constants/routes';
import ButtonForm from '../../../components/ButtonForm/ButtonForm';

export interface ForgotPasswordFields {
    email: string;
}

interface ForgotPasswordProps {
    isDoctorAuth: boolean;
}

const ForgotPassword: React.FunctionComponent<ForgotPasswordProps> = ({ isDoctorAuth }) => {
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);

    const formForgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .required('This field is required')
            .email('Please enter a valid email address')
            .max(70, 'Email length must be less than or equal to 70 characters long'),
    });

    const handleForgotPassword = async (values: ForgotPasswordFields) => {
        try {
            setLoading(true);
            values.email = values.email.trim();
            const response = await authServices.sendEmailToForgotPassword({
                email: values.email,
            });
            if (response.success) {
                history.push(!isDoctorAuth ? route.VERIFICATION : doctorRoute.VERIFICATION, {
                    email: values.email,
                    page: !isDoctorAuth ? VerificationPage.FORGOT_PASSWORD : VerificationPage.FORGOT_PASS_CODE,
                });
            } else {
                setErrorMessage(response.message);
            }
        } catch (error) {
            setErrorMessage('Please try again.');
        } finally {
            setLoading(false);
        }
    };
    const currentPage = isDoctorAuth ? 'Passcode' : 'Password';
    return (
        <AuthForm isShowRedirect={true}>
            <p className="text-primary mt-5 mb-3 paragraph-main">Forgot {currentPage}</p>
            <p className="paragraph-extra text-color-primary mb-4 ">
                Please enter your registered email to request a {currentPage.toLowerCase()} reset.
            </p>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Formik
                initialValues={{
                    email: '',
                }}
                validateOnChange
                validationSchema={formForgotPasswordSchema}
                enableReinitialize
                onSubmit={(values) => {
                    handleForgotPassword({ email: values.email.toLowerCase() });
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form
                        className="mt-2"
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                            }
                        }}
                    >
                        <InputForm
                            controlId="formEmail"
                            label="Email Address"
                            handleChange={handleChange}
                            name="email"
                            value={values.email}
                            labelStyle="text-color-primary mb-1 paragraph-medium"
                            error={(touched.email && errors.email) || ''}
                            setErrorMessageForm={setErrorMessage}
                            formGroupStyle="mb-5"
                        />
                        <ButtonForm
                            buttonName={`REQUEST ${currentPage.toUpperCase()} RESET`}
                            handleSubmit={handleSubmit}
                            isLoading={isLoading}
                        />
                    </Form>
                )}
            </Formik>
        </AuthForm>
    );
};

export default ForgotPassword;
