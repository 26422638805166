import { all, call, put, takeEvery } from 'redux-saga/effects';

import { ResponseType } from '../../common/services/apiTypes';
import { storage, storageKey } from '../../common/utils/storage';
import { doctorRoute } from '../../common/constants/routes';
import doctorAuthServices from '../../common/services/doctorAuthServices';
import { LoginResponse } from '../auth/actionTypes';

import { DOCTOR_LOGIN, DoctorLoginAction, DOCTOR_LOGOUT } from './actionTypes';
import { doctorLoginSuccess, loginFailure, reset } from './actions';

function* loginSaga(action: DoctorLoginAction) {
    try {
        const response: ResponseType<LoginResponse | null> = yield call(() => doctorAuthServices.login(action.payload));
        if (response.success && !!response.data?.token) {
            storage.removeToken(storageKey.TOKEN);
            storage.setToken(response.data.token, storageKey.DOCTOR_TOKEN);
            yield put(doctorLoginSuccess({ token: response.data.token, status: response.data.status }));
            window.location.pathname = doctorRoute.HOME;
        } else if (response.success && response.data?.invitationCode && response.data?.name) {
            action.history &&
                action.history.push(doctorRoute.CHANGE_PASSWORD_FIRST_TIME, {
                    invitationCode: response.data.invitationCode,
                    name: response.data.name,
                });
        } else {
            yield put(loginFailure({ loginMessage: response.message, status: response.data?.status }));
        }
    } catch (error) {
        yield put(loginFailure({ loginMessage: 'Login Failure. Please try again!' }));
    }
}

function* logoutSaga() {
    try {
        storage.removeToken(storageKey.DOCTOR_TOKEN);
        yield put(reset());
        //* waiting for state updating
        setTimeout(() => {
            window.location.reload();
        }, 400);
    } catch (error) {
        console.log(error);
    }
}

function* watchAll() {
    yield all([takeEvery(DOCTOR_LOGIN, loginSaga), takeEvery(DOCTOR_LOGOUT, logoutSaga)]);
}

export default watchAll();
