import React, { useState } from 'react';
import { Col, Image } from 'react-bootstrap';

import backgroundImg from '../../common/assets/images/background-4.png';
import AppStoreBackgroundImgHover from '../../common/assets/images/app-store-hover.png';
import ChPlayBackgroundImgHover from '../../common/assets/images/google-play-hover.png';
import AppStoreBackgroundImg from '../../common/assets/images/icon-app-store.svg';
import ChPlayBackgroundImg from '../../common/assets/images/icon-ch-play.svg';
import MobileImg from '../../common/assets/images/jive-web-app-landing-animation.gif';
import logo from '../../common/assets/images/loginLogo.png';
import LogoImg from '../../common/assets/images/logo.svg';
import StarImg from '../../common/assets/images/star.svg';
import { LinkMobileApp } from '../../common/constants/enum';

import './DoctorAuthBackgroundStyles.scss';
const DoctorAuthBackground: React.FC = ({ children }): React.ReactElement => {
    const [isHovered, setIsHovered] = useState<string>('');

    const handleMouseEnter = (props: LinkMobileApp) => {
        setIsHovered(props);
    };
    const handleMouseLeave = () => {
        setIsHovered('');
    };

    return (
        <div className="page-container">
            <div className="page-background">
                <img className="image-background" src={backgroundImg} alt="" />
                <div className="information">
                    <div className="information__header">
                        <div className="information__detail">
                            <span className="text-steel-blue">Record dictations and have them transcribed </span>
                            <span className="text-color-orange">anywhere and anytime </span>
                            <span className="text-steel-blue">with the new </span>
                            <span className="text-Jivescribe">Jivescribe</span>
                        </div>
                        <div className="information__logo">
                            <div className="logo">
                                <img src={LogoImg} alt="" />
                            </div>
                            <div className="web-name">
                                <div className="web-name__title">JIVESCRIBE</div>
                                <img src={StarImg} alt="" className="web-name__start" />
                            </div>
                        </div>
                    </div>
                    <div className="image-mobile">
                        <img src={MobileImg} alt="" loading="lazy" />
                    </div>
                    <div className="download">
                        <div className="download-app">
                            <p>
                                <span className="text-cornflower-blue">Download the app now </span>
                            </p>
                        </div>
                        <div className="link-mobile-app">
                            <a
                                href="https://apps.apple.com/vn/app/jivescribe/id1557197979"
                                target="blank"
                                onMouseEnter={() => handleMouseEnter(LinkMobileApp.APPSTORE)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img
                                    src={
                                        isHovered === LinkMobileApp.APPSTORE
                                            ? AppStoreBackgroundImgHover
                                            : AppStoreBackgroundImg
                                    }
                                    alt=""
                                />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.jivescribe.cmsg"
                                target="blank"
                                onMouseEnter={() => handleMouseEnter(LinkMobileApp.CHPLAY)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img
                                    src={
                                        isHovered === LinkMobileApp.CHPLAY
                                            ? ChPlayBackgroundImgHover
                                            : ChPlayBackgroundImg
                                    }
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-content">
                <div className="content-container">
                    <Col className="p-3 text-center">
                        <Image src={logo} className="image-logo" />
                    </Col>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default DoctorAuthBackground;
