import { VerificationPage } from '../../../common/constants/enum';

export const getVerificationSuccessMessage = (page: VerificationPage): string => {
    switch (page) {
        case VerificationPage.FORGOT_PASSWORD:
            return 'You have successfully reset your password. You can now use your new password to login.';
        case VerificationPage.FORGOT_PASS_CODE:
            return 'You have successfully reset your pass-code. You can now use your new pass-code to sign in.';
        default:
            return 'You have successfully verified your password. You can now use your all features of Jive.';
    }
};

export const getVerificationSuccessTitle = (page: VerificationPage): string => {
    switch (page) {
        case VerificationPage.FORGOT_PASSWORD:
            return 'Reset Password Success';
        case VerificationPage.FORGOT_PASS_CODE:
            return 'Reset Passcode Success';
        default:
            return 'Verify Account';
    }
};
