import React from 'react';

import { sortCaret } from '../../../common/helpers/TableHelper';
import {
    AssigneesTableResponse,
    EditorInChargeResponse,
    TranscriptionResponse,
} from '../../../common/services/apiTypes';
import { PaginationOptions, TableConfig } from '../../../components/Table/Table';
import AppConstants from '../../../common/constants/app';
import dictationsServices from '../../../common/services/dictationsServices';

import { Actions, DictationsInProgressDetailColumnFormatter } from './components/ColumnFormatter/ColumnFormatter';

export const formatter = (
    _cell: string,
    row: EditorInChargeResponse | AssigneesTableResponse | TranscriptionResponse,
    _rowIndex: number,
    actions: Actions,
): JSX.Element => <DictationsInProgressDetailColumnFormatter row={row} actions={actions} />;

export const editorInChargeRenderColumns = (
    handleDeleteEditorInCharAction: (idEIC: number) => void,
    currentUserId: number,
    lengthList: number,
    hideAllTrashIcon: boolean,
    disabledAllTrashIcon: boolean,
) => [
    {
        dataField: 'user.lastName',
        text: 'Last Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'user.firstName',
        text: 'First Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'user.email',
        text: 'Email',
    },
    {
        dataField: '',
        text: '',
        formatter: formatter,
        formatExtraData: {
            actionDelete: handleDeleteEditorInCharAction,
            currentUserId,
            hideAllTrashIcon,
            disabledAllTrashIcon,
        },
        classes: 'text-center icon-table',
        style: (_cell: any, row: EditorInChargeResponse) => {
            return {
                minWidth: '140px',
                opacity: disabledAllTrashIcon || lengthList === 1 ? '0.5' : currentUserId === row.user.id ? '1' : '0.5',
            };
        },
        headerStyle: () => {
            return { width: '7%' };
        },
    },
];

export const editorInChargeActions = {
    getListEditorInCharge: async (
        tableConfig: TableConfig,
        setListEditorInCharge: (listAT: EditorInChargeResponse[]) => void,
        dictationId: string,
    ) => {
        const dictationIdNumber = +dictationId;
        const paramsGetEditorInCharge = {
            sortBy: tableConfig.sortBy,
            sortDirection: tableConfig.sortDirection,
            dictationId: dictationIdNumber,
        };
        const response = await dictationsServices.getEditorInCharge(paramsGetEditorInCharge);
        if (response.success) {
            setListEditorInCharge([...response.data.editorInCharges]);
        }
    },
    addEditorInCharge: async (
        isExistEIC: boolean,
        setIsExistEIC: (condition: boolean) => void,
        dictationId: string,
        currentUserId: number,
    ) => {
        try {
            if (!isExistEIC) {
                const dictationIdNumber = +dictationId;
                const paramsAddEditorInCharge = {
                    dictationId: dictationIdNumber,
                    userId: currentUserId,
                };
                const response = await dictationsServices.addEditorInCharge(paramsAddEditorInCharge);
                if (response.success) {
                    setIsExistEIC(true);
                }
            }
        } catch (error) {
            console.log(error);
        }
    },
    deleteEditorInChar: async (idEIC: number, setIsExistEIC: (condition: boolean) => void) => {
        try {
            const response = await dictationsServices.deleteEditorInCharge({ id: idEIC });
            if (response.success) {
                setIsExistEIC(false);
            }
        } catch (error) {
            console.log(error);
        }
    },
};
export const initialTableConfig = {
    page: 1,
    pageSize: AppConstants.pagination.pageSize,
    sortBy: '',
    sortDirection: '',
};
export const paginationOptions: PaginationOptions = {
    custom: true,
    totalSize: 10,
    sizePerPageList: [
        { text: '10', value: 10 },
        { text: '20', value: 20 },
    ],
    sizePerPage: initialTableConfig.pageSize,
    page: initialTableConfig.page,
};
