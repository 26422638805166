import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import AppConstants from '../../../../common/constants/app';
import { VerificationPage } from '../../../../common/constants/enum';
import { route } from '../../../../common/constants/routes';
import { useQuery } from '../../../../common/custom-hooks/queryHook';
import authServices from '../../../../common/services/authServices';
import AuthForm from '../../../../components/AuthForm/AuthForm';
import ContentLoginPage from '../../../../components/ContentLoginPage/ContentLoginPage';
import { reset } from '../../../../redux/auth/actions';
import './LoginStyles.scss';

const Login: React.FunctionComponent = (): React.ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const query = useQuery();
    const invitationCode = query.get('invitationCode') as string;
    const firstName = query.get('firstName') as string;
    const lastName = query.get('lastName') as string;

    const handleLogin = async (values: { email: string; password: string }) => {
        try {
            setLoading(true);
            const response = await authServices.login({
                email: values.email.trim(),
                password: values.password,
            });
            if (response.success) {
                history.push(route.VERIFICATION, { email: response.data.email.trim(), page: VerificationPage.LOGIN });
            } else {
                setErrorMessage(response.message);
            }
        } catch (error) {
            setErrorMessage('Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (invitationCode && firstName && lastName)
            history.push(route.CHANGE_PASSWORD_FIRST_TIME, {
                invitationCode: invitationCode,
                firstName: firstName,
                lastName: lastName,
            });
        dispatch(reset());
    }, []);

    const formLoginSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .required('This field is required')
            .email('Please enter a valid email address')
            .max(70, 'Email length must be less than or equal to 70 characters long'),
        password: Yup.string()
            .required('This field is required')
            .matches(
                AppConstants.passwordHelper.passwordRuleRegex,
                'Must Contain 8-32 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
            ),
    });

    return (
        <AuthForm
            backgroundClass={'login-background'}
            responsiveClass={'login-responsive'}
            isShowContactSupport={true}
            isShowRedirect={false}
            cardClass={'login-card'}
            cardContainerClass={'login-card-container'}
        >
            <ContentLoginPage
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                formLoginSchema={formLoginSchema}
                isLoading={isLoading}
                handleLogin={handleLogin}
                forgotString="Password"
                emailString="Email"
                forgotLink={route.FORGOT_PASSWORD}
            />
        </AuthForm>
    );
};

export default Login;
