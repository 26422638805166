import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import emptyPage from '../../../../common/assets/images/historical-empty.png';
import Table, { PaginationOptions, TableConfig } from '../../../../components/Table/Table';
import SubHeaderPage from '../../../../components/SubHeaderPage/SubHeaderPage';
import AppConstants from '../../../../common/constants/app';
import TableToolbar from '../../../../components/Table/components/TableToolbar/TableToolbar';
import appServices from '../../../../common/services/appServices';
import { DictationResourceType, DictationResponse } from '../../../../common/services/apiTypes';
import { formatDate } from '../../../../common/helpers/DateFormatHelper';
import { GlobalState } from '../../../../redux';
import { getTurnAroundTimes } from '../../../../redux/settings/action';
import { ExpandContent } from '../../../../components/Table/components/ExpandRowContent/ExpandRowContent';
import { DictationStatus, SortType } from '../../../../common/constants/enum';
import { tableConfigGettingTAT } from '../../../../common/helpers/TableHelper';
import SwitchButton from '../../../../components/ButtonForm/SwitchButton';
import DownloadLog from '../finished-transcriptions-modal/DownloadLog';

import { renderColumns } from './FinishedTranscriptionsHelper';

import './FinishedTranscriptionsStyles.scss';

export interface DictationTableConfig extends TableConfig {
    status: string;
    turnAroundTime: string;
    fromDate?: Date;
    includeEditorInCharge: boolean;
}

const initialTableConfig: DictationTableConfig = {
    page: 1,
    pageSize: AppConstants.pagination.pageSize,
    searchValue: '',
    sortBy: 'updatedAt',
    sortDirection: SortType.DESC,
    status: `${DictationStatus.Finished},${DictationStatus.Submitted}`,
    turnAroundTime: '',
    includeEditorInCharge: true,
};

interface DictationsResponse extends DictationResponse {
    userName: string;
}

const FinishedTranscription: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const [totalSize, setTotalSize] = useState<number>(0);
    const { userInfo } = useSelector((state: GlobalState) => state.user);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [listDictation, setListDictation] = useState<DictationsResponse[]>([]);
    const [tableConfig, setTableConfig] = useState<DictationTableConfig>(initialTableConfig);
    const turnAroundTime = useSelector((state: GlobalState) => state.settings.turnAroundTime.list);
    const [itemTATFilter, setItemTATFilter] = useState<string>('');
    const [listResourceFile, setListResourceFile] = useState<DictationResourceType[]>([]);
    const [dictationIdActive, setDictationIdActive] = useState(0);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [expanded, _] = useState<number[]>([]);
    const [isOn, setIsOn] = useState(false);

    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: totalSize,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    const getData = async (tableConfigProps: DictationTableConfig) => {
        setIsDataLoading(true);
        try {
            const response = await appServices.getListDictationDoctorRole(tableConfigProps);
            if (response.success) {
                const listFormatDate = response.data.list.map((item) => ({
                    ...item,
                    createdAt: formatDate(item.createdAt),
                    updatedAt: formatDate(item.updatedAt),
                    editorIncharge: item.editorInCharges
                        .map((item) => {
                            return `${item.user.firstName} ${item.user.lastName}`;
                        })
                        .join(', '),
                }));
                setListDictation(listFormatDate);
                setTableConfig({ ...tableConfigProps, page: parseInt(response.data.current_page) });
                setTotalSize(response.data.total);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    useEffect(() => {
        if (itemTATFilter) getData({ ...tableConfig, page: 1, turnAroundTime: itemTATFilter });
    }, [itemTATFilter]);

    useEffect(() => {
        dispatch(getTurnAroundTimes(tableConfigGettingTAT));
    }, []);

    const getHandleTableChange = (type: string, newState: any) => {
        const params = {
            page: newState.page || 1,
            pageSize: newState.sizePerPage || AppConstants.pagination.pageSize,
            searchValue: newState.searchText || '',
            sortBy: newState.sortField || 'updatedAt',
            sortDirection: newState.sortOrder || SortType.DESC,
            status: tableConfig.status,
            turnAroundTime: tableConfig.turnAroundTime,
            includeEditorInCharge: tableConfig.includeEditorInCharge,
        };
        if (params.sortBy === 'editorIncharge') {
            params.sortBy = 'firstName';
        }
        if (type === 'search') {
            setIsSearch(true);
            return getData({ ...params, page: 1 });
        }
        getData(params);
    };

    const handleClick = (id: number) => {
        setDictationIdActive(id);
        setIsShowModal(true);
    };

    const getResourceData = async (dictationId: number, setResource: (resource: DictationResourceType) => void) => {
        try {
            const response = await appServices.getDictationResourceDoctorRole(dictationId);
            if (response.success) {
                setListResourceFile([...listResourceFile, response.data]);
                setResource(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const renderExpandContent = (row: any) => {
        return (
            <>
                <ExpandContent
                    getResourceData={getResourceData}
                    dictationId={row.id}
                    listResourceFile={listResourceFile}
                />
            </>
        );
    };

    const expandRow = {
        renderer: (row: any) => renderExpandContent(row),
        onExpand: (row: any, isExpand: boolean) => {
            const data = listDictation.map((i) => (i.id === row.id ? { ...row, opened: isExpand } : { ...i }));
            setListDictation(data);
            const index = expanded.indexOf(row.id);
            if (index !== -1) {
                expanded.splice(index, 1);
            } else {
                expanded.push(row.id);
            }
        },
        expanded: expanded,
    };

    const handleClose = () => {
        setIsShowModal(false);
        setDictationIdActive(0);
    };

    const tableFilter =
        (turnAroundTime?.length && turnAroundTime.map((t) => ({ id: parseInt(t.id), value: t.value }))) || [];

    useEffect(() => {
        getData(tableConfig);
    }, []);

    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

    const onClickOutSite = () => {
        setIsOn(false);
    };

    return (
        <>
            {listDictation.length > 0 || itemTATFilter || isSearch ? (
                <div className="container">
                    <DownloadLog
                        onClickOutSite={onClickOutSite}
                        isOpen={isOn}
                        toggleSwitch={toggleSwitch}
                    ></DownloadLog>
                    <div className="header-historical">
                        <SubHeaderPage title="Historical audio & Files list" />
                        <div className="download-log">
                            <span className="download-log-title">Download Logs</span>
                            <SwitchButton toggleSwitch={toggleSwitch} isOn={isOn} />
                        </div>
                    </div>
                    <Table
                        tableConfig={tableConfig}
                        paginationOptions={paginationOptions}
                        data={listDictation}
                        isDataLoading={isDataLoading}
                        getData={getData}
                        columns={renderColumns(handleClick)}
                        getHandleTableChange={getHandleTableChange}
                        expandRow={expandRow}
                        rowClasses="item-table td-line-height"
                        tableToolbar={
                            <TableToolbar
                                isShowFilterGroup
                                listFilter={[
                                    {
                                        value:
                                            turnAroundTime.find((tat) => +tat.id === userInfo.turnAroundTimeId)
                                                ?.value || '',
                                    },
                                ]}
                                setFilterValue={setItemTATFilter}
                            />
                        }
                    />
                </div>
            ) : (
                <div className="empty-page">
                    <div className="empty-element">
                        <img src={emptyPage} className="empty-image" alt="" />
                    </div>
                </div>
            )}
        </>
    );
};

export default FinishedTranscription;
