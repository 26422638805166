import { call, put, takeEvery } from 'redux-saga/effects';

import { ResponseType, TurnAroundTimeResponse } from '../../common/services/apiTypes';
import settingsServices from '../../common/services/settingsServices';
import { notify } from '../../common/utils/notify';
import { storage, storageKey } from '../../common/utils/storage';

import {
    createTurnAroundTimeFailure,
    getTurnAroundTimes,
    getTurnAroundTimesFailure,
    getTurnAroundTimesSuccess,
} from './action';
import {
    createTurnAroundTimeAction,
    CREATE_TURN_AROUND_TIME,
    deleteTurnAroundTimeAction,
    DELETE_TURN_AROUND_TIME,
    getTurnAroundTimeResponse,
    getTurnAroundTimesAction,
    GET_TURN_AROUND_TIMES,
} from './actionTypes';

function* getTurnAroundTimesSaga(action: getTurnAroundTimesAction) {
    const getTurnAroundTime = storage.getToken(storageKey.TOKEN)?.length
        ? settingsServices.getTurnAroundTimes(action.payload)
        : settingsServices.getTurnAroundTimesDoctorRole(action.payload);

    try {
        const response: ResponseType<getTurnAroundTimeResponse> = yield call(() => getTurnAroundTime);
        yield put(getTurnAroundTimesSuccess(response.data));
    } catch (error) {
        yield put(getTurnAroundTimesFailure());
    }
}

function* createTurnAroundTime(action: createTurnAroundTimeAction) {
    try {
        const response: ResponseType<{ turnAroundTime: TurnAroundTimeResponse }> = yield call(() =>
            settingsServices.createTurnAroundTime({ value: action.payload.value }),
        );
        yield put(getTurnAroundTimes(action.payload));
        if (response.success) {
            notify.success('Turnaround time successfully added.', 'Success');
        } else {
            notify.error(response.message, 'Error');
        }
    } catch (error) {
        notify.error('Failure, please try again!', 'Error');
        yield put(createTurnAroundTimeFailure());
    }
}

function* deleteTurnAroundTime(action: deleteTurnAroundTimeAction) {
    try {
        const response: ResponseType<null> = yield call(() =>
            settingsServices.deleteTurnAroundTime({ id: action.payload.id }),
        );
        if (response.success) {
            yield put(getTurnAroundTimes(action.payload));
            notify.success('Turnaround time successfully deleted.', 'Success');
        } else {
            notify.error(response.message, 'Error');
            yield put(createTurnAroundTimeFailure());
        }
    } catch (error) {
        yield put(createTurnAroundTimeFailure());
        notify.error('Failure, please try again!', 'Error');
    }
}

function* watchAll() {
    yield takeEvery(GET_TURN_AROUND_TIMES, getTurnAroundTimesSaga);
    yield takeEvery(CREATE_TURN_AROUND_TIME, createTurnAroundTime);
    yield takeEvery(DELETE_TURN_AROUND_TIME, deleteTurnAroundTime);
}
export default watchAll();
