import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import emptyPage from '../../../../common/assets/images/historical-empty.png';
import AppConstants from '../../../../common/constants/app';
import { DictationStatus, SortType } from '../../../../common/constants/enum';
import { formatDate } from '../../../../common/helpers/DateFormatHelper';
import { tableConfigGettingTAT } from '../../../../common/helpers/TableHelper';
import appServices from '../../../../common/services/appServices';
import { sliceFileName } from '../../../../common/utils/converter';
import SubHeaderPage from '../../../../components/SubHeaderPage/SubHeaderPage';
import Table, { PaginationOptions, TableConfig } from '../../../../components/Table/Table';
import TableToolbar from '../../../../components/Table/components/TableToolbar/TableToolbar';
import { getTurnAroundTimes } from '../../../../redux/settings/action';
import { doctorRoute } from '../../../../common/constants/routes';

import { renderColumns } from './FilesListHelper';

import './FilesListStyles.scss';

export interface DictationTableConfig extends TableConfig {
    dictationId?: number;
    status: string;
    turnAroundTime: string;
    fromDate?: Date;
    includeEditorInCharge: boolean;
}

interface TranscriptionDTO {
    fileName: string;
    id: number;
}

const FilesList: React.FunctionComponent = () => {
    const { id }: any = useParams();
    const dispatch = useDispatch();
    const [totalSize, setTotalSize] = useState<number>(0);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [itemFilter, setItemFilter] = useState<string>('');
    const [dictationIdActive, setDictationIdActive] = useState(0);
    const [listTranscription, setListTranscription] = useState<TranscriptionDTO[]>([]);
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const sortTypeArr = [
        { id: 'asc', value: 'Oldest to newest' },
        { id: 'desc', value: 'Newest to oldest' },
    ];
    const initialTableConfig: DictationTableConfig = {
        dictationId: id || 0,
        page: 1,
        pageSize: AppConstants.pagination.pageSize,
        searchValue: '',
        sortBy: 'updatedAt',
        sortDirection: SortType.DESC,
        status: `${DictationStatus.Finished},${DictationStatus.Submitted}`,
        turnAroundTime: '',
        includeEditorInCharge: true,
    };
    const [tableConfig, setTableConfig] = useState<DictationTableConfig>(initialTableConfig);
    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: totalSize,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    const getDataDetail = async (tableConfigProps: DictationTableConfig) => {
        setIsDataLoading(true);
        try {
            const response = await appServices.getListFileFinishedTranscriptionsDoctorRole(tableConfigProps);
            if (response.success) {
                const listFormatDate = response.data.list.map((item: any) => ({
                    ...item,
                    createdAt: formatDate(item.createdAt),
                    updatedAt: formatDate(item.updatedAt),
                    fileName: sliceFileName(item.fileName),
                }));
                setListTranscription(listFormatDate);
                setTableConfig({ ...tableConfigProps, page: parseInt(response.data.current_page) });
                setTotalSize(response.data.total);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    const getHandleTableChange = (type: string, newState: any) => {
        const params = {
            dictationId: id || 0,
            page: newState.page || 1,
            pageSize: newState.sizePerPage || AppConstants.pagination.pageSize,
            searchValue: newState.searchText || '',
            sortBy: newState.sortField || 'updatedAt',
            sortDirection: newState.sortOrder || SortType.DESC,
            status: tableConfig.status,
            turnAroundTime: tableConfig.turnAroundTime,
            includeEditorInCharge: tableConfig.includeEditorInCharge,
        };
        if (params.sortBy === 'editorIncharge') {
            params.sortBy = 'firstName';
        }
        if (type === 'search') {
            setIsSearch(true);
            return getDataDetail({ ...params, page: 1 });
        }
        getDataDetail(params);
    };
    const handleClick = (id: number) => {
        setDictationIdActive(id);
    };

    const tableFilter = (sortTypeArr?.length && sortTypeArr.map((t) => ({ id: t.id, value: t.value }))) || [];

    useEffect(() => {
        getDataDetail(tableConfig);
    }, []);

    useEffect(() => {
        if (itemFilter) getDataDetail({ ...tableConfig, page: 1, sortDirection: itemFilter });
    }, [itemFilter]);

    useEffect(() => {
        dispatch(getTurnAroundTimes(tableConfigGettingTAT));
    }, []);

    return (
        <>
            {listTranscription.length > 0 || itemFilter || isSearch ? (
                <div className="container">
                    <div className="header-files-list">
                        <SubHeaderPage
                            isBackTitle={true}
                            backUrl={doctorRoute.FINISHED_TRANSCRIPTIONS}
                            title={`Historical audio & Files list < ${id}`}
                        />
                        <span>({listTranscription.length} Files)</span>
                    </div>

                    <Table
                        tableConfig={tableConfig}
                        paginationOptions={paginationOptions}
                        data={listTranscription}
                        isDataLoading={isDataLoading}
                        getData={getDataDetail}
                        columns={renderColumns(handleClick)}
                        getHandleTableChange={getHandleTableChange}
                        rowClasses="item-table td-line-height"
                        tableToolbar={
                            <TableToolbar isShowFilterGroup listFilter={tableFilter} setFilterValue={setItemFilter} />
                        }
                    />
                </div>
            ) : (
                <div className="empty-page">
                    <div className="empty-element">
                        <img src={emptyPage} className="empty-image" alt="" />
                    </div>
                </div>
            )}
        </>
    );
};

export default FilesList;
