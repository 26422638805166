import React from 'react';
import classNames from 'classnames';

import './PasswordRulesStyles.scss';

interface PasswordRulesProps {
    password: string;
}

const passwordRules = [
    { text: 'At least 8, but no more than 32.', regex: /^.{8,32}$/ },
    { text: 'At least one UPPERCASE letter.', regex: /^.*[A-Z]/ },
    { text: 'At least one lowercase letter.', regex: /^.*[a-z]/ },
    { text: 'At least one special character.', regex: /^.*[!@#$%^&*]/ },
    { text: 'At least one number.', regex: /^.*[0-9]/ },
];

const PasswordRules: React.FunctionComponent<PasswordRulesProps> = ({ password }): React.ReactElement => {
    return (
        <div className="pw-rules">
            <div className="pw-rules__title mt-2 paragraph-medium">Password Rules</div>
            <ul className="pw-rules__content mt-2">
                {passwordRules.map((rule, index) => (
                    <li
                        key={index}
                        className={classNames('pw-rule', 'ml-3', { 'text-success': password.match(rule.regex) })}
                    >
                        <i className="fa fa-circle mr-1" aria-hidden="true"></i>
                        {rule.text}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default PasswordRules;
