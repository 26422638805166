import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import Table, { TableConfig } from '../../../components/Table/Table';
import SubHeaderPage from '../../../components/SubHeaderPage/SubHeaderPage';
import TableToolbar from '../../../components/Table/components/TableToolbar/TableToolbar';
import { GlobalState } from '../../../redux';
import { EditorInChargeResponse } from '../../../common/services/apiTypes';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import { notify } from '../../../common/utils/notify';
import { route } from '../../../common/constants/routes';
import { sliceStringWithLastCharacter } from '../../../common/utils/converter';
import { DictationTableConfig } from '../dictations-in-progress/DictationsInProgress';
import { UserRole } from '../../../common/constants/enum';

import {
    editorInChargeActions,
    editorInChargeRenderColumns,
    initialTableConfig,
    paginationOptions,
} from './DictationsDetailHelper';
import AssigneeTableContainer from './components/AssigneeTableContainer/AssigneeTableContainer';
import AttachTranscriptionTableContainer from './components/AttachTranscriptionTableContainer/AttachTranscriptionTableContainer';

import './DictationsDetailStyles.scss';

interface DictationsDetailProps {
    titlePage: string;
}
const DictationsDetail: React.FunctionComponent<DictationsDetailProps> = ({ titlePage }) => {
    const userInfo = useSelector((state: GlobalState) => state.user.userInfo);

    const [listEditorInCharge, setListEditorInCharge] = useState<EditorInChargeResponse[]>([]);
    const [isExistEIC, setIsExistEIC] = useState(false);
    const [isExistAssignee, setIsExistAssignee] = useState(false);
    const { dictationId } = useParams<{ dictationId: string }>();
    const [selectedEIC, setSelectedEIC] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory<{ tableConfigRemember: DictationTableConfig }>();
    const backUrl = sliceStringWithLastCharacter(location.pathname, '/');
    const isSubmittedPage = backUrl === route.DICTATIONS_SUBMITTED;
    const isHistoricalPage = backUrl === route.HISTORICAL_RECORD;
    const isHomePage = backUrl === sliceStringWithLastCharacter(route.DICTATIONS_UNASSIGNED_DETAIL, '/');

    useEffect(() => {
        setIsExistEIC(listEditorInCharge.findIndex((item) => item.user.id === userInfo.id) !== -1);
    }, [listEditorInCharge]);

    const getListEditorInCharge = async (tableConfig: TableConfig) => {
        try {
            await editorInChargeActions.getListEditorInCharge(tableConfig, setListEditorInCharge, dictationId);
        } catch (error) {
            history.replace(backUrl);
        }
    };

    const handleAddEditorInChargeAction = async () => {
        if (!isLoading) {
            setIsLoading(true);
            await editorInChargeActions.addEditorInCharge(isExistEIC, setIsExistEIC, dictationId, userInfo.id);
            await getListEditorInCharge(initialTableConfig);
            notify.success('Editor-In-Charge successfully added.', 'Success');
            setIsLoading(false);
        }
    };

    const handleDeleteEditorInCharAction = async () => {
        if (!isLoading) {
            setIsLoading(true);
            const targetEIC = listEditorInCharge.find((item) => item.id === selectedEIC);
            if (targetEIC?.user.id === userInfo.id) {
                await editorInChargeActions.deleteEditorInChar(selectedEIC, setIsExistEIC);
                setSelectedEIC(0);
                await getListEditorInCharge(initialTableConfig);
                notify.success('Editor-In-Charge successfully deleted.', 'Success');
            }
            setIsLoading(false);
        }
    };

    const getHandleTableEditorInChargeChange = (type: string, newState: any) => {
        const dotIdx = newState.sortField.indexOf('.') + 1;
        getListEditorInCharge({
            ...initialTableConfig,
            sortBy: newState.sortField.slice(dotIdx, newState.sortField.length),
            sortDirection: newState.sortOrder,
        });
    };

    const cancelModal = () => {
        setSelectedEIC(0);
    };

    return (
        <div>
            <SubHeaderPage
                title={titlePage}
                isBackTitle
                backUrl={isHomePage ? route.HOME : backUrl}
                tableConfigRemember={history.location.state?.tableConfigRemember}
            />
            <Table
                title="Editor-In-Charge"
                isHideSearchBar
                isHidePagination
                tableClassName="mb-4"
                tableConfig={initialTableConfig}
                paginationOptions={paginationOptions}
                data={listEditorInCharge}
                isDataLoading={false}
                getData={getListEditorInCharge}
                columns={editorInChargeRenderColumns(
                    setSelectedEIC,
                    userInfo.id,
                    listEditorInCharge.length,
                    isHistoricalPage,
                    isSubmittedPage,
                )}
                getHandleTableChange={getHandleTableEditorInChargeChange}
                tableToolbar={
                    !(isHistoricalPage || isSubmittedPage) &&
                    !isExistEIC &&
                    (userInfo.userRole === UserRole.Admin || userInfo.userRole === UserRole.Editor) ? (
                        <TableToolbar textAddNewButton="+ Add account" handleAction={handleAddEditorInChargeAction} />
                    ) : null
                }
            />
            <ConfirmModal
                isOpen={listEditorInCharge.length > 1 && selectedEIC}
                setIsOpen={setSelectedEIC}
                actionConfirm={handleDeleteEditorInCharAction}
                actionCancel={cancelModal}
                titleModal="Delete Editor-In-Charge"
                textConfirm="Delete Now"
                className="turn-around-time-confirm-modal"
                confirmButtonClassName="delete-button"
            >
                <p>Are you sure to delete this Editor-In-Charge?</p>
            </ConfirmModal>
            <AssigneeTableContainer
                isHistoricalPage={isHistoricalPage}
                isSubmittedPage={isSubmittedPage}
                dictationId={dictationId}
                isEditable={isExistEIC}
                setIsExistAssignee={setIsExistAssignee}
            />
            {!isHomePage ? (
                <AttachTranscriptionTableContainer
                    isHistoricalPage={isHistoricalPage}
                    dictationId={dictationId}
                    isEditable={isExistEIC || isExistAssignee}
                />
            ) : null}
        </div>
    );
};

export default DictationsDetail;
