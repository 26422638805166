import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

import './ButtonFormStyles.scss';

interface ButtonFormProps {
    handleSubmit: () => void;
    buttonName: string;
    isLoading?: boolean;
    className?: string;
}

const ButtonForm: React.FunctionComponent<ButtonFormProps> = ({
    handleSubmit,
    isLoading,
    buttonName,
    className,
}): React.ReactElement => {
    return (
        <Button className={`blue-button ${className}`} block onClick={handleSubmit} disabled={isLoading}>
            {buttonName}
            {isLoading && (
                <span className="ml-3">
                    <Spinner className="loading" animation="border" size="sm" variant="light" />
                </span>
            )}
        </Button>
    );
};

export default ButtonForm;
