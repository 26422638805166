import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import './PopupUploadStytles.scss';

interface Props {
    isShowDialog: boolean;
    setShowDialog: (show: boolean) => void;
    isUploadSuccess: boolean;
}

export const PopupUpload: React.FunctionComponent<Props> = ({ isShowDialog, setShowDialog, isUploadSuccess }) => {
    const renderContent = () => {
        switch (isUploadSuccess) {
            case true:
                return {
                    title: 'Upload Successful',
                    content: 'Your dictation was uploaded successfully!',
                    subContent: 'You can now check your recent uploads on the home screen.',
                };
            case false:
                return {
                    title: 'Upload Failed',
                    content: 'A problem was encountered while uploading your file.',
                    subContent:
                        "Please make sure that you have stable internet connection and that the file you're uploading matches our accepted formats.",
                };
            default:
                return;
        }
    };

    return (
        <Modal
            aria-labelledby="contained-modal-title-center"
            centered
            show={isShowDialog}
            onHide={() => setShowDialog(false)}
        >
            <Modal.Header closeButton className={isUploadSuccess ? 'model-success' : 'model-failed'}>
                <Modal.Title id="contained-modal-title-center">{renderContent()?.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{renderContent()?.content}</p>
                <p className="sub-content">{renderContent()?.subContent}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="light"
                    onClick={() => setShowDialog(false)}
                    className={`px-4 py-1 ${isUploadSuccess ? 'success-button' : 'failed-button'}`}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
