import { Formik } from 'formik';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import { doctorRoute } from '../../../../common/constants/routes';
import doctorAuthServices from '../../../../common/services/doctorAuthServices';
import AuthForm from '../../../../components/AuthForm/AuthForm';
import ButtonForm from '../../../../components/ButtonForm/ButtonForm';
import InputForm from '../../../../components/InputForm/InputForm';

import './RegistrationStyles.scss';

const Registration: React.FC = (): React.ReactElement => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const registrationSchema = Yup.object().shape({
        registrationCode: Yup.string()
            .required('This field is required')
            .matches(/^[0-9]{8}$/, 'Must Contain 8 Numbers'),
    });

    const handleRegistrationCode = async (
        values: { registrationCode: string },
        setFieldError: (field: string, error: string) => void,
    ) => {
        try {
            setIsLoading(true);
            const response = await doctorAuthServices.verifyRegistrationCode(values);
            if (response.data.isValidCode) {
                history.push({
                    pathname: doctorRoute.SIGN_UP,
                    state: {
                        registrationCode: values.registrationCode,
                    },
                });
            } else {
                setIsLoading(false);
                setFieldError(
                    'registrationCode',
                    'Registered Code is invalid. Please contact support to get a new code.',
                );
            }
            return;
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <AuthForm isShowRedirect={true} textRedirect="Back" linkRedirect={doctorRoute.WELCOME}>
            <p className="text-primary mt-5 mb-3 paragraph-main">Registration Code</p>
            <p className="paragraph-extra text-color-primary mb-0 ">Enter your registration code below to continue.</p>
            <Formik
                initialValues={{
                    registrationCode: '',
                }}
                validateOnChange
                validationSchema={registrationSchema}
                enableReinitialize
                onSubmit={(values, actions) => {
                    handleRegistrationCode(values, actions.setFieldError);
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form
                        onKeyDown={(e: any) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                            }
                        }}
                    >
                        <InputForm
                            controlId="formRegistration"
                            label="Passcode"
                            handleChange={handleChange}
                            name="registrationCode"
                            value={values.registrationCode}
                            labelStyle="text-color-primary mt-5 mb-1 paragraph-medium"
                            error={(touched.registrationCode && errors.registrationCode) || ''}
                        />
                        <ButtonForm
                            className="registerBtn"
                            buttonName="REGISTER"
                            handleSubmit={handleSubmit}
                            isLoading={isLoading}
                        />
                    </Form>
                )}
            </Formik>
            <div className="contact text-center mt-5">
                <label className="d-block m-0 text-color-primary note">
                    Registration code is a unique code given by Jivecribe to our dear clients, to receive one
                    contact&nbsp;
                    <a href="mailto:support@jivescribe.com" className="text-note">
                        support@jivescribe.com
                    </a>
                </label>
            </div>
        </AuthForm>
    );
};

export default Registration;
