const API = {
    AUTH: {
        LOGIN: 'user/editor/login',
        RESET_PASSWORD: 'user/reset-password',
        SEND_EMAIL_TO_FORGOT_PASSWORD: 'user/send-verify-code',
        VERIFY_CODE_TO_RESET_PASSWORD: 'user/verify-reset-password-code',
        VERIFY_CODE_TO_LOGIN: 'user/editor/verify-login',
        CHANGE_PASSWORD: 'user/change-password',
        CHANGE_PASSWORD_FIRST_TIME: 'user/staff-first-change-password',
    },
    DOCTOR_AUTH: {
        LOGIN: 'user/login',
        VERIFY_CODE_TO_SIGN_UP: 'user/verify-sign-up-code',
        VERIFY_REGISTRATION_CODE: 'user/verify-regis-code',
        SIGN_UP: 'user/sign-up',
        CHANGE_PASSWORD_FIRST_TIME: 'user/doctor-first-change-password',
    },
    SETTINGS: {
        TURN_AROUND_TIME: 'turnAroundTime',
    },
    DOCTOR_DICTATION: {
        CREATE_DICTATION: 'dictation',
        UPLOAD_AUDIO: 'record/upload',
        UPLOAD_ATTACHMENT: 'dictation/upload-attachment',
        UPLOAD_SUCCESS_FILES: 'record/uploaded-success-files',
    },
    DICTATIONS: {
        EDITOR_IN_CHARGE: 'editor-in-charge',
        ASSIGNEE_DICTATION: 'assignee/dictation',
        ASSIGNEE: 'assignee',
        GET_TRANSCRIPTION: 'dictation/:dictationId/transcriptions',
        UPLOAD_TRANSCRIPTION: 'dictation/upload-transcription',
        DELETE_TRANSCRIPTION: 'dictation/transcription',
        GET_LIST_DICTATION: 'dictation',
        GET_LIST_DICTATION_TRANSCRIPTION: 'dictation/transcriptions',
        GET_DICTATION_RESOURCE: 'dictation/resource/',
        GET_DICTATION_EDITOR_IN_CHARGE: 'editor-in-charge',
        GET_DICTATION_ASSIGNEE: 'assignee/dictation',
        UPDATE_STATUS: 'dictation/update-status/',
        DOWNLOAD_FILE_CSV: 'dictation/export-csv',
        DOWNLOAD_FILE_TRANSCRIPTION: 'dictation/transcription/get-file/',
        DOWNLOADED_FILE_TRANSCRIPTION_STATUS: 'dictation/transcription/status/',
    },
    USER: {
        GET_LIST_USER: 'user/client-user',
        UPLOAD_AVATAR: 'user/change-avatar',
        GET_PROFILE_USER: 'user/profile',
        UPDATE_STATUS_USER: 'user/update-status',
    },
    TEMPLATES: {
        GET_LIST_TEMPLATE: 'template',
        DOWNLOAD_FILE_TEMPLATE: 'template/get-file/',
        UPLOAD_FILE_TEMPLATE: 'template/upload',
    },
    ACCOUNT: {
        GET_LIST_ACCOUNT: 'account',
        GET_LIST_ACCOUNT_ACTIVE: 'account/active',
        UPDATE_ACCOUNT: 'account/update/',
    },
    GET_ASSIGNEES: 'assignee',
    GET_DASHBOARD: 'dashboard',
    GET_STAFFS: 'user/staffs',
    CREATE_STAFF: 'user/invite-user',
    DELETE_STAFF: 'user/delete-many-staffs',
};

export default API;
