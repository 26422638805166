import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../../../redux';
import QuarterPicker from '../../../../../components/Picker/QuarterPicker/QuarterPicker';
import { TableConfig } from '../../../../../components/Table/Table';

import './TableToolbarHistoricalRecordStyles.scss';

export interface DictationTableConfig extends TableConfig {
    status: string;
    turnAroundTime: string;
    fromDate?: Date;
    accountId?: string;
}

export interface ToolbarFilter {
    searchKey: string;
}

interface TableToolbarProps {
    tableFilter: DictationTableConfig;
    handleChangeFilter: (params: DictationTableConfig) => void;
    handleDownloadFileCsv: (tableConfig: DictationTableConfig, fromDate?: Date) => void;
    listAccount: { id?: number | string; value: string }[];
}

const TableToolbarHistoricalRecord: React.FunctionComponent<TableToolbarProps> = ({
    tableFilter,
    handleChangeFilter,
    handleDownloadFileCsv,
    listAccount,
}) => {
    const turnAroundTime = useSelector((state: GlobalState) => state.settings.turnAroundTime.list);
    const turnAroundTimeValues =
        (turnAroundTime?.length && turnAroundTime.map((t) => ({ id: parseInt(t.id), value: t.value }))) || [];
    const [searchKey, setSearchKey] = useState<string>('');
    const [tableConfig, setTableConfig] = useState<DictationTableConfig>(tableFilter);

    const handleChangeSearchKey = (e: any) => {
        setSearchKey(e.target.value);
    };

    const handleSearch = () => {
        handleChangeFilter({ ...tableFilter, searchValue: searchKey });
        setTableConfig({ ...tableFilter, searchValue: searchKey });
    };

    const handleChangeFilterKey = (filterValue: { turnAroundTime?: string; accountId?: string }) => {
        handleChangeFilter({ ...tableFilter, ...filterValue });
        setTableConfig({ ...tableFilter, ...filterValue });
    };

    const handleChangeDatePicker = (fromDate?: Date) => {
        handleChangeFilter({ ...tableFilter, fromDate });
        setTableConfig({ ...tableFilter, fromDate });
    };

    useEffect(() => {
        if (tableFilter.searchValue?.length) {
            setSearchKey(tableFilter.searchValue);
        } else setSearchKey(searchKey.trim());
    }, [tableFilter.searchValue]);

    return (
        <>
            <div className="d-flex justify-content-between filter-box">
                <div className="d-flex filter-left-box">
                    <div className="col-md-3 p-0 mb-3 search-box">
                        <InputGroup>
                            <FormControl
                                aria-describedby="basic-addon2"
                                className="text-truncate"
                                value={searchKey}
                                onChange={handleChangeSearchKey}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                            />
                            <InputGroup.Append>
                                <Button variant="light" onClick={handleSearch} className="px-3">
                                    Search
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                    <div className="col-md-2 p-0 mb-3 turn-around-time-box">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="btnTurnAroundTime">TAT</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                as="select"
                                onChange={(e) => handleChangeFilterKey({ turnAroundTime: e.target.value })}
                                defaultValue={tableConfig.turnAroundTime}
                            >
                                <option value={''}>Select State</option>
                                {turnAroundTimeValues?.length &&
                                    turnAroundTimeValues.map((item, index) => (
                                        <option key={index} value={item.id.toString()}>
                                            {item.value}
                                        </option>
                                    ))}
                            </Form.Control>
                        </InputGroup>
                    </div>
                    {!!listAccount?.length && (
                        <div className="col-md-3 ml-3 p-0 mb-3 turn-around-time-box">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="btnTurnAroundTime">Account</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => handleChangeFilterKey({ accountId: e.target.value })}
                                    defaultValue={tableConfig.accountId}
                                >
                                    <option value={''}>Select State</option>
                                    {listAccount?.length &&
                                        listAccount.map((item, index) => (
                                            <option key={index} value={item.id}>
                                                {item.value}
                                            </option>
                                        ))}
                                </Form.Control>
                            </InputGroup>
                        </div>
                    )}

                    <div className="col-md-3 p-0 mb-3 datePicker-box">
                        <QuarterPicker onChange={handleChangeDatePicker} defaultQuarter={tableConfig.fromDate} />
                    </div>
                </div>
                <div id="btnDownload" onClick={() => handleDownloadFileCsv(tableConfig, tableConfig.fromDate)}></div>
            </div>
        </>
    );
};

export default TableToolbarHistoricalRecord;
