import { GET_STATISTIC_SUCCESS, HomeType } from './actionTypes';

export interface HomeState {
    statistic: {
        registerAccounts: (number | string)[];
        currentTranscriptions: (number | string)[];
        finishedTranscriptions: (number | string)[];
    };
}

const initialState: HomeState = {
    statistic: {
        registerAccounts: [0, 0],
        currentTranscriptions: [0, 0, 0],
        finishedTranscriptions: [0, 0],
    },
};

const reducer = (state = initialState, action: HomeType) => {
    switch (action.type) {
        case GET_STATISTIC_SUCCESS:
            return {
                ...state,
                statistic: { ...action.payload },
            };

        default:
            return state;
    }
};

export default reducer;
