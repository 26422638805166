import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TemplateDTO } from '../../../common/services/apiTypes';
import emptyTempalte from '../../../common/assets/images/template-empty.png';
import Table, { PaginationOptions, TableConfig } from '../../../components/Table/Table';
import AppConstants from '../../../common/constants/app';
import templateServices from '../../../common/services/templateService';
import { formatDate } from '../../../common/helpers/DateFormatHelper';
import SubHeaderPage from '../../../components/SubHeaderPage/SubHeaderPage';
import TableToolbar from '../../../components/Table/components/TableToolbar/TableToolbar';
import { ActionSubject, SortType } from '../../../common/constants/enum';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import { notify } from '../../../common/utils/notify';
import { GlobalState } from '../../../redux';

import { renderColumns } from './TemplateHelper';
import { TemplateUploadDialog } from './components/TemplateUploadDialog/TemplateUploadDialog';

import './TemplateStyles.scss';

const initialTableConfig: TableConfig = {
    page: 1,
    pageSize: AppConstants.pagination.pageSize,
    searchValue: '',
    sortBy: 'createdAt',
    sortDirection: SortType.DESC,
};

const Template: React.FunctionComponent = (): React.ReactElement => {
    const [totalSize, setTotalSize] = useState<number>(0);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [isShowDialog, setShowDialog] = useState(false);
    const [listTemplate, setListTemplate] = useState<TemplateDTO[]>([]);
    const [reloadPageType, setReloadPageType] = useState<ActionSubject>(ActionSubject.UNDEFINED);
    const [isShowDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [templateId, setTemplateId] = useState<number>(0);
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
    const userInfo = useSelector((state: GlobalState) => state.user.userInfo);
    const [tableConfig, setTableConfig] = useState<TableConfig>(initialTableConfig);

    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: totalSize,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    const getData = async (tableConfigProps: TableConfig) => {
        setIsDataLoading(true);
        try {
            const response = await templateServices.getTemplateDoctorRole({ ...tableConfigProps, userId: userInfo.id });
            if (response.success) {
                const templates = response.data.list.map((template) => {
                    if (template.createdAt) {
                        template.createdAt = formatDate(new Date(template.createdAt));
                    }
                    return template;
                });
                setListTemplate(templates);
                setTotalSize(response.data.total);
                setTableConfig({ ...tableConfigProps, page: parseInt(response.data.current_page) });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    const handleUploadAction = () => {
        setShowDialog(true);
    };

    const handleOpenDeleteDialog = (id: number) => {
        setTemplateId(id);
        setShowDeleteDialog(true);
    };

    const handleDeleteTemplate = async (templateId: number) => {
        setDeleteLoading(true);
        try {
            const response = await templateServices.deleteTemplateDoctorRole({ templateIds: [templateId] });
            if (response.success) {
                setReloadPageType(ActionSubject.DELETE);
                notify.success('Template successfully deleted.', 'Success');
            } else notify.error(response.message, 'Error');
        } catch (error) {
            console.log(error);
        } finally {
            setDeleteLoading(false);
            setShowDeleteDialog(false);
        }
    };

    const getHandleTableChange = (type: string, newState: any) => {
        const params = {
            page: newState.page || 1,
            pageSize: newState.sizePerPage || AppConstants.pagination.pageSize,
            searchValue: newState.searchText || '',
            sortBy: newState.sortField || 'createdAt',
            sortDirection: newState.sortOrder || 'desc',
        };
        if (type === 'search') {
            setIsSearch(true);
            return getData({ ...params, page: 1 });
        }
        getData(params);
    };

    useEffect(() => {
        if (reloadPageType !== ActionSubject.UNDEFINED) {
            getData(initialTableConfig);
            setShowDialog(false);
            setReloadPageType(ActionSubject.UNDEFINED);
        }
    }, [reloadPageType]);

    const goUploadPage = () => {
        setShowDialog(true);
    };

    useEffect(() => {
        getData(tableConfig);
    }, []);

    return (
        <>
            {listTemplate.length > 0 || isSearch ? (
                <div className="container">
                    <TemplateUploadDialog
                        isShowDialog={isShowDialog}
                        setShowDialog={setShowDialog}
                        setReloadPageType={setReloadPageType}
                    />
                    <SubHeaderPage title="Templates" />
                    <Table
                        tableClassName="padding-td"
                        tableConfig={tableConfig}
                        paginationOptions={paginationOptions}
                        data={listTemplate}
                        isDataLoading={isDataLoading}
                        getData={getData}
                        columns={renderColumns(handleOpenDeleteDialog)}
                        getHandleTableChange={getHandleTableChange}
                        handleUploadAction={handleUploadAction}
                        tableToolbar={
                            <TableToolbar textAddNewButton="Upload a Template" handleAction={handleUploadAction} />
                        }
                    />
                    <ConfirmModal
                        isOpen={isShowDeleteDialog}
                        setIsOpen={setShowDeleteDialog}
                        actionConfirm={() => handleDeleteTemplate(templateId)}
                        titleModal="Delete Templates"
                        textConfirm="Yes"
                        textReject="No"
                        className="delete-template-confirm-modal"
                        confirmButtonClassName="delete-button"
                        rejectButtonClassName="no-button"
                        isLoading={isDeleteLoading}
                    >
                        <p>Are you sure you want to delete this template?</p>
                        <p className="sub-text">If you delete this template, you will not be able to use it anymore.</p>
                    </ConfirmModal>
                </div>
            ) : (
                <div className="empty-page">
                    <div className="empty-element">
                        <img src={emptyTempalte} className="empty-template" alt="" />
                        <p>You can upload formatted files, layouts, and styles for the transcriptions.</p>
                        <button onClick={goUploadPage} className="upload-btn">
                            Upload a template
                        </button>
                    </div>
                </div>
            )}
            {isShowDialog && listTemplate.length === 0 && (
                <TemplateUploadDialog
                    isShowDialog={isShowDialog}
                    setShowDialog={setShowDialog}
                    setReloadPageType={setReloadPageType}
                />
            )}
        </>
    );
};

export default Template;
