import React from 'react';

import { sortCaret } from '../../../common/helpers/TableHelper';
import { AssigneeDTO } from '../../../common/services/apiTypes';
import { CustomRoleCell } from '../../../components/Table/components/CustomRoleCell/CustomRoleCell';

const roleFormatter = (cell: string, row: AssigneeDTO): JSX.Element => <CustomRoleCell value={cell} row={row} />;

export const renderColumns = () => [
    {
        dataField: 'lastName',
        text: 'Last Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'firstName',
        text: 'First Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'email',
        text: 'Email',
    },
    {
        dataField: 'userRole',
        text: 'Role',
        formatter: roleFormatter,
    },
];
