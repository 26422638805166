import axios from 'axios';

import { UNAUTHORIZED } from '../constants/app';
import { HttpStatusCode } from '../constants/enum';

import { storage, storageKey } from './storage';

export const baseURL = process.env.REACT_APP_API_ENDPOINT;

export const AxiosClient = axios.create({
    baseURL,
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
    },
});

AxiosClient.interceptors.request.use(
    async (config) => {
        let token = null;
        try {
            token = storage.getToken(storageKey.TOKEN);
        } catch (e) {
            console.log('Token Invalid', e);
        }
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

AxiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        throw Error(error);
    },
);

export const AxiosDoctorClient = axios.create({
    baseURL,
    timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
    },
});

AxiosDoctorClient.interceptors.request.use(
    async (config) => {
        let token = null;
        try {
            token = storage.getToken(storageKey.DOCTOR_TOKEN);
        } catch (e) {
            console.log('Token Invalid', e);
        }
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

AxiosDoctorClient.interceptors.response.use(
    (response) => {
        //* Check Doctor's status = 'Deactivated' => remove Token
        if (response.data?.code === HttpStatusCode.Unauthorized && response.data?.message === UNAUTHORIZED) {
            window.location.reload();
            storage.removeToken(storageKey.DOCTOR_TOKEN);
        }
        return response;
    },
    (error) => {
        throw Error(error);
    },
);
