import { DictationStatus } from '../constants/enum';
import { notify } from '../utils/notify';

export const showNotifyDictationChangeStatus = (value: string) => {
    switch (value) {
        case DictationStatus.InProgress:
            notify.success('Dictation moved to In Progress.', 'Success');
            break;
        case DictationStatus.Submitted:
            notify.success('Dictation moved to Submitted.', 'Success');
            break;
        case DictationStatus.Finished:
            notify.success('Dictation moved to Finished.', 'Success');
            break;
        case DictationStatus.Canceled:
            notify.success('Dictation moved to Cancelled.', 'Success');
            break;
        case DictationStatus.Unassign:
            notify.success('Dictation moved to Unassigned.', 'Success');
            break;
    }
};
const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    window.history.replaceState(null, '');
};

export const beforeUnloadCustomEvent = () => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
        window.removeEventListener('beforeunload', alertUser);
    };
};
