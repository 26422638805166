import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { DictationResourceType } from '../../../../common/services/apiTypes';
import { baseURL } from '../../../../common/utils/axiosClient';
import { sliceFileName } from '../../../../common/utils/converter';
import './ExpandRowContentStyles.scss';

interface Props {
    getResourceData: (dictationId: number, setResource: (resource: DictationResourceType) => void) => void;
    dictationId: number;
    listResourceFile: DictationResourceType[];
}
export const initDictationResource = {
    id: 0,
    remarks: '',
    records: [],
    attachments: [],
};

export const ExpandContent: React.FunctionComponent<Props> = ({
    getResourceData,
    dictationId,
    listResourceFile,
}): React.ReactElement => {
    const [resource, setResource] = useState<DictationResourceType>(initDictationResource);

    useEffect(() => {
        const item = listResourceFile.find((resource) => resource.id === dictationId);
        if (!item) {
            getResourceData(dictationId, setResource);
        } else {
            setResource(item);
        }
    }, []);

    return (
        <>
            {resource.id ? (
                <Row className="collapse-row">
                    <Col md={2} className="pr-0">
                        <p className="float-right font-weight-bold">Dictations:</p>
                    </Col>
                    <Col md={10}>
                        {resource.records?.length ? (
                            resource.records.map((item, index: number) => (
                                <Col className="p-0 mb-2" key={index}>
                                    <Button
                                        variant="light"
                                        className="py-1 mp3-template button-row"
                                        href={`${baseURL}/dictation/record/get-file/${item.fileName}`}
                                    >
                                        {sliceFileName(item.fileName)}
                                    </Button>
                                </Col>
                            ))
                        ) : (
                            <></>
                        )}
                    </Col>
                    <Col md={2} className="pr-0">
                        <p className="float-right font-weight-bold">Attachments:</p>
                    </Col>
                    <Col md={10}>
                        {resource.attachments?.length ? (
                            resource.attachments.map((item, index: number) => (
                                <Col className="p-0 mb-3" key={index}>
                                    <Button
                                        variant="light"
                                        className="py-1 button-row file-template"
                                        href={`${baseURL}/dictation/attachment/get-file/${item.id}`}
                                    >
                                        {sliceFileName(item.fileName)}
                                    </Button>
                                </Col>
                            ))
                        ) : (
                            <></>
                        )}
                    </Col>
                    <Col md={2} className="pr-0 mb-2">
                        <p className="mb-0 float-right font-weight-bold">Remarks:</p>
                    </Col>
                    <Col md={10}>
                        {resource.remarks?.length ? (
                            <p className="mb-0 text-extra-dictation">{resource.remarks}</p>
                        ) : (
                            <p className="mb-0 text-extra-dictation">Please send to my email when done, thanks.</p>
                        )}
                    </Col>
                </Row>
            ) : (
                <span className="ml-auto">...Loading</span>
            )}
        </>
    );
};
