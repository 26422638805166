import React from 'react';
import './CustomRoleCellStyles.scss';

import { AssigneeDTO } from '../../../../common/services/apiTypes';

interface ActionsCustomRoleCellProps {
    value: string;
    row: AssigneeDTO;
}

export const CustomRoleCell: React.FunctionComponent<ActionsCustomRoleCellProps> = ({
    value,
    row,
}): React.ReactElement => {
    return (
        <>
            <span>
                {value}
                {!row.isActivated && <span className="inactive-staff">&nbsp;(Inactivated)</span>}
            </span>
        </>
    );
};
