import React from 'react';

import { ActionSubject } from '../../../common/constants/enum';
import { sortCaret } from '../../../common/helpers/TableHelper';
import { AccountDTO } from '../../../common/services/apiTypes';
import {
    AccountActionCell,
    AccountActionType,
} from '../../../components/Table/components/AccountActionCell/AccountActionCell';
import { AccountStatusCell } from '../../../components/Table/components/AccountStatusCell/AccountStatusCell';

const accountStatusFormatter = (cell: string): JSX.Element => <AccountStatusCell value={cell} />;

const accountActionFormatter = (
    _cell: string,
    row: AccountDTO,
    _rowIndex: number,
    actions: AccountActionType,
): JSX.Element => <AccountActionCell row={row} actions={actions} />;

export const renderColumns = (isAdminRole: boolean, setReloadPageType: (action: ActionSubject) => void) => [
    {
        dataField: 'accountName',
        text: 'Account Name',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'returnEmail',
        text: 'Return Email',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
    },
    {
        dataField: 'returnEmailStatus',
        text: 'Return Email Status',
        formatter: accountStatusFormatter,
    },
    {
        dataField: 'accountStatus',
        text: 'Account Status',
        formatter: accountStatusFormatter,
    },
    isAdminRole
        ? {
              dataField: 'action',
              text: '',
              formatter: accountActionFormatter,
              formatExtraData: {
                  setReloadPageType: setReloadPageType,
              },
              classes: 'text-center icon-table',
              headerClasses: 'text-center',
              style: {
                  minWidth: '140px',
              },
              headerStyle: () => {
                  return { width: '7%' };
              },
          }
        : { dataField: 'action', text: '', hidden: true },
];
