import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import EyeIcon from '../../common/assets/images/eye.png';
import EyeSlashIcon from '../../common/assets/images/eye-slash.png';

import './InputFormStyles.scss';

export enum InputType {
    Text = 'text',
    Password = 'password',
}
interface InputFormProps {
    controlId: string;
    label: string;
    type?: InputType;
    value: string | number | undefined;
    name?: string;
    handleChange: (e: any) => void;
    formGroupStyle?: string;
    labelStyle?: string;
    setErrorMessageForm?: (message: string) => void;
    error?: string;
    touched?: boolean;
    placeholder?: string;
    isRequired?: boolean;
}

const InputForm: React.FunctionComponent<InputFormProps> = ({
    controlId,
    label,
    type = InputType.Text,
    value,
    name,
    formGroupStyle,
    labelStyle,
    handleChange,
    setErrorMessageForm,
    error,
    touched,
    placeholder,
    isRequired,
}): React.ReactElement => {
    const [isShowPassword, setIsShowPassword] = useState(type === InputType.Password);
    const inputType = isShowPassword ? InputType.Password : InputType.Text;

    return (
        <Form.Group className={formGroupStyle} controlId={controlId}>
            <Form.Label className={`${labelStyle} text-color-steel-blue`}>
                {label}
                {isRequired && <span className="text-danger">*</span>}
            </Form.Label>
            <div className="form-input">
                <Form.Control
                    type={inputType}
                    name={name}
                    onChange={(e) => {
                        setErrorMessageForm && setErrorMessageForm('');
                        handleChange(e);
                    }}
                    value={value}
                    className={error ? 'error' : ''}
                    placeholder={placeholder}
                />
                {type === InputType.Password && (
                    <div
                        className={`${isShowPassword ? 'eye-slash-icon' : 'eye-icon'} password-icon`}
                        onClick={() => setIsShowPassword(!isShowPassword)}
                    >
                        <img src={isShowPassword ? EyeSlashIcon : EyeIcon} alt="" />
                    </div>
                )}
            </div>
            {touched !== undefined
                ? touched && error && <Form.Text className="text-danger">{error}</Form.Text>
                : error && <Form.Text className="text-danger">{error}</Form.Text>}
        </Form.Group>
    );
};

export default InputForm;
