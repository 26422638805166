import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { SignUpFields } from '../../../../common/services/apiTypes';
import InputForm from '../../../../components/InputForm/InputForm';
import appServices from '../../../../common/services/appServices';
import { notify } from '../../../../common/utils/notify';
import { ActionSubject, UserRole } from '../../../../common/constants/enum';
import './UserActionDialogStyles.scss';
import DropdownForm, { OptionSelectType } from '../../../../components/DropdownForm/DropdownForm';
import { GlobalState } from '../../../../redux';

interface Props {
    isShowDialog: boolean;
    setShowDialog: (show: boolean) => void;
    action: ActionSubject;
    setReloadPageType: (action: ActionSubject) => void;
}

export interface UserInviteFields
    extends Omit<SignUpFields, 'firstName' | 'lastName' | 'password' | 'registrationCode' | 'turnAroundTimeId'> {
    turnAroundTimeId: string;
    userRole: string;
}

const initialUser: UserInviteFields = {
    userRole: UserRole.Doctor,
    accountId: '',
    email: '',
    turnAroundTimeId: '',
    name: '',
};

export const UserActionDialog: React.FunctionComponent<Props> = ({
    isShowDialog,
    setShowDialog,
    action,
    setReloadPageType,
}) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const turnaroundTime = useSelector((state: GlobalState) => state.settings.turnAroundTime.list);
    const listAccountActive = useSelector((state: GlobalState) => state.account.listAccountActive);

    const listTAT: OptionSelectType[] = turnaroundTime.map((item) => {
        return {
            text: item.value,
            value: item.id,
        };
    });

    const listAccount: OptionSelectType[] = listAccountActive.map((item) => {
        return {
            text: item.accountName,
            value: item.id.toString(),
        };
    });

    const formAddUserSchema = Yup.object().shape({
        accountId: Yup.string().trim().required('This field is required'),
        turnAroundTimeId: Yup.string().trim().required('This field is required'),
        name: Yup.string()
            .trim()
            .required('This field is required')
            .max(50, 'Username must be less than 50 characters'),
        email: Yup.string()
            .trim()
            .required('This field is required')
            .email('Please enter a valid email address')
            .max(70, 'Email length must be less than or equal to 70 characters long'),
    });

    const handleSubmitForm = async (values: UserInviteFields) => {
        try {
            setLoading(true);
            values.name = values.name.trim();
            values.email = values.email.trim();
            if (action === ActionSubject.ADD) {
                const response = await appServices.inviteUser(values);
                if (response.success) {
                    setReloadPageType(ActionSubject.ADD);
                    setShowDialog(false);
                    notify.success('User successfully added.', 'Success');
                } else {
                    notify.error(response.message, 'Error');
                }
            }
        } catch (error) {
            notify.error(error.message, 'Error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-center"
            centered
            show={isShowDialog}
            onHide={() => setShowDialog(false)}
        >
            <Modal.Header closeButton className="model-add">
                <Modal.Title id="contained-modal-title-center">Invite Client User</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialUser}
                validateOnChange
                validationSchema={formAddUserSchema}
                enableReinitialize
                onSubmit={(values) => {
                    if (isLoading) {
                        return;
                    }
                    handleSubmitForm(values);
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form
                        className="my-2"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    >
                        <Modal.Body>
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            <DropdownForm
                                controlId="formAccountId"
                                label="Account"
                                handleChange={handleChange}
                                name="accountId"
                                value={values.accountId}
                                labelStyle="label-add-assignee mb-1"
                                error={(touched.accountId && errors.accountId) || ''}
                                defaultText="Select an Account"
                                listOption={listAccount}
                                setErrorMessageForm={setErrorMessage}
                            />
                            <DropdownForm
                                controlId="formTurnAroundTime"
                                label="Turnaround Time"
                                handleChange={handleChange}
                                name="turnAroundTimeId"
                                value={values.turnAroundTimeId}
                                labelStyle="label-add-assignee mb-1"
                                error={(touched.turnAroundTimeId && errors.turnAroundTimeId) || ''}
                                defaultText="Select a TAT"
                                listOption={listTAT}
                                setErrorMessageForm={setErrorMessage}
                            />
                            <InputForm
                                controlId="formName"
                                label="Name"
                                handleChange={handleChange}
                                name="name"
                                value={values.name}
                                labelStyle="label-add-assignee mb-1"
                                error={(touched.name && errors.name) || ''}
                                setErrorMessageForm={setErrorMessage}
                            />
                            <InputForm
                                controlId="formEmail"
                                label="Email"
                                handleChange={handleChange}
                                name="email"
                                value={values.email}
                                labelStyle="label-add-assignee mb-1"
                                error={(touched.email && errors.email) || ''}
                                setErrorMessageForm={setErrorMessage}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-primary cancel-button"
                                onClick={() => setShowDialog(false)}
                                className="px-4 py-1"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="px-4 py-1 save-button"
                                onClick={() => handleSubmit()}
                                disabled={isLoading}
                            >
                                Invite
                                {isLoading && (
                                    <span className="ml-2">
                                        <Spinner animation="border" size="sm" variant="light" />
                                    </span>
                                )}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
