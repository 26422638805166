import React from 'react';
import { PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';

export const PaginationToolbar = (props: any) => {
    const { paginationProps } = props;
    const { sizePerPageList, sizePerPage, totalSize, onSizePerPageChange } = paginationProps;

    const style = {
        width: '75px',
    };

    const onSizeChange = (event: any) => {
        const newSize = +event.target.value;
        onSizePerPageChange(newSize);
    };

    return (
        <div className="d-flex align-items-center py-3">
            <select
                disabled={totalSize === 0}
                className={`form-control form-control-sm text-primary font-weight-bold mr-3 bg-light ${
                    totalSize === 0 && 'disabled'
                }`}
                onChange={onSizeChange}
                value={sizePerPage}
                style={style}
            >
                {sizePerPageList.map((option: { text: string; value: number }) => {
                    const isSelect = sizePerPage === option.value;
                    return (
                        <option key={option.text} value={option.value} className={`btn ${isSelect ? 'active' : ''}`}>
                            {option.text}
                        </option>
                    );
                })}
            </select>
            <PaginationTotalStandalone className="text-muted" {...paginationProps} />
        </div>
    );
};
