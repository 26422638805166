import {
    GET_LIST_ACCOUNT_ACTIVE,
    GET_LIST_ACCOUNT_ACTIVE_FAILURE,
    GET_LIST_ACCOUNT_ACTIVE_SUCCESS,
    AccountType,
    getListAccountActiveAction,
    AccountActiveType,
} from './actionTypes';

export const getListAccountActive = (): getListAccountActiveAction => {
    return {
        type: GET_LIST_ACCOUNT_ACTIVE,
    };
};

export const getListAccountActiveSuccess = (payload: AccountActiveType[]): AccountType => {
    return {
        type: GET_LIST_ACCOUNT_ACTIVE_SUCCESS,
        payload,
    };
};

export const getListAccountActiveFailure = (): AccountType => {
    return {
        type: GET_LIST_ACCOUNT_ACTIVE_FAILURE,
    };
};
