import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';

import { ActionSubject } from '../../../../../common/constants/enum';
import { notify } from '../../../../../common/utils/notify';
import InputForm from '../../../../../components/InputForm/InputForm';
import templateServices from '../../../../../common/services/templateService';
import trashIcon from '../../../../../common/assets/images/trashIcon2.png';
import { validateFile } from '../../../../../common/helpers/ValidateFileHelper';
import { MAX_SIZE_DOCTOR_ATTACHMENT_UPLOAD } from '../../../../../common/constants/app';
import '../../TemplateStyles.scss';
interface Props {
    isShowDialog: boolean;
    setShowDialog: (show: boolean) => void;
    setReloadPageType: (action: ActionSubject) => void;
}

export interface TemplateUploadType {
    templateName: string;
    file: File | undefined;
}

export const TemplateUploadDialog: React.FunctionComponent<Props> = ({
    isShowDialog,
    setShowDialog,
    setReloadPageType,
}) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [errorMessageFile, setErrorMessageFile] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [previewFiles, setPreviewFiles] = useState<File | undefined>(undefined);

    const initialTemplate: TemplateUploadType = {
        templateName: '',
        file: undefined,
    };

    const formUploadTemplateSchema = Yup.object().shape({
        templateName: Yup.string()
            .trim()
            .required('This field is required')
            .max(50, 'Length must be less than 50 digits'),
    });

    const handleSubmitForm = async (values: TemplateUploadType) => {
        try {
            setLoading(true);
            values.templateName = values.templateName.trim();
            const response = await templateServices.uploadTemplateDoctorRole(values);
            if (response.success) {
                setReloadPageType(ActionSubject.UPLOAD);
                setShowDialog(false);
                notify.success('Upload new template success', 'Success');
            } else {
                setErrorMessage(response.message);
            }
        } catch (error: any) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeTemplateFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length) {
            const validFiles = ['txt', 'doc', 'docx'];
            const error = validateFile(files[0], validFiles, MAX_SIZE_DOCTOR_ATTACHMENT_UPLOAD);
            if (error) {
                setErrorMessageFile(error);
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.addEventListener('load', (e: any) => {
                e.preventDefault();
                setPreviewFiles(files[0]);
                setErrorMessageFile('');
                event.target.value = '';
            });
        }
    };

    const handleDeleteFile = () => {
        setPreviewFiles(undefined);
    };

    useEffect(() => {
        setPreviewFiles(undefined);
        setErrorMessageFile('');
        setErrorMessage('');
    }, [isShowDialog]);

    return (
        <Modal
            aria-labelledby="contained-modal-title-center"
            centered
            show={isShowDialog}
            onHide={() => setShowDialog(false)}
        >
            <Modal.Header className="model-add text-primary bg-white">
                <Modal.Title id="contained-modal-title-center text-26">Upload new template</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialTemplate}
                validateOnChange
                validationSchema={formUploadTemplateSchema}
                enableReinitialize
                onSubmit={(values) => {
                    if (!previewFiles) {
                        setErrorMessageFile('Template File is required');
                        return;
                    }
                    if (isLoading) {
                        return;
                    }
                    values.file = previewFiles;
                    handleSubmitForm(values);
                }}
            >
                {({ handleSubmit, handleChange, values, errors }) => (
                    <Form
                        className="my-2"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    >
                        <Form.Text className="text-body">
                            Accept Attachment file format are .doc, .docx and .txt
                        </Form.Text>

                        <Modal.Body>
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                            <InputForm
                                isRequired
                                controlId="formTemplateName"
                                label="Template name"
                                handleChange={handleChange}
                                name="templateName"
                                value={values.templateName}
                                labelStyle="label-add-assignee text-color-primary mb-2"
                                error={errors.templateName}
                                setErrorMessageForm={setErrorMessage}
                            />
                            <Form.Group controlId="formTemplateFile">
                                <Form.Label className="label-add-assignee mb-2 mt-3">
                                    Template File <span className="text-danger">&nbsp;*</span>
                                </Form.Label>
                                <div className={`input-template-file`}>
                                    <label htmlFor="input-template-file">Browse Files</label>
                                    <input
                                        type="file"
                                        accept=".txt,.doc,.docx"
                                        id="input-template-file"
                                        onChange={(e) => {
                                            handleChangeTemplateFile(e);
                                        }}
                                    />
                                </div>
                                {errorMessageFile && <Form.Text className="text-danger">{errorMessageFile}</Form.Text>}
                            </Form.Group>
                            {previewFiles ? (
                                <div className="py-3 border-top border-bottom d-flex justify-content-between align-middle">
                                    <div className="py-1 file-template-upload">{previewFiles.name}</div>
                                    <a className="delete-icon d-flex align-middle" onClick={() => handleDeleteFile()}>
                                        <img src={trashIcon} alt="" />
                                    </a>
                                </div>
                            ) : (
                                <></>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-primary cancel-button"
                                onClick={() => setShowDialog(false)}
                                className="border cancel-button border-0"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="save-button border border-0"
                                onClick={() => handleSubmit()}
                                disabled={isLoading}
                            >
                                Save
                                {isLoading && (
                                    <span className="ml-2">
                                        <Spinner animation="border" size="sm" variant="light" />
                                    </span>
                                )}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
