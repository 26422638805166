import React, { useEffect, useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';

import currentTranscriptionsIcon from '../../../../common/assets/images/currentTranscriptionsIcon.png';
import finishedTranscriptionsIcon from '../../../../common/assets/images/finishedTranscriptionsIcon.png';
import registerAccountsIcon from '../../../../common/assets/images/registerAccountsIcon.png';
import { ActionSubject } from '../../../../common/constants/enum';
import { StatisticType } from '../../../../common/services/apiTypes';
import appServices from '../../../../common/services/appServices';

import './StatisticStyles.scss';

const initialStatisticData: StatisticType = {
    countEditor: 0,
    countClient: 0,
    countDictationUnassign: 0,
    countDictationInprogress: 0,
    countDictationSubmitted: 0,
    countDictationFinishedToday: 0,
    countDictationFinishedThisMonth: 0,
};

interface Props {
    reloadPageType: ActionSubject;
}

const Statistic: React.FC<Props> = ({ reloadPageType }): React.ReactElement => {
    const [isDataLoading, setDataLoading] = useState(false);
    const [statisticData, setStatisticData] = useState<StatisticType>(initialStatisticData);

    const getStatisticData = async () => {
        setDataLoading(true);
        try {
            const timezoneHrs = -(new Date().getTimezoneOffset() / 60);
            const response = await appServices.getDashboard({
                timezoneOffset: timezoneHrs,
            });
            if (response.success) {
                setStatisticData(response.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setDataLoading(false);
        }
    };

    useEffect(() => {
        getStatisticData();
    }, []);

    useEffect(() => {
        if (reloadPageType !== ActionSubject.UNDEFINED) {
            getStatisticData();
        }
    }, [reloadPageType]);

    return (
        <div className="statistic">
            <div className="statistic__item start-div">
                <div className="title">
                    <img src={registerAccountsIcon} alt="" />
                    Register Accounts
                </div>
                <div className="content justify-content-around">
                    <Col className="content__container px-0">
                        <div className="content__number orange-number">
                            {isDataLoading ? (
                                <Spinner animation="border" className="my-2" />
                            ) : (
                                statisticData.countClient
                            )}
                        </div>
                        <span className="content__text">Client Users</span>
                    </Col>
                    <Col className="content__container px-0">
                        <div className="content__number bd-left">
                            {isDataLoading ? (
                                <Spinner animation="border" className="my-2" />
                            ) : (
                                statisticData.countEditor
                            )}
                        </div>
                        <span className="content__text">Editors</span>
                    </Col>
                </div>
            </div>
            <div className="statistic__item middle-div">
                <div className="title">
                    <img src={currentTranscriptionsIcon} alt="" />
                    Current Transcriptions
                </div>
                <div className="content justify-content-around">
                    <Col className="content__container px-0">
                        <div className="content__number orange-number">
                            {isDataLoading ? (
                                <Spinner animation="border" className="my-2" />
                            ) : (
                                statisticData.countDictationUnassign
                            )}
                        </div>
                        <span className="content__text">Unassigned</span>
                    </Col>
                    <Col className="content__container px-0">
                        <div className="content__number bd-left bd-right">
                            {isDataLoading ? (
                                <Spinner animation="border" className="my-2" />
                            ) : (
                                statisticData.countDictationInprogress
                            )}
                        </div>
                        <span className="content__text">In Progress</span>
                    </Col>
                    <Col className="content__container px-0">
                        <div className="content__number orange-number">
                            {isDataLoading ? (
                                <Spinner animation="border" className="my-2" />
                            ) : (
                                statisticData.countDictationSubmitted
                            )}
                        </div>
                        <span className="content__text">Submitted</span>
                    </Col>
                </div>
            </div>
            <div className="statistic__item start-div">
                <div className="title">
                    <img src={finishedTranscriptionsIcon} alt="" />
                    Finished Transcriptions
                </div>
                <div className="content justify-content-around">
                    <Col className="content__container px-0">
                        <div className="content__number">
                            {isDataLoading ? (
                                <Spinner animation="border" className="my-2" />
                            ) : (
                                statisticData.countDictationFinishedToday
                            )}
                        </div>
                        <span className="content__text">Today</span>
                    </Col>
                    <Col className="content__container px-0">
                        <div className="content__number bd-left orange-number">
                            {isDataLoading ? (
                                <Spinner animation="border" className="my-2" />
                            ) : (
                                statisticData.countDictationFinishedThisMonth
                            )}
                        </div>
                        <span className="content__text">This Month</span>
                    </Col>
                </div>
            </div>
        </div>
    );
};

export default Statistic;
