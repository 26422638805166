import { LoginStatus } from '../auth/actionTypes';

import { DOCTOR_LOGIN, DOCTOR_LOGIN_SUCCESS, DOCTOR_LOGIN_FAILURE, DOCTOR_RESET, DoctorLoginType } from './actionTypes';

export interface AuthState {
    token: string;
    isLoggingIn: boolean;
    isLoggedIn: boolean;
    loginMessage: string | undefined;
    status: LoginStatus | undefined;
}

const initialState: AuthState = {
    token: '',
    isLoggingIn: false,
    isLoggedIn: false,
    loginMessage: undefined,
    status: undefined,
};

const reducer = (state = initialState, action: DoctorLoginType): AuthState => {
    switch (action.type) {
        case DOCTOR_LOGIN:
            return {
                ...state,
                isLoggingIn: true,
            };
        case DOCTOR_LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                status: LoginStatus.success,
                isLoggedIn: true,
                loginMessage: undefined,
                isLoggingIn: false,
            };
        case DOCTOR_LOGIN_FAILURE:
            return {
                ...state,
                isLoggingIn: false,
                status: LoginStatus.failure,
                loginMessage: action.payload.loginMessage,
            };
        case DOCTOR_RESET:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
