import React from 'react';

import { VerificationPage } from '../../../../common/constants/enum';
import { getVerificationSuccessMessage, getVerificationSuccessTitle } from '../../verification/VerificationHelper';

interface ResetPasswordSuccessProps {
    page: VerificationPage;
}

const ResetPasswordSuccess: React.FunctionComponent<ResetPasswordSuccessProps> = ({ page }) => {
    return (
        <div className="text-center">
            <p className="text-color-orange mt-3 mb-2 paragraph-main">{getVerificationSuccessTitle(page)}</p>
            <p>{getVerificationSuccessMessage(page)}</p>
        </div>
    );
};

export default ResetPasswordSuccess;
