import { AxiosDoctorClient } from '../utils/axiosClient';
import API from '../constants/api';

import { ResponseType } from './apiTypes';

const doctorDictationsServices = {
    createDictation: async (params: {
        remarks: string;
        status: string;
        userId: number;
    }): Promise<ResponseType<{ dictation: { id: number } }>> => {
        const { data } = await AxiosDoctorClient.post(API.DOCTOR_DICTATION.CREATE_DICTATION, params);
        return data;
    },
    uploadAudio: async (params: { file: File; dictationId: string }): Promise<ResponseType<any>> => {
        const formData = new FormData();
        formData.append('fileName', params.file.name);
        formData.append('dictationId', params.dictationId);
        formData.append('duration', params.file.size + '');
        formData.append('dateRecorded', params.file.lastModified + '');
        formData.append('file', params.file);
        const { data } = await AxiosDoctorClient.post(API.DOCTOR_DICTATION.UPLOAD_AUDIO, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data;
    },
    uploadAttachment: async (params: { file: File; dictationId: string }): Promise<ResponseType<any>> => {
        const formData = new FormData();
        formData.append('fileName', params.file.name);
        formData.append('file', params.file);
        const { data } = await AxiosDoctorClient.post(
            `${API.DOCTOR_DICTATION.UPLOAD_ATTACHMENT}/${params.dictationId}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
        return data;
    },
    uploadSuccessFiles: async (params: { files: string[]; dictationId: number }): Promise<ResponseType<any>> => {
        const { data } = await AxiosDoctorClient.post(API.DOCTOR_DICTATION.UPLOAD_SUCCESS_FILES, params);
        return data;
    },
};

export default doctorDictationsServices;
