import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SubHeaderPage from '../../../components/SubHeaderPage/SubHeaderPage';
import Table, { PaginationOptions } from '../../../components/Table/Table';
import { getStatistic } from '../../../redux/home/actions';
import TableToolbar, { ToolbarFilter } from '../../../components/Table/components/TableToolbar/TableToolbar';
import { GlobalState } from '../../../redux';
import { getTurnAroundTimes } from '../../../redux/settings/action';
import { ExpandContent } from '../../../components/Table/components/ExpandRowContent/ExpandRowContent';
import appServices from '../../../common/services/appServices';
import { DictationResourceType, DictationResponse } from '../../../common/services/apiTypes';
import { DictationTableConfig } from '../dictations-in-progress/DictationsInProgress';
import { ActionSubject, DictationStatus, SortType } from '../../../common/constants/enum';
import AppConstants from '../../../common/constants/app';
import { formatDate } from '../../../common/helpers/DateFormatHelper';
import { beforeUnloadCustomEvent, showNotifyDictationChangeStatus } from '../../../common/helpers/NotifyHelper';
import { notify } from '../../../common/utils/notify';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import { tableConfigGettingTAT } from '../../../common/helpers/TableHelper';
import dictationsServices from '../../../common/services/dictationsServices';
import { getListAccountActive } from '../../../redux/account/action';

import { renderColumns } from './HomeHelper';
import Statistic from './components/Statistic';

const initialTableConfig: DictationTableConfig = {
    page: 1,
    pageSize: AppConstants.pagination.pageSize,
    searchValue: '',
    sortBy: 'createdAt',
    sortDirection: SortType.DESC,
    status: DictationStatus.Unassign,
    turnAroundTime: '',
    accountId: '',
    includeEditorInCharge: true,
};

const Home: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const history = useHistory<{ tableConfigRemember: DictationTableConfig }>();
    const [tableConfig, setTableConfig] = useState<DictationTableConfig>(
        history.location.state?.tableConfigRemember ? history.location.state.tableConfigRemember : initialTableConfig,
    );
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [totalSize, setTotalSize] = useState<number>(0);
    const turnAroundTime = useSelector((state: GlobalState) => state.settings.turnAroundTime.list);
    const [listDictation, setListDictation] = useState<DictationResponse[]>([]);
    const [listResourceFile, setListResourceFile] = useState<DictationResourceType[]>([]);
    const [itemTATFilter, setItemTATFilter] = useState<string>('');
    const [reloadPageType, setReloadPageType] = useState<ActionSubject>(ActionSubject.UNDEFINED);
    const [dictationSelected, setDictationSelected] = useState(0);
    const [itemAccountFilter, setItemAccountFilter] = useState<string>('');
    const [isAddAccountLoading, setIsAddAccountLoading] = useState<boolean>(false);
    const [dictationIdSelect, setDictationIdSelect] = useState<number>(0);
    const { userInfo } = useSelector((state: GlobalState) => state.user);
    const listAccountActive = useSelector((state: GlobalState) => state.account.listAccountActive);
    const listAccount: ToolbarFilter[] = listAccountActive.map((item) => {
        return {
            value: item.accountName,
            id: item.id.toString(),
        };
    });

    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: totalSize,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    const getData = async (tableConfigProps: DictationTableConfig) => {
        setIsDataLoading(true);
        try {
            const response = await appServices.getListDictation(tableConfigProps);
            if (response.success) {
                const listFormatDate = response.data.list.map((item) => ({
                    ...item,
                    createdAt: formatDate(item.createdAt),
                    userName: item.user.name,
                }));
                setListDictation(listFormatDate);
                setTableConfig({ ...tableConfigProps, page: parseInt(response.data.current_page) });
                setTotalSize(response.data.total);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    const getHandleTableChange = (type: string, newState: any) => {
        const params: DictationTableConfig = {
            page: newState.page || tableConfig.page,
            pageSize: newState.sizePerPage || tableConfig.pageSize,
            searchValue: newState.searchText || tableConfig.searchValue,
            sortBy: newState.sortField || tableConfig.sortBy,
            sortDirection: newState.sortOrder || tableConfig.sortDirection,
            status: tableConfig.status,
            turnAroundTime: tableConfig.turnAroundTime,
            accountId: tableConfig.accountId,
            includeEditorInCharge: true,
        };
        if (params.sortBy === 'userName') {
            params.sortBy = 'name';
        }
        if (type === 'search') {
            if (!newState.searchText.trim()) return getData({ ...params, page: 1, searchValue: '' });
            return getData({ ...params, page: 1 });
        }
        getData(params);
    };

    const getResourceData = async (dictationId: number, setResource: (resource: DictationResourceType) => void) => {
        try {
            const response = await appServices.getDictationResource(dictationId);
            if (response.success) {
                setListResourceFile([...listResourceFile, response.data]);
                setResource(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSelected = async (value: string, id: number) => {
        if (value === DictationStatus.Canceled) {
            if (!dictationSelected) {
                setDictationSelected(id);
                return;
            } else {
                handleUpdateStatus(value, id);
            }
        } else {
            handleUpdateStatus(value, id);
        }
    };

    const handleAddAccount = async (id: number) => {
        setDictationIdSelect(id);
        setIsAddAccountLoading(true);
        try {
            const response = await dictationsServices.addEditorInCharge({
                dictationId: id,
                userId: userInfo.id,
            });
            if (response.success) {
                setReloadPageType(ActionSubject.UPDATE);
                notify.success('Editor-In-Charge successfully added.', 'Success');
            } else notify.error(response.message, 'Error');
        } catch (error) {
            console.log(error);
        } finally {
            setDictationIdSelect(0);
            setIsAddAccountLoading(false);
        }
    };

    const handleUpdateStatus = async (value: string, id: number) => {
        try {
            const response = await appServices.updateDictationStatus({ status: value }, id);
            if (response.success) {
                setDictationSelected(0);
                setReloadPageType(ActionSubject.UPDATE);
                showNotifyDictationChangeStatus(value);
            } else notify.error(response.message, 'Error');
        } catch (error) {
            console.log(error);
        }
    };
    const renderExpandContent = (row: any) => {
        return (
            <ExpandContent getResourceData={getResourceData} dictationId={row.id} listResourceFile={listResourceFile} />
        );
    };

    const expandRow = {
        renderer: (row: any) => renderExpandContent(row),
    };

    const tableFilter =
        (turnAroundTime?.length && turnAroundTime.map((t) => ({ id: parseInt(t.id), value: t.value }))) || [];

    useEffect(() => {
        dispatch(getStatistic());
        dispatch(getTurnAroundTimes(tableConfigGettingTAT));
        dispatch(getListAccountActive());
        beforeUnloadCustomEvent();
    }, []);

    useEffect(() => {
        if (!userInfo) setReloadPageType(ActionSubject.UPDATE);
    }, [userInfo]);

    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        getData({ ...tableConfig, page: 1, accountId: itemAccountFilter, turnAroundTime: itemTATFilter });
    }, [itemTATFilter, itemAccountFilter]);

    useEffect(() => {
        if (reloadPageType !== ActionSubject.UNDEFINED) {
            getData(initialTableConfig);
            setReloadPageType(ActionSubject.UNDEFINED);
        }
    }, [reloadPageType]);

    const defaultSorted = [
        {
            dataField: tableConfig.sortBy,
            order: tableConfig.sortDirection,
        },
    ];

    return (
        <div>
            <SubHeaderPage title="Home" />
            <Statistic reloadPageType={reloadPageType} />
            <SubHeaderPage title="Recent Uploads" />
            <Table
                tableConfig={tableConfig}
                paginationOptions={paginationOptions}
                data={listDictation}
                isDataLoading={isDataLoading}
                getData={getData}
                columns={renderColumns(
                    handleSelected,
                    handleAddAccount,
                    tableConfig,
                    isAddAccountLoading,
                    dictationIdSelect,
                    userInfo,
                )}
                getHandleTableChange={getHandleTableChange}
                expandRow={expandRow}
                rowClasses="item-table"
                defaultInputSearch={tableConfig.searchValue}
                defaultSorted={defaultSorted}
                tableToolbar={
                    <>
                        <TableToolbar
                            isShowFilterGroup
                            listFilter={listAccount}
                            setFilterValue={setItemAccountFilter}
                            placeholderSelect="Account"
                            defaultValueFilter={tableConfig.accountId}
                        />
                        <TableToolbar
                            isShowFilterGroup
                            listFilter={tableFilter}
                            setFilterValue={setItemTATFilter}
                            placeholderSelect="TAT"
                            defaultValueFilter={tableConfig.turnAroundTime}
                        />
                    </>
                }
            />
            <ConfirmModal
                className="turn-around-time-confirm-modal"
                isOpen={dictationSelected}
                setIsOpen={setDictationSelected}
                actionConfirm={() => handleSelected(DictationStatus.Canceled, dictationSelected)}
                titleModal="Warning"
                textConfirm="Yes"
                textReject="No"
                confirmButtonClassName="delete-button"
            >
                <p>
                    Dictation will be moved to Historical Records. You will not be able to work on this dictation
                    anymore. Continue?
                </p>
            </ConfirmModal>
        </div>
    );
};

export default Home;
