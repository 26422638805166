export const route = {
    ROOT: '/editor',
    HOME: '/editor/home',
    DICTATIONS_UNASSIGNED_DETAIL: '/editor/home/dictations-unassigned/:dictationId',
    DICTATIONS_IN_PROGRESS: '/editor/dictations-inprogress',
    DICTATIONS_IN_PROGRESS_DETAIL: '/editor/dictations-inprogress/:dictationId',
    DICTATIONS_SUBMITTED: '/editor/dictations-submitted',
    DICTATIONS_SUBMITTED_DETAIL: '/editor/dictations-submitted/:dictationId',
    HISTORICAL_RECORD: '/editor/historical-record',
    HISTORICAL_RECORD_DETAIL: '/editor/historical-record/:dictationId',
    USERS: '/editor/users',
    TEMPLATE: '/editor/users/:id',
    SETTINGS_TURN_AROUND_TIME: '/editor/settings-turn-around-time',
    SETTINGS_ASSIGNEE: '/editor/settings-staff',
    SETTINGS_ACCOUNT: '/editor/settings-account',
    LOGIN: '/editor/login',
    FORGOT_PASSWORD: '/editor/forgot-password',
    CHANGE_PASSWORD: '/editor/change-password',
    CHANGE_PASSWORD_FIRST_TIME: '/editor/change-password-first-time',
    VERIFICATION: '/editor/verification-code',
    RESET_PASSWORD: '/editor/reset-password',
    APP: '/app',
};
export const doctorRoute = {
    WELCOME: '/doctor/welcome',
    SIGN_IN: '/doctor/sign-in',
    SIGN_UP: '/doctor/sign-up',
    FORGOT_PASS_CODE: '/doctor/forgot-pass-code',
    VERIFICATION: '/doctor/verification-code',
    UPLOAD_DICTATION: '/doctor/upload-dictation',
    RESET_PASS_CODE: '/doctor/reset-pass-code',
    CHANGE_PASS_CODE: '/doctor/change-pass-code',
    REGISTRATION: '/doctor/registration',
    HOME: '/doctor/home',
    FINISHED_TRANSCRIPTIONS: '/doctor/finished-transcriptions',
    FINISHED_TRANSCRIPTIONS_FILES_LIST: '/doctor/finished-transcriptions/:id',
    TRANSCRIPTION_ONLY_LIST: '/doctor/transcription-only-list',
    TEMPLATES: '/doctor/templates',
    CHANGE_PASSWORD_FIRST_TIME: '/doctor/change-password-first-time',
    ROOT: '/doctor',
};
