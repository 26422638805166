import React from 'react';
import { useHistory } from 'react-router-dom';

import backIcon from '../../common/assets/images/backIcon.png';
import { DictationTableConfig } from '../../pages/editor-pages/dictations-in-progress/DictationsInProgress';

import './SubHeaderPageStyles.scss';

interface AdminSubHeaderPageProps {
    title: string;
    isBackTitle?: boolean;
    backUrl?: string;
    tableConfigRemember?: DictationTableConfig;
}

const SubHeaderPage: React.FunctionComponent<AdminSubHeaderPageProps> = ({
    title,
    isBackTitle,
    backUrl,
    tableConfigRemember,
}) => {
    const history = useHistory();
    const { pathname } = location;
    const isEditorRole = pathname.includes('editor' + '/');
    return (
        <div className="d-sm-flex align-items-center justify-content-between py-4 subHeader">
            {isBackTitle ? (
                <a
                    onClick={() =>
                        backUrl ? history.push(backUrl, { tableConfigRemember: tableConfigRemember }) : history.goBack()
                    }
                    className="back-tittle d-flex align-items-center"
                >
                    {isEditorRole && <img className="mr-3 back-icon" src={backIcon} alt="" />}
                    <h2 className="h2 mb-0 title-page text-primary text-size">{title}</h2>
                </a>
            ) : (
                <h2 className="h2 mb-0 title-page text-primary text-size">{title}</h2>
            )}
        </div>
    );
};

export default SubHeaderPage;
