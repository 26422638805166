import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';

import './ConfirmModalStyles.scss';

interface ConfirmModalProps {
    isOpen: string | boolean | number;
    setIsOpen: (params: any) => void;
    actionConfirm?: () => void;
    actionCancel?: () => void;
    titleModal: string;
    textConfirm: string;
    textReject?: string;
    confirmButtonClassName?: string;
    rejectButtonClassName?: string;
    className?: string;
    isLoading?: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
    titleModal,
    isOpen,
    setIsOpen,
    actionConfirm,
    actionCancel,
    children,
    textConfirm,
    textReject,
    confirmButtonClassName,
    rejectButtonClassName,
    className,
    isLoading,
}): React.ReactElement => {
    return (
        <Modal
            size="lg"
            className={`confirm-modal-container ${className}`}
            show={!!isOpen}
            onHide={actionCancel ? actionCancel : () => setIsOpen('')}
            centered
        >
            <Modal.Header>
                <Modal.Title>{titleModal}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button
                    className={`cancel-button ${rejectButtonClassName}`}
                    onClick={() => {
                        setIsOpen('');
                        actionCancel && actionCancel();
                    }}
                >
                    {textReject ? textReject : 'Cancel'}
                </Button>
                <Button disabled={isLoading} className={confirmButtonClassName} onClick={actionConfirm}>
                    {textConfirm}
                    {isLoading ? (
                        <span className="ml-3">
                            <Spinner animation="border" size="sm" />
                        </span>
                    ) : null}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
