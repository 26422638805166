import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

import { MAX_SIZE_DOCTOR_ATTACHMENT_UPLOAD, MAX_SIZE_DOCTOR_AUDIO_UPLOAD } from '../../../../../common/constants/app';
import fileIcon from '../../../../../common/assets/images/fileIcon.png';
import audioFile from '../../../../../common/assets/images/audio-file.png';
import attachmentFile from '../../../../../common/assets/images/attachment-file.png';
import mp3Icon from '../../../../../common/assets/images/mp3Icon.png';
import trashIcon2 from '../../../../../common/assets/images/trashIcon2.png';
import ConfirmModal from '../../../../../components/ConfirmModal/ConfirmModal';

import './FilesContainerStyles.scss';

export enum FileType {
    audio = 'Audio',
    attachment = 'Attachment',
}

interface FilesContainerProps {
    title: FileType;
    files: File[];
    setFiles: (files: File[]) => void;
    isUpload?: boolean;
    isLoading: boolean;
}

const audioValidExtension = ['wav', 'm4a', 'mp3', 'ds2', 'dss', 'aiff', 'wma', 'aac'];
const attachmentValidExtension = ['doc', 'docx', 'txt'];

const FilesContainer: React.FC<FilesContainerProps> = ({
    title,
    files,
    setFiles,
    isUpload,
    isLoading,
}): React.ReactElement => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>('');
    const isUploadAudioFiles = title === FileType.audio;
    const [selectedFile, setSelectedFile] = useState('');

    useEffect(() => {
        setErrorMessage('');
        if (isUpload && !files.length) {
            setErrorMessage('Audio field is required');
        }
    }, [isUpload, files]);

    const fileValidate = {
        extension: isUploadAudioFiles ? audioValidExtension : attachmentValidExtension,
        maxSize: isUploadAudioFiles ? MAX_SIZE_DOCTOR_AUDIO_UPLOAD : MAX_SIZE_DOCTOR_ATTACHMENT_UPLOAD,
        errorMessage: {
            size: `Sorry, we could not upload this file. Allowed Size is less than ${
                isUploadAudioFiles ? '100MB' : '25MB'
            }`,
            length: `Upload exceeds 3 ${isUploadAudioFiles ? 'dictation' : 'attachment'}  files`,
            extension: `Sorry, we could not upload this file, allowed extensions are: ${
                isUploadAudioFiles ? '.wav, .m4a, .mp3, .ds2, .dss, .aiff, .wma and .aac' : '.doc, .docx and .txt'
            } `,
        },
    };

    const { getRootProps, getInputProps } = useDropzone({
        noClick: isLoading,
        noDrag: isLoading,
        noKeyboard: isLoading,
        onDrop: (acceptedFiles) => {
            const totalSize =
                files.reduce((total, file) => total + file.size, 0) +
                acceptedFiles.reduce((total, file) => total + file.size, 0);
            let message = '';
            acceptedFiles.forEach((file) => {
                const dotIdx = file.name.lastIndexOf('.');
                const extension = file.name.slice(dotIdx + 1, file.name.length).toLowerCase();
                if (!fileValidate.extension.includes(extension)) {
                    message = fileValidate.errorMessage.extension;
                }
            });
            if (totalSize > fileValidate.maxSize) {
                message = fileValidate.errorMessage.size;
            }
            if (files.length + acceptedFiles.length > 3) {
                message = fileValidate.errorMessage.length;
            }
            if (message) {
                setErrorMessage(message);
                return;
            }
            setFiles([...files, ...acceptedFiles]);
        },
    });

    const removeFile = (index: number) => {
        setFiles([...files.slice(0, index), ...files.slice(index + 1, files.length)]);
        setSelectedFile('');
    };

    return (
        <>
            <div className="files-container">
                <div className="files-container__title mt-3">
                    {isUploadAudioFiles ? <img src={audioFile} alt="" /> : <img src={attachmentFile} alt="" />}
                    <p className="text-primary mt-2 choose-file">Choose {title} Files</p>
                    <p className="mt-2 black-text">{`Accepted ${
                        isUploadAudioFiles ? 'Audio' : 'Attachment file'
                    } format are ${
                        isUploadAudioFiles ? '.wav, .m4a, .mp3, .ds2, .dss, .aiff, .wma, and .aac' : '.doc, .docx, .txt'
                    }`}</p>
                </div>
                <div className="files-container__dropzone">
                    <div {...getRootProps({ className: 'drop-area' })}>
                        <input {...getInputProps()} />
                        <p className="drag-file">
                            Drag <b> {title.toUpperCase()} FILES</b> here
                        </p>
                        <p>or</p>
                        <button className="choose-file-btn">Choose file</button>
                    </div>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    {files.length ? (
                        <div className="files-area">
                            {files.map((file, index) => (
                                <div key={index} className="files-area__file">
                                    <span>
                                        <img src={isUploadAudioFiles ? mp3Icon : fileIcon} alt="" />
                                        {file.name}
                                    </span>
                                    <div
                                        className="delete-icon"
                                        onClick={() => {
                                            if (!isLoading) setSelectedFile(`${index}`);
                                        }}
                                    >
                                        <img src={trashIcon2} alt="" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
                <ConfirmModal
                    isOpen={selectedFile}
                    setIsOpen={setSelectedFile}
                    actionConfirm={() => removeFile(+selectedFile)}
                    titleModal="Delete File"
                    textConfirm="Delete Now"
                    className="turn-around-time-confirm-modal"
                    confirmButtonClassName="delete-button"
                >
                    <p>Are you sure to delete this file?</p>
                </ConfirmModal>
            </div>
        </>
    );
};

export default FilesContainer;
