import { GET_USER_INFO, GET_USER_INFO_SUCCESS, UserType, UserInfo, USER_LOGOUT } from './actionTypes';

export const getUserInfo = (): UserType => {
    return {
        type: GET_USER_INFO,
        payload: true,
    };
};

export const getUserInfoSuccess = (payload: UserInfo): UserType => {
    return {
        type: GET_USER_INFO_SUCCESS,
        payload,
    };
};

export const userLogout = (): UserType => {
    return {
        type: USER_LOGOUT,
    };
};
