import API from '../constants/api';
import {
    ChangePassword,
    ChangePasswordFirstTime,
    ForgotPasswordType,
    LoginFields,
    LoginTypeResponse,
    ResetPasswordType,
    ResponseType,
} from '../services/apiTypes';
import { VerificationResetPasswordResponse, VerificationLoginResponse } from '../../redux/auth/actionTypes';
import { AxiosClient } from '../utils/axiosClient';

const authServices = {
    resetPassword: async (params: ResetPasswordType): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.post(API.AUTH.RESET_PASSWORD, params);
        return data;
    },
    sendEmailToForgotPassword: async (params: { email: string }): Promise<ResponseType<null>> => {
        const { data } = await AxiosClient.post(API.AUTH.SEND_EMAIL_TO_FORGOT_PASSWORD, params);
        return data;
    },
    verifyCodeToResetPassword: async (
        params: ForgotPasswordType,
    ): Promise<ResponseType<VerificationResetPasswordResponse>> => {
        const { data } = await AxiosClient.post(API.AUTH.VERIFY_CODE_TO_RESET_PASSWORD, params);
        return data;
    },
    verifyCodeToLogin: async (params: ForgotPasswordType): Promise<ResponseType<VerificationLoginResponse>> => {
        const { data } = await AxiosClient.post(API.AUTH.VERIFY_CODE_TO_LOGIN, params);
        return data;
    },
    login: async (params: LoginFields): Promise<ResponseType<LoginTypeResponse>> => {
        const { data } = await AxiosClient.post(API.AUTH.LOGIN, params);
        return data;
    },
    changePassword: async (params: ChangePassword) => {
        const { data } = await AxiosClient.post(API.AUTH.CHANGE_PASSWORD, params);
        return data;
    },
    changePasswordFirstTime: async (
        params: ChangePasswordFirstTime,
    ): Promise<ResponseType<VerificationLoginResponse>> => {
        const { data } = await AxiosClient.post(API.AUTH.CHANGE_PASSWORD_FIRST_TIME, params);
        return data;
    },
};

export default authServices;
