import { History } from 'history';

import { LoginFailureResponse, LoginParams, LoginResponse } from '../auth/actionTypes';

export const DOCTOR_LOGIN = 'DOCTOR_LOGIN';
export const DOCTOR_LOGIN_FAILURE = 'DOCTOR_LOGIN_FAILURE';
export const DOCTOR_LOGIN_SUCCESS = 'DOCTOR_LOGIN_SUCCESS';
export const DOCTOR_LOGOUT = 'DOCTOR_LOGOUT';
export const DOCTOR_RESET = 'DOCTOR_RESET';

interface DoctorLoginAction {
    type: typeof DOCTOR_LOGIN;
    payload: LoginParams;
    history?: History;
}

interface DoctorLoginSuccessAction {
    type: typeof DOCTOR_LOGIN_SUCCESS;
    payload: LoginResponse;
}

interface DoctorLoginFailureAction {
    type: typeof DOCTOR_LOGIN_FAILURE;
    payload: LoginFailureResponse;
}

interface DoctorResetAction {
    type: typeof DOCTOR_RESET;
}

type DoctorLoginType = DoctorLoginAction | DoctorLoginSuccessAction | DoctorLoginFailureAction | DoctorResetAction;

export type {
    DoctorLoginType,
    DoctorLoginAction,
    DoctorLoginSuccessAction,
    DoctorLoginFailureAction,
    DoctorResetAction,
};
