import { call, put, takeEvery } from 'redux-saga/effects';

import { ResponseType, UserInfoDTO } from '../../common/services/apiTypes';
import userServices from '../../common/services/userServices';
import { storage, storageKey } from '../../common/utils/storage';

import { getUserInfoSuccess } from './actions';
import { GET_USER_INFO } from './actionTypes';

function* getUserInfo() {
    const getUserProfile = storage.getToken(storageKey.TOKEN)?.length
        ? userServices.getProfileUser()
        : userServices.getProfileDoctor();

    try {
        const response: ResponseType<UserInfoDTO | null> = yield call(() => getUserProfile);
        if (response.success && response.data) {
            const {
                id,
                email,
                firstName,
                lastName,
                avatar,
                userRole,
                turnAroundTimeId,
                status,
                name,
            } = response.data.user;
            yield put(
                getUserInfoSuccess({
                    id,
                    email,
                    firstName,
                    lastName,
                    avatar,
                    userRole,
                    turnAroundTimeId,
                    status,
                    name,
                }),
            );
        }
    } catch (error) {}
}

function* watchAll() {
    yield takeEvery(GET_USER_INFO, getUserInfo);
}

export default watchAll();
