import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactCodeInput from 'react-code-input';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { VerificationPage } from '../../../common/constants/enum';
import { doctorRoute, route } from '../../../common/constants/routes';
import authServices from '../../../common/services/authServices';
import { storage, storageKey } from '../../../common/utils/storage';
import { loginSuccess } from '../../../redux/auth/actions';
import AuthForm from '../../../components/AuthForm/AuthForm';
import { LoginStatus } from '../../../redux/auth/actionTypes';
import ButtonForm from '../../../components/ButtonForm/ButtonForm';
import doctorAuthServices from '../../../common/services/doctorAuthServices';
import { notify } from '../../../common/utils/notify';

interface VerificationProps {
    isDoctorAuth: boolean;
}
const Verification: React.FunctionComponent<VerificationProps> = ({ isDoctorAuth }) => {
    const dispatch = useDispatch();
    const history = useHistory<any>();
    const [isVerifying, setIsVerifying] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [countdownResend, setCountdownResend] = useState(0);
    const verificationCodeSchema = Yup.object().shape({
        verificationCode: Yup.string().required().length(6),
    });

    const verificationCodeToLogin = async (values: { verificationCode: string }) => {
        try {
            setIsVerifying(true);
            const response = await authServices.verifyCodeToLogin({
                email: history.location.state?.email || '',
                code: values.verificationCode,
            });
            if (response.success && response.data?.token) {
                storage.removeToken(storageKey.DOCTOR_TOKEN);
                storage.setToken(response.data.token, storageKey.TOKEN);
                dispatch(loginSuccess({ token: response.data.token, status: LoginStatus.success }));
                //* waiting for state updating
                setTimeout(() => {
                    window.location.reload();
                }, 400);
            } else if (
                response.success &&
                response.data?.invitationCode &&
                response.data?.firstName &&
                response.data?.lastName
            ) {
                history.push(route.CHANGE_PASSWORD_FIRST_TIME, {
                    invitationCode: response.data.invitationCode,
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                });
            } else {
                setErrorMessage(response.message);
            }
        } catch (error) {
            setErrorMessage('Failure, please try again!');
        } finally {
            setIsVerifying(false);
        }
    };

    const verificationCodeToSignUp = async (values: { verificationCode: string }) => {
        try {
            setIsVerifying(true);
            const response = await doctorAuthServices.verifyCodeToSignUp({
                email: history.location.state?.email || '',
                code: values.verificationCode,
            });
            if (response.success) {
                notify.success('Sign-up successfully!', 'Success');
                //* waiting for state updating
                setTimeout(() => {
                    history.push(doctorRoute.SIGN_IN);
                }, 400);
            } else {
                setErrorMessage(response.message);
            }
        } catch (error) {
            setErrorMessage('Failure, please try again!');
        } finally {
            setIsVerifying(false);
        }
    };

    const verificationCodeToResetPassword = async (values: { verificationCode: string }) => {
        try {
            setIsVerifying(true);
            const response = await authServices.verifyCodeToResetPassword({
                email: history.location.state.email || '',
                code: values.verificationCode,
            });
            if (response.success && response.data?.code) {
                history.push(isDoctorAuth ? doctorRoute.RESET_PASS_CODE : route.RESET_PASSWORD, {
                    email: history.location.state.email,
                    code: response.data?.code,
                    page: history.location.state?.page,
                });
            } else {
                setErrorMessage(response.message);
            }
        } catch (error) {
            setErrorMessage('Failure, please try again!');
        } finally {
            setIsVerifying(false);
        }
    };

    useEffect(() => {
        if (!history.location.state?.email || !history.location.state?.page) history.push(route.LOGIN);
    }, []);

    useEffect(() => {
        if (!countdownResend) return;
        const countdown = setInterval(() => {
            setCountdownResend(countdownResend - 1);
        }, 1000);
        return () => clearInterval(countdown);
    }, [countdownResend]);

    const resendCode = async () => {
        const response = await authServices.sendEmailToForgotPassword({
            email: history.location.state.email,
        });
        if (response.success) {
            setCountdownResend(60);
        }
    };

    const handleSubmit =
        history.location.state?.page === VerificationPage.LOGIN
            ? verificationCodeToLogin
            : history.location.state?.page === VerificationPage.FORGOT_PASSWORD ||
              history.location.state?.page === VerificationPage.FORGOT_PASS_CODE
            ? verificationCodeToResetPassword
            : verificationCodeToSignUp;

    return (
        <AuthForm
            isShowRedirect={true}
            textRedirect={isDoctorAuth ? 'Back' : ''}
            linkRedirect={isDoctorAuth ? doctorRoute.WELCOME : ''}
        >
            <Formik
                initialValues={{ verificationCode: '' }}
                validateOnChange
                validationSchema={verificationCodeSchema}
                enableReinitialize
                onSubmit={(values) => {
                    handleSubmit(values);
                }}
            >
                {({ handleSubmit, values, setFieldValue }) => (
                    <Form
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                    >
                        <p className="text-primary mt-5 mb-3 paragraph-main">Verify Email Address</p>
                        <p className="paragraph-extra text-color-primary mb-5">
                            Please enter the verification code we sent to your email.
                        </p>
                        <p className="text-center text-primary mb-0 mt-4 paragraph-medium">
                            Enter 6 digit verification code
                        </p>
                        <ReactCodeInput
                            name="verificationCode"
                            inputMode="numeric"
                            fields={6}
                            className={`verification-container ${errorMessage ? 'error' : ''}`}
                            value={values.verificationCode}
                            onChange={(e) => {
                                setErrorMessage('');
                                setFieldValue('verificationCode', e);
                            }}
                        />
                        {errorMessage && (
                            <Form.Text className="text-danger text-center paragraph-extra">{errorMessage}</Form.Text>
                        )}
                        <ButtonForm
                            buttonName="VERIFY"
                            handleSubmit={handleSubmit}
                            isLoading={isVerifying}
                            className="mt-5"
                        />
                    </Form>
                )}
            </Formik>
        </AuthForm>
    );
};

export default Verification;
