import React from 'react';

import { sortCaret } from '../../../common/helpers/TableHelper';
import {
    DropdownSelectCell,
    Row,
    SelectActions,
} from '../../../components/Table/components/DropdownSelectCell/DropdownSelectCell';
import { CustomLinkCell, TableConfigActions } from '../../../components/Table/components/CustomLinkCell/CustomLinkCell';
import constant from '../../../common/constants/app';
import { route } from '../../../common/constants/routes';

import { DictationTableConfig } from './DictationsInProgress';

const formatter = (_cell: string, row: Row, _rowIndex: number, actions: TableConfigActions): JSX.Element => (
    <CustomLinkCell link={`${route.DICTATIONS_IN_PROGRESS}/${row.id}`} actions={actions} />
);
const statusFormatter = (cell: string, row: Row, _rowIndex: number, actions: SelectActions): JSX.Element => (
    <DropdownSelectCell
        value={cell}
        options={constant.dictationStatus.filter(
            (item) => item.id === 'InProgress' || item.id === 'Submitted' || item.id === 'Unassign',
        )}
        statusClassName="in-progress-select"
        actions={actions}
        row={row}
    />
);

export const renderColumns = (
    handleSelected: (value: string, id: number) => void,
    tableConfig: DictationTableConfig,
) => [
    {
        dataField: 'id',
        text: 'Unique Code',
        sort: true,
        sortCaret: sortCaret,
        classes: 'border-right',
        headerStyle: () => {
            return { textAlign: 'center' };
        },
        style: {
            textAlign: 'center',
        },
    },
    {
        dataField: 'createdAt',
        text: 'Date of Upload',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
        classes: 'border-right',
    },
    {
        dataField: 'userName',
        text: 'Username',
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses: 'sortable-active',
        classes: 'border-right',
    },
    {
        dataField: 'turnAroundTime.value',
        text: 'Turn Around Time',
        classes: 'border-right',
    },
    {
        dataField: 'status',
        text: 'Status',
        classes: 'border-right icon-table',
        formatter: statusFormatter,
        formatExtraData: {
            handleSelected: handleSelected,
        },
        headerStyle: () => {
            return { textAlign: 'center' };
        },
    },
    {
        dataField: '',
        text: '',
        formatter: formatter,
        formatExtraData: {
            tableConfig,
        },
        classes: 'text-center icon-table',
        headerClasses: 'text-center',
        style: {
            minWidth: '140px',
        },
        headerStyle: () => {
            return { width: '7%' };
        },
    },
];
