import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Table, { PaginationOptions, TableConfig } from '../../../components/Table/Table';
import SubHeaderPage from '../../../components/SubHeaderPage/SubHeaderPage';
import AppConstants from '../../../common/constants/app';
import TableToolbar, { ToolbarFilter } from '../../../components/Table/components/TableToolbar/TableToolbar';
import appServices from '../../../common/services/appServices';
import { DictationResourceType, DictationResponse } from '../../../common/services/apiTypes';
import { formatDate } from '../../../common/helpers/DateFormatHelper';
import { GlobalState } from '../../../redux';
import { getTurnAroundTimes } from '../../../redux/settings/action';
import { ExpandContent } from '../../../components/Table/components/ExpandRowContent/ExpandRowContent';
import { ActionSubject, DictationStatus, SortType } from '../../../common/constants/enum';
import { beforeUnloadCustomEvent, showNotifyDictationChangeStatus } from '../../../common/helpers/NotifyHelper';
import { notify } from '../../../common/utils/notify';
import { tableConfigGettingTAT } from '../../../common/helpers/TableHelper';
import { getListAccountActive } from '../../../redux/account/action';

import { renderColumns } from './DictationsInProgressHelper';

export interface DictationTableConfig extends TableConfig {
    status: string;
    turnAroundTime: string;
    accountId?: string;
    fromDate?: Date;
    includeEditorInCharge?: boolean;
}
export interface getHistoricalDetail extends DictationTableConfig {
    dictationId?: number;
}
export interface SearchParamListDictation extends TableConfig {
    status?: DictationStatus;
    turnAroundTime?: number;
    fromDate?: Date;
    isExport?: boolean;
    includeEditorInCharge?: boolean;
    userId?: number;
    accountId?: number | null;
    isDownloaded?: boolean;
}

const initialTableConfig: DictationTableConfig = {
    page: 1,
    pageSize: AppConstants.pagination.pageSize,
    searchValue: '',
    sortBy: 'createdAt',
    sortDirection: SortType.DESC,
    status: DictationStatus.InProgress,
    turnAroundTime: '',
    accountId: '',
};

interface DictationsResponse extends DictationResponse {
    userName: string;
}

const DictationsInProgress: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory<{ tableConfigRemember: DictationTableConfig }>();
    const [totalSize, setTotalSize] = useState<number>(0);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [listDictation, setListDictation] = useState<DictationsResponse[]>([]);
    const [tableConfig, setTableConfig] = useState<DictationTableConfig>(
        history.location.state?.tableConfigRemember ? history.location.state.tableConfigRemember : initialTableConfig,
    );
    const turnAroundTime = useSelector((state: GlobalState) => state.settings.turnAroundTime.list);
    const [itemTATFilter, setItemTATFilter] = useState<string>('');
    const [listResourceFile, setListResourceFile] = useState<DictationResourceType[]>([]);
    const [reloadPageType, setReloadPageType] = useState<ActionSubject>(ActionSubject.UNDEFINED);
    const [itemAccountFilter, setItemAccountFilter] = useState<string>('');
    const listAccountActive = useSelector((state: GlobalState) => state.account.listAccountActive);
    const listAccount: ToolbarFilter[] = listAccountActive.map((item) => {
        return {
            value: item.accountName,
            id: item.id.toString(),
        };
    });

    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: totalSize,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    const getData = async (tableConfigProps: DictationTableConfig) => {
        setIsDataLoading(true);
        try {
            const response = await appServices.getListDictation(tableConfigProps);
            if (response.success) {
                const listFormatDate = response.data.list.map((item) => ({
                    ...item,
                    createdAt: formatDate(item.createdAt),
                    userName: item.user.name,
                }));
                setListDictation(listFormatDate);
                setTableConfig({ ...tableConfigProps, page: parseInt(response.data.current_page) });
                setTotalSize(response.data.total);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    const getResourceData = async (dictationId: number, setResource: (resource: DictationResourceType) => void) => {
        try {
            const response = await appServices.getDictationResource(dictationId);
            if (response.success) {
                setListResourceFile([...listResourceFile, response.data]);
                setResource(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        getData({ ...tableConfig, page: 1, accountId: itemAccountFilter, turnAroundTime: itemTATFilter });
    }, [itemTATFilter, itemAccountFilter]);

    useEffect(() => {
        dispatch(getTurnAroundTimes(tableConfigGettingTAT));
        dispatch(getListAccountActive());
        beforeUnloadCustomEvent();
    }, []);

    useEffect(() => {
        if (reloadPageType !== ActionSubject.UNDEFINED) {
            getData(initialTableConfig);
            setReloadPageType(ActionSubject.UNDEFINED);
        }
    }, [reloadPageType]);

    const getHandleTableChange = (type: string, newState: any) => {
        const params = {
            page: newState.page || tableConfig.page,
            pageSize: newState.sizePerPage || tableConfig.pageSize,
            searchValue: newState.searchText || tableConfig.searchValue,
            sortBy: newState.sortField || tableConfig.sortBy,
            sortDirection: newState.sortOrder || tableConfig.sortDirection,
            status: tableConfig.status,
            turnAroundTime: tableConfig.turnAroundTime,
            accountId: tableConfig.accountId,
        };
        if (params.sortBy === 'userName') {
            params.sortBy = 'name';
        }
        if (type === 'search') {
            if (!newState.searchText.trim()) return getData({ ...params, page: 1, searchValue: '' });
            return getData({ ...params, page: 1 });
        }
        getData(params);
    };

    const handleSelected = async (value: string, id: number) => {
        try {
            const response = await appServices.updateDictationStatus({ status: value }, id);
            if (response.success) {
                setReloadPageType(ActionSubject.UPDATE);
                showNotifyDictationChangeStatus(value);
            } else notify.error(response.message, 'Error');
        } catch (error) {
            console.log(error);
        }
    };

    const renderExpandContent = (row: any) => {
        return (
            <ExpandContent getResourceData={getResourceData} dictationId={row.id} listResourceFile={listResourceFile} />
        );
    };

    const expandRow = {
        renderer: (row: any) => renderExpandContent(row),
    };

    const tableFilter =
        (turnAroundTime?.length && turnAroundTime.map((t) => ({ id: parseInt(t.id), value: t.value }))) || [];

    const defaultSorted = [
        {
            dataField: tableConfig.sortBy,
            order: tableConfig.sortDirection,
        },
    ];

    return (
        <div>
            <SubHeaderPage title="In Progress" />
            <Table
                tableConfig={tableConfig}
                paginationOptions={paginationOptions}
                data={listDictation}
                isDataLoading={isDataLoading}
                getData={getData}
                columns={renderColumns(handleSelected, tableConfig)}
                getHandleTableChange={getHandleTableChange}
                expandRow={expandRow}
                rowClasses="item-table"
                defaultInputSearch={tableConfig.searchValue}
                defaultSorted={defaultSorted}
                tableToolbar={
                    <>
                        <TableToolbar
                            isShowFilterGroup
                            listFilter={listAccount}
                            setFilterValue={setItemAccountFilter}
                            placeholderSelect="Account"
                            defaultValueFilter={tableConfig.accountId}
                        />
                        <TableToolbar
                            isShowFilterGroup
                            listFilter={tableFilter}
                            setFilterValue={setItemTATFilter}
                            placeholderSelect="TAT"
                            defaultValueFilter={tableConfig.turnAroundTime}
                        />
                    </>
                }
            />
        </div>
    );
};

export default DictationsInProgress;
