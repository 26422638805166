import { GET_STATISTIC, GET_STATISTIC_FAILURE, GET_STATISTIC_SUCCESS, getStatisticValues } from './actionTypes';

export const getStatistic = () => {
    return {
        type: GET_STATISTIC,
    };
};

export const getStatisticSuccess = (payload: getStatisticValues) => {
    return {
        type: GET_STATISTIC_SUCCESS,
        payload,
    };
};

export const getStatisticFailure = () => {
    return {
        type: GET_STATISTIC_FAILURE,
    };
};
