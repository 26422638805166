import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fileSaver from 'file-saver';
import { useHistory } from 'react-router-dom';

import Table, { PaginationOptions } from '../../../components/Table/Table';
import { DictationResourceType, DictationResponse } from '../../../common/services/apiTypes';
import AppConstants from '../../../common/constants/app';
import SubHeaderPage from '../../../components/SubHeaderPage/SubHeaderPage';
import { getTurnAroundTimes } from '../../../redux/settings/action';
import appServices from '../../../common/services/appServices';
import { ExpandContent } from '../../../components/Table/components/ExpandRowContent/ExpandRowContent';
import { DictationStatus, SortType } from '../../../common/constants/enum';
import { formatDate } from '../../../common/helpers/DateFormatHelper';
import { DictationTableConfig } from '../dictations-in-progress/DictationsInProgress';
import dayjs from '../../../common/utils/dayjs';
import { tableConfigGettingTAT } from '../../../common/helpers/TableHelper';
import { beforeUnloadCustomEvent } from '../../../common/helpers/NotifyHelper';
import { ToolbarFilter } from '../../../components/Table/components/TableToolbar/TableToolbar';
import { GlobalState } from '../../../redux';
import { getListAccountActive } from '../../../redux/account/action';

import TableToolbarHistoricalRecord from './components/TableToolbarHistoricalRecord/TableToolbarHistoricalRecord';
import { renderColumns } from './HistoricalRecordHelper';

const initialTableConfig: DictationTableConfig = {
    page: 1,
    pageSize: AppConstants.pagination.pageSize,
    searchValue: '',
    sortBy: 'createdAt',
    sortDirection: SortType.DESC,
    status: `${DictationStatus.Finished},${DictationStatus.Canceled}`,
    turnAroundTime: '',
    accountId: '',
};

interface DictationsResponse extends DictationResponse {
    userName: string;
}

const HistoryRecord: React.FunctionComponent = (): React.ReactElement => {
    const history = useHistory<{ tableConfigRemember: DictationTableConfig }>();
    const [totalSize, setTotalSize] = useState<number>(0);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [listDictation, setListDictation] = useState<DictationsResponse[]>([]);
    const dispatch = useDispatch();
    const [listResourceFile, setListResourceFile] = useState<DictationResourceType[]>([]);
    const [tableConfig, setTableConfig] = useState<DictationTableConfig>(
        history.location.state?.tableConfigRemember ? history.location.state.tableConfigRemember : initialTableConfig,
    );
    const listAccountActive = useSelector((state: GlobalState) => state.account.listAccountActive);
    const listAccount: ToolbarFilter[] = listAccountActive.map((item) => {
        return {
            value: item.accountName,
            id: item.id,
        };
    });

    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: totalSize,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    const getData = async (tableConfigProps: DictationTableConfig) => {
        setIsDataLoading(true);
        try {
            const response = await appServices.getListDictation(tableConfigProps);
            if (response.success) {
                const listFormatDate = response.data.list.map((item) => ({
                    ...item,
                    createdAt: formatDate(item.createdAt),
                    userName: item.user.name,
                }));
                setListDictation(listFormatDate);
                setTableConfig({ ...tableConfigProps, page: parseInt(response.data.current_page) });
                setTotalSize(response.data.total);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    const getHandleTableChange = (type: string, newState: any) => {
        const params = {
            page: newState.page || tableConfig.page,
            pageSize: newState.sizePerPage || tableConfig.pageSize,
            searchValue: newState.searchText || tableConfig.searchValue,
            sortBy: newState.sortField || tableConfig.sortBy,
            sortDirection: newState.sortOrder || tableConfig.sortDirection,
            status: tableConfig.status,
            turnAroundTime: tableConfig.turnAroundTime,
            accountId: tableConfig.accountId,
        };
        if (params.sortBy === 'userName') {
            params.sortBy = 'name';
        }
        if (type === 'search') {
            if (!newState.searchText.trim()) return getData({ ...params, page: 1, searchValue: '' });
            return getData({ ...params, page: 1 });
        }
        getData(params);
    };

    useEffect(() => {
        dispatch(getTurnAroundTimes(tableConfigGettingTAT));
        dispatch(getListAccountActive());
        beforeUnloadCustomEvent();
    }, []);

    const renderExpandContent = (row: any) => {
        return (
            <ExpandContent getResourceData={getResourceData} dictationId={row.id} listResourceFile={listResourceFile} />
        );
    };

    const expandRow = {
        renderer: (row: any) => renderExpandContent(row),
    };

    const getResourceData = async (dictationId: number, setResource: (resource: DictationResourceType) => void) => {
        try {
            const response = await appServices.getDictationResource(dictationId);
            if (response.success) {
                setListResourceFile([...listResourceFile, response.data]);
                setResource(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeFilter = (tableConfig: DictationTableConfig) => {
        getData(tableConfig);
        setTableConfig(tableConfig);
    };

    const handleDownloadFileCsv = async (tableConfig: DictationTableConfig, fromDate?: Date) => {
        try {
            const exportFileResponse = await appServices.downloadFileCsv({
                fromDate,
                status: `${DictationStatus.Finished},${DictationStatus.Canceled}`,
                searchValue: tableConfig.searchValue,
                turnAroundTime: tableConfig.turnAroundTime,
            });
            const blob = new Blob([exportFileResponse], { type: 'text/csv' });
            let fileName = AppConstants.csvBaseFileName;
            if (fromDate) {
                fileName += `_${AppConstants.quarterTitles[dayjs(fromDate).quarter() - 1]}${fromDate.getFullYear()}`;
            }
            fileSaver.saveAs(blob, `${fileName}.csv`);
        } catch (error) {
            console.log(error);
        }
    };

    const defaultSorted = [
        {
            dataField: tableConfig.sortBy,
            order: tableConfig.sortDirection,
        },
    ];

    return (
        <div>
            <SubHeaderPage title="Historical record" />
            <TableToolbarHistoricalRecord
                handleChangeFilter={handleChangeFilter}
                tableFilter={tableConfig}
                listAccount={listAccount}
                handleDownloadFileCsv={handleDownloadFileCsv}
            />
            <Table
                tableConfig={tableConfig}
                paginationOptions={paginationOptions}
                data={listDictation}
                isDataLoading={isDataLoading}
                expandRow={expandRow}
                isHideSearchBar={true}
                rowClasses="item-table"
                getData={getData}
                columns={renderColumns(tableConfig)}
                defaultSorted={defaultSorted}
                getHandleTableChange={getHandleTableChange}
            />
        </div>
    );
};

export default HistoryRecord;
