import { TemplateUploadType } from '../../pages/doctor-pages/templates/components/TemplateUploadDialog/TemplateUploadDialog';
import { TableTemplateByBranchConfig } from '../../pages/editor-pages/templates/Template';
import API from '../constants/api';
import { AxiosClient, AxiosDoctorClient } from '../utils/axiosClient';

import { TemplateTableResponse, ResponseType } from './apiTypes';

const templateServices = {
    getTemplate: async (params: TableTemplateByBranchConfig): Promise<TemplateTableResponse> => {
        const { data } = await AxiosClient.get(API.TEMPLATES.GET_LIST_TEMPLATE, { params });
        return data;
    },

    getTemplateDoctorRole: async (params: TableTemplateByBranchConfig): Promise<TemplateTableResponse> => {
        const { data } = await AxiosDoctorClient.get(API.TEMPLATES.GET_LIST_TEMPLATE, { params });
        return data;
    },
    uploadTemplateDoctorRole: async (params: TemplateUploadType): Promise<ResponseType<any>> => {
        const formData = new FormData();
        params.file && formData.append('file', params.file);
        formData.append('templateName', params.templateName);
        const { data } = await AxiosDoctorClient.post(API.TEMPLATES.UPLOAD_FILE_TEMPLATE, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data;
    },
    downloadTemplateDoctorRole: async (params: number): Promise<any> => {
        const { data } = await AxiosDoctorClient.get(API.TEMPLATES.DOWNLOAD_FILE_TEMPLATE + params);
        return data;
    },
    deleteTemplateDoctorRole: async (params: { templateIds: number[] }): Promise<ResponseType<null>> => {
        const { data } = await AxiosDoctorClient.delete(API.TEMPLATES.GET_LIST_TEMPLATE, { data: params });
        return data;
    },
};

export default templateServices;
