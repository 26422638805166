import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Alert, Navbar, NavDropdown } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import useWidth from '../../../common/custom-hooks/widthHook';
import appConstants, { WindowSize } from '../../../common/constants/app';
import AuthForm from '../../../components/AuthForm/AuthForm';
import InputForm, { InputType } from '../../../components/InputForm/InputForm';
import PasswordRules from '../../../components/PasswordRules/PasswordRules';
import ButtonForm from '../../../components/ButtonForm/ButtonForm';
import authServices from '../../../common/services/authServices';
import { notify } from '../../../common/utils/notify';
import doctorAuthServices from '../../../common/services/doctorAuthServices';
import { storage, storageKey } from '../../../common/utils/storage';
import { loginSuccess } from '../../../redux/auth/actions';
import { LoginStatus } from '../../../redux/auth/actionTypes';
import './ChangePasswordStyles.scss';
import '../../../components/Header/HeaderStyles.scss';
import headerMenu from '../../../common/assets/images/headerMenu.png';
import { doctorRoute, route } from '../../../common/constants/routes';
import { doctorLoginSuccess } from '../../../redux/doctor-auth/actions';

export interface ChangePasswordFields {
    oldPassword: string;
    newPassword: string;
    confirmationPassword: string;
}

interface ChangePasswordProps {
    currentPage: 'password' | 'passcode';
}
const ChangePassword: React.FC<ChangePasswordProps> = ({ currentPage }): React.ReactElement => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentLocation = location.pathname;
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const history = useHistory<{ invitationCode: string; firstName?: string; lastName?: string; name?: string }>();
    const isEditorChangePasswordFirstTime =
        history.location.state?.invitationCode && history.location.state?.firstName && history.location.state?.lastName;
    const isDoctorChangePasswordFirstTime = history.location.state?.invitationCode && history.location.state?.name;
    const { windowWidth } = useWidth();

    const changePasswordSchema = Yup.object().shape({
        oldPassword: Yup.string().required('This field is required'),
        newPassword: Yup.string()
            .required('This field is required')
            .matches(appConstants.passwordHelper.passwordRuleRegex, 'Password does not follow the format rules')
            .when('oldPassword', {
                is: (oldPassword: string) => oldPassword !== undefined,
                then: Yup.string().notOneOf([Yup.ref('oldPassword')], 'This password has been currently used'),
            }),
        confirmationPassword: Yup.string()
            .required('This field is required')
            .when('newPassword', {
                is: (newPassword: string) => newPassword !== undefined,
                then: Yup.string().oneOf([Yup.ref('newPassword')], 'Password does not match'),
            }),
    });
    const changePasscodeSchema = Yup.object().shape({
        oldPassword: Yup.string().required('This field is required'),
        newPassword: Yup.string()
            .required('This field is required')
            .matches(/^[0-9]{4}$/, 'Must Contain 4 Numbers')
            .when('oldPassword', {
                is: (oldPassword: string) => oldPassword !== undefined,
                then: Yup.string().notOneOf([Yup.ref('oldPassword')], 'This password has been currently used'),
            }),
        confirmationPassword: Yup.string()
            .required('This field is required')
            .when('newPassword', {
                is: (newPassword: string) => newPassword !== undefined,
                then: Yup.string().oneOf([Yup.ref('newPassword')], 'Password does not match'),
            }),
    });

    const handleSubmitForm = async (values: ChangePasswordFields) => {
        try {
            setIsLoading(true);
            const response = isEditorChangePasswordFirstTime
                ? await authServices.changePasswordFirstTime({
                      oldPassword: values.oldPassword,
                      newPassword: values.newPassword,
                      invitationCode: history.location.state?.invitationCode,
                  })
                : currentPage === 'password'
                ? await authServices.changePassword({
                      oldPassword: values.oldPassword,
                      newPassword: values.newPassword,
                  })
                : isDoctorChangePasswordFirstTime
                ? await doctorAuthServices.changePasswordFirstTime({
                      oldPassword: values.oldPassword,
                      newPassword: values.newPassword,
                      invitationCode: history.location.state?.invitationCode,
                  })
                : await doctorAuthServices.changePassword({
                      oldPassword: values.oldPassword,
                      newPassword: values.newPassword,
                  });
            if (response.success) {
                notify.success('Change password success', 'Success');
                if (isEditorChangePasswordFirstTime && response.data?.token) {
                    storage.removeToken(storageKey.DOCTOR_TOKEN);
                    storage.setToken(response.data.token, storageKey.TOKEN);
                    dispatch(loginSuccess({ token: response.data.token, status: LoginStatus.success }));
                    //* waiting for state updating
                    setTimeout(() => {
                        window.location.reload();
                    }, 400);
                } else if (isDoctorChangePasswordFirstTime && response.data?.token) {
                    storage.removeToken(storageKey.TOKEN);
                    storage.setToken(response.data.token, storageKey.DOCTOR_TOKEN);
                    dispatch(doctorLoginSuccess({ token: response.data.token, status: LoginStatus.success }));
                    window.location.pathname = doctorRoute.HOME;
                }
            } else {
                setErrorMessage(response.message);
            }
        } catch (error) {
            setErrorMessage('Failure, please try again');
        } finally {
            setIsLoading(false);
            return;
        }
    };

    useEffect(() => {
        if (!history.location.state?.invitationCode && location.pathname.includes(route.CHANGE_PASSWORD_FIRST_TIME))
            history.push(route.LOGIN);
        if (
            !history.location.state?.invitationCode &&
            location.pathname.includes(doctorRoute.CHANGE_PASSWORD_FIRST_TIME)
        )
            history.push(doctorRoute.SIGN_IN);
    }, []);

    return (
        <>
            {(isEditorChangePasswordFirstTime || isDoctorChangePasswordFirstTime) && (
                <div className="header">
                    <Navbar className="p-0">
                        {windowWidth > WindowSize.SMALL ? (
                            <NavDropdown
                                id="user-options"
                                title={
                                    isEditorChangePasswordFirstTime
                                        ? `${history.location.state?.firstName} ${history.location.state?.lastName}`
                                        : history.location.state?.name
                                }
                            ></NavDropdown>
                        ) : null}
                        <div className="topbar-icon ml-4">
                            <img src={headerMenu} alt="" />
                        </div>
                    </Navbar>
                </div>
            )}
            <AuthForm
                backgroundClass="change-password-background"
                isShowLogo={true}
                currentLocation={currentLocation}
                isShowRedirect={false}
            >
                <>
                    <p className="text-primary mt-3 mb-2 paragraph-main title-change-password">Change {currentPage}</p>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Formik
                        initialValues={{
                            oldPassword: '',
                            newPassword: '',
                            confirmationPassword: '',
                        }}
                        validateOnChange
                        validationSchema={currentPage === 'password' ? changePasswordSchema : changePasscodeSchema}
                        enableReinitialize
                        onSubmit={handleSubmitForm}
                    >
                        {({ values, handleSubmit, errors, touched, handleChange }) => (
                            <Form
                                className="mt-2 pb-4"
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit();
                                    }
                                }}
                            >
                                <InputForm
                                    controlId="formOldPassword"
                                    label={`Current ${currentPage}`}
                                    type={InputType.Password}
                                    name="oldPassword"
                                    handleChange={handleChange}
                                    setErrorMessageForm={setErrorMessage}
                                    labelStyle="text-color-primary mb-1 paragraph-medium"
                                    value={values.oldPassword}
                                    error={(touched.oldPassword && errors.oldPassword) || ''}
                                />
                                <InputForm
                                    controlId="formPassword"
                                    label={`New ${currentPage}`}
                                    type={InputType.Password}
                                    name="newPassword"
                                    handleChange={handleChange}
                                    setErrorMessageForm={setErrorMessage}
                                    labelStyle="text-color-primary mb-1 paragraph-medium"
                                    formGroupStyle={`${currentPage === 'password' ? 'pb-3 border-bottom' : ''}`}
                                    value={values.newPassword}
                                    error={(touched.newPassword && errors.newPassword) || ''}
                                />
                                {currentPage == 'password' && <PasswordRules password={values.newPassword} />}

                                <InputForm
                                    controlId="formConfirmPassword"
                                    label={`Confirm new ${currentPage}`}
                                    type={InputType.Password}
                                    name="confirmationPassword"
                                    handleChange={handleChange}
                                    setErrorMessageForm={setErrorMessage}
                                    labelStyle="text-color-primary mb-1 paragraph-medium"
                                    value={values.confirmationPassword}
                                    error={(touched.confirmationPassword && errors.confirmationPassword) || ''}
                                />
                                <ButtonForm
                                    buttonName={`RESET ${currentPage.toUpperCase()}`}
                                    handleSubmit={handleSubmit}
                                    isLoading={isLoading}
                                />
                            </Form>
                        )}
                    </Formik>
                </>
            </AuthForm>
        </>
    );
};

export default ChangePassword;
