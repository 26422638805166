import { AccountDTO } from '../../common/services/apiTypes';

export const GET_LIST_ACCOUNT_ACTIVE = 'GET_LIST_ACCOUNT_ACTIVE';
export const GET_LIST_ACCOUNT_ACTIVE_SUCCESS = 'GET_LIST_ACCOUNT_ACTIVE_SUCCESS';
export const GET_LIST_ACCOUNT_ACTIVE_FAILURE = 'GET_LIST_ACCOUNT_ACTIVE_FAILURE';

export interface AccountActiveType extends Omit<AccountDTO, 'returnEmail' | 'returnEmailStatus' | 'accountStatus'> {}

interface getListAccountActiveAction {
    type: typeof GET_LIST_ACCOUNT_ACTIVE;
}

interface getListAccountActiveSuccessAction {
    type: typeof GET_LIST_ACCOUNT_ACTIVE_SUCCESS;
    payload: AccountActiveType[];
}

interface getListAccountActiveFailureAction {
    type: typeof GET_LIST_ACCOUNT_ACTIVE_FAILURE;
}

type AccountType = getListAccountActiveAction | getListAccountActiveFailureAction | getListAccountActiveSuccessAction;

export type { getListAccountActiveAction, AccountType };
