export const GET_STATISTIC = 'GET_STATISTIC';
export const GET_STATISTIC_SUCCESS = 'GET_STATISTIC_SUCCESS';
export const GET_STATISTIC_FAILURE = 'GET_STATISTIC_FAILURE';

interface getStatisticAction {
    type: typeof GET_STATISTIC;
}

interface getStatisticValues {
    registerAccounts: (number | string)[];
    currentTranscriptions: (number | string)[];
    finishedTranscriptions: (number | string)[];
}

interface getStatisticSuccessAction {
    type: typeof GET_STATISTIC_SUCCESS;
    payload: getStatisticValues;
}

type HomeType = getStatisticAction | getStatisticSuccessAction;

export type { HomeType, getStatisticAction, getStatisticValues };
