import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ActionSubject, SortType, UserRole } from '../../../common/constants/enum';
import { AccountDTO } from '../../../common/services/apiTypes';
import appServices from '../../../common/services/appServices';
import SubHeaderPage from '../../../components/SubHeaderPage/SubHeaderPage';
import Table, { PaginationOptions, TableConfig } from '../../../components/Table/Table';
import TableToolbar from '../../../components/Table/components/TableToolbar/TableToolbar';
import { GlobalState } from '../../../redux';

import { renderColumns } from './AccountHelper';
import { CreateAccountDialog } from './components/CreateAccountDialog/CreateAccountDialog';

export const initialTableConfig: TableConfig = {
    page: 1,
    pageSize: 10,
    searchValue: '',
    sortBy: '',
    sortDirection: SortType.DESC,
};

const Account: React.FunctionComponent = () => {
    const [totalSize, setTotalSize] = useState<number>(0);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [listAccount, setListAccount] = useState<AccountDTO[]>([]);
    const [isShowDialog, setShowDialog] = useState(false);
    const [reloadPageType, setReloadPageType] = useState<ActionSubject>(ActionSubject.UNDEFINED);
    const [tableConfig, setTableConfig] = useState<TableConfig>(initialTableConfig);
    const { userInfo } = useSelector((state: GlobalState) => state.user);

    const paginationOptions: PaginationOptions = {
        custom: true,
        totalSize: totalSize,
        sizePerPageList: [
            { text: '10', value: 10 },
            { text: '30', value: 30 },
            { text: '50', value: 50 },
        ],
        sizePerPage: tableConfig.pageSize,
        page: tableConfig.page,
    };

    const handleAction = () => {
        setShowDialog(true);
    };

    const getData = async (tableConfigProps: TableConfig) => {
        setIsDataLoading(true);
        try {
            const response = await appServices.getListAccount(tableConfigProps);
            if (response.success) {
                setListAccount(response.data.list);
                setTableConfig({ ...tableConfigProps, page: parseInt(response.data.current_page) });
                setTotalSize(response.data.total);
                setReloadPageType(ActionSubject.UNDEFINED);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsDataLoading(false);
        }
    };

    const getHandleTableChange = (type: string, newState: any) => {
        const params = {
            page: newState.page || tableConfig.page,
            pageSize: newState.sizePerPage || tableConfig.pageSize,
            searchValue: newState.searchText || tableConfig.searchValue,
            sortBy: newState.sortField || tableConfig.sortBy,
            sortDirection: newState.sortOrder || tableConfig.sortDirection,
        };
        if (type === 'search') {
            return getData({ ...params, page: 1 });
        }
        getData(params);
    };

    useEffect(() => {
        if (reloadPageType !== ActionSubject.UNDEFINED) {
            getData(tableConfig);
            setShowDialog(false);
            setReloadPageType(ActionSubject.UNDEFINED);
        }
    }, [reloadPageType]);

    return (
        <>
            <CreateAccountDialog
                isShowDialog={isShowDialog}
                setShowDialog={setShowDialog}
                setReloadPageType={setReloadPageType}
            />
            <SubHeaderPage title="Client Accounts" />
            <Table
                keyField="id"
                tableConfig={tableConfig}
                paginationOptions={paginationOptions}
                data={listAccount}
                isDataLoading={isDataLoading}
                getData={getData}
                columns={renderColumns(userInfo?.userRole === UserRole.Admin, setReloadPageType)}
                getHandleTableChange={getHandleTableChange}
                tableToolbar={
                    userInfo.userRole === UserRole.Admin ? (
                        <TableToolbar textAddNewButton="Create New Account" handleAction={handleAction} />
                    ) : undefined
                }
            />
        </>
    );
};

export default Account;
