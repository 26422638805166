import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';

import dayjs, { getStartDateOfQuarter } from '../../../common/utils/dayjs';
import AppConstants from '../../../common/constants/app';

import './QuarterPickerStyles.scss';

export interface QuarterPickerProps {
    onChange: (date?: Date) => void;
    defaultQuarter?: Date;
}

const DatePicker: React.FunctionComponent<QuarterPickerProps> = ({ onChange, defaultQuarter }): React.ReactElement => {
    const [textYear, setTextYear] = useState<number>(dayjs().year());
    const [textLabel, setTextLabel] = useState<string>(AppConstants.quarters[0].title);
    const [quarterActive, setQuarterActive] = useState<number>(0);
    const [isPickerDisplayed, setPickerDisplayState] = useState<boolean>(false);

    const handleChangeYear = (year: number) => {
        if (quarterActive > 0) {
            onChange(getStartDateOfQuarter(quarterActive, year));
            setTextLabel(`Q${quarterActive}: ${year}`);
            setTextYear(year);
        }
    };

    const handleSelectQuarter = (quarter: number) => {
        if (quarter > 0) {
            const fromDate = getStartDateOfQuarter(quarter, textYear);
            onChange(fromDate);
        } else {
            onChange();
        }
        setQuarterActive(quarter);
        setTextLabel(quarter > 0 ? `Q${quarter}: ${textYear}` : AppConstants.quarters[0].title);
        setPickerDisplayState(false);
    };

    useEffect(() => {
        if (defaultQuarter) {
            setTextYear(defaultQuarter.getFullYear());
            setQuarterActive(dayjs(defaultQuarter).quarter());
            setTextLabel(`Q${dayjs(defaultQuarter).quarter()}: ${defaultQuarter.getFullYear()}`);
        }
    }, [defaultQuarter]);

    const pickerContentClassName = classNames('date-picker-item-box', { hidden: !isPickerDisplayed });
    const changeYearClassName = quarterActive == 0 ? 'c-no-drop-force' : '';
    return (
        <>
            <InputGroup onClick={() => setPickerDisplayState(!isPickerDisplayed)}>
                <InputGroup.Prepend>
                    <InputGroup.Text id="btnFilterDatePicker"></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    aria-describedby="btnFilterDatePicker"
                    value={textLabel}
                    readOnly
                    id="filterDatePicker"
                ></Form.Control>
            </InputGroup>
            <div className={pickerContentClassName}>
                <div className="date-picker-header">
                    <div
                        id="btnPrevYear"
                        className={changeYearClassName}
                        onClick={() => handleChangeYear(textYear - 1)}
                    ></div>
                    <div id="textYear" className={quarterActive == 0 ? 'text-year' : ''}>
                        {textYear}
                    </div>
                    <div
                        id="btnNextYear"
                        className={changeYearClassName}
                        onClick={() => handleChangeYear(textYear + 1)}
                    ></div>
                </div>
                <div className="date-picker-content">
                    {AppConstants.quarters.map((item) => (
                        <div
                            className={quarterActive == item.quarter ? 'active' : ''}
                            key={item.quarter}
                            onClick={() => handleSelectQuarter(item.quarter)}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>
                <div className="date-picker-footer"></div>
            </div>
        </>
    );
};

export default DatePicker;
