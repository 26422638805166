import { TurnAroundTimeResponse } from '../../common/services/apiTypes';

import {
    GET_TURN_AROUND_TIMES_SUCCESS,
    SettingsType,
    GET_TURN_AROUND_TIMES,
    DELETE_TURN_AROUND_TIME,
    CREATE_TURN_AROUND_TIME,
} from './actionTypes';

export interface settingsState {
    turnAroundTime: {
        list: TurnAroundTimeResponse[];
        total: number;
        current_page: number;
        isLoadingData: boolean;
    };
}

const initialState: settingsState = {
    turnAroundTime: {
        list: [],
        total: 0,
        current_page: 0,
        isLoadingData: false,
    },
};

const reducer = (state = initialState, action: SettingsType): settingsState => {
    switch (action.type) {
        case GET_TURN_AROUND_TIMES:
        case DELETE_TURN_AROUND_TIME:
        case CREATE_TURN_AROUND_TIME:
            return {
                ...state,
                turnAroundTime: {
                    ...state.turnAroundTime,
                    isLoadingData: true,
                },
            };
        case GET_TURN_AROUND_TIMES_SUCCESS:
            return {
                ...state,
                turnAroundTime: {
                    ...action.payload,
                    isLoadingData: false,
                },
            };
        default:
            return {
                ...state,
                turnAroundTime: {
                    ...state.turnAroundTime,
                    isLoadingData: false,
                },
            };
    }
};

export default reducer;
