import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import SwitchButton from '../../../../components/ButtonForm/SwitchButton';
import fileIcon from '../../../../common/assets/images/file-icon.png';
import downloadedIcon from '../../../../common/assets/images/downloaded.png';
import { Search } from '../../../../components/Table/components/Search/Search';
import appServices from '../../../../common/services/appServices';
import { formatDate, formatDateByDay } from '../../../../common/helpers/DateFormatHelper';
import { sliceFileName } from '../../../../common/utils/converter';
import { GlobalState } from '../../../../redux';
import { TableConfig } from '../../../../components/Table/Table';
import { DictationStatus } from '../../../../common/constants/enum';
interface ToggleButtonProps {
    toggleSwitch: () => void;
    onClickOutSite: () => void;
    isOpen: boolean;
}

export interface SearchParamListDictation extends TableConfig {
    status?: DictationStatus;
    turnAroundTime?: number;
    fromDate?: Date;
    isExport?: boolean;
    includeEditorInCharge?: boolean;
    userId?: number;
    accountId?: number | null;
    isDownloaded?: boolean;
}

interface TranscriptionDTO {
    fileName: string;
    id: number;
    isDownloaded: boolean;
}

const DownloadLog: React.FunctionComponent<ToggleButtonProps> = ({
    toggleSwitch,
    isOpen,
    onClickOutSite,
}): React.ReactElement => {
    const { userInfo } = useSelector((state: GlobalState) => state.user);
    const [listTranscription, setListTranscription] = useState<TranscriptionDTO[]>([]);
    const initialTableConfig: any = {
        userId: userInfo.id,
        isDownloaded: true,
    };
    const [tableConfig, setTableConfig] = useState<SearchParamListDictation>(initialTableConfig);

    const getData = async (prop: SearchParamListDictation) => {
        try {
            const response = await appServices.getDictationTranscriptions(prop);
            if (response.success) {
                const listFormatDate = response.data.transcriptions.map((item: any) => ({
                    ...item,
                    createdAt: formatDate(item.createdAt),
                    updatedAt: formatDateByDay(item.updatedAt),
                    fileName: sliceFileName(item.fileName),
                }));
                setListTranscription([...listFormatDate]);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const modalRef = useRef<HTMLDivElement | null>(null);
    const handleOutsideClick = (e: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
            onClickOutSite();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    const handleSearch = (prop: string) => {
        getData({ ...tableConfig, searchValue: prop });
    };

    useEffect(() => {
        getData(tableConfig);
    }, []);

    const itemsUpdatedAt: Record<string, any[]> = {};

    listTranscription.forEach((items: any) => {
        if (!itemsUpdatedAt[items.updatedAt]) {
            itemsUpdatedAt[items.updatedAt] = [];
        }
        itemsUpdatedAt[items.updatedAt].push(items);
    });

    return (
        <div className={`menu-download-logs ${isOpen ? 'isShow' : ''}`} ref={modalRef}>
            <div className="menu-download-logs-items">
                <div className="header-historical d-sm-flex align-items-center justify-content-between my-3">
                    <div className="download-log">
                        <span className="download-log-title">Download Logs</span>
                        <SwitchButton toggleSwitch={toggleSwitch} isOn={isOpen} />
                    </div>
                </div>
                <Search isOpen={isOpen} onSearch={handleSearch} className={'search-download-log'}></Search>
                {Object.keys(itemsUpdatedAt).map((updatedAt) => (
                    <div key={updatedAt} className="mt-4">
                        {updatedAt}
                        <>
                            {itemsUpdatedAt[updatedAt].map((item) => (
                                <div key={item} className="download-items-container">
                                    <div className="download-items">
                                        <img src={fileIcon} className="file-icon" alt="" />
                                        <div className="title-file-download">
                                            <div className="file-id">{item.dictationId}</div>
                                            <span className="file-name">{item.fileName}</span>
                                        </div>
                                    </div>
                                    <img src={downloadedIcon} className="download-icon"></img>
                                </div>
                            ))}
                        </>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DownloadLog;
