import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import SortByDateIcon from '../../../../common/assets/images/IconComponent';
import { ActionSubject } from '../../../../common/constants/enum';
import { doctorRoute } from '../../../../common/constants/routes';

import './TableToolbarStyles.scss';

export interface ToolbarFilter {
    id?: number | string;
    value: string;
}

interface TableToolbarProps {
    textDeleteButton?: string;
    isDisableDeleteButton?: boolean;
    textAddNewButton?: string;
    handleAction?: (action: ActionSubject) => void;
    isShowFilterGroup?: boolean;
    listFilter?: ToolbarFilter[];
    setFilterValue?: (state: string) => void;
    isLeftSide?: boolean;
    placeholderSelect?: string;
    fixedSelect?: boolean;
    defaultValueFilter?: string;
}

const TableToolbar: React.FunctionComponent<TableToolbarProps> = ({
    textDeleteButton,
    textAddNewButton,
    isDisableDeleteButton,
    handleAction,
    isShowFilterGroup,
    listFilter,
    setFilterValue,
    isLeftSide,
    placeholderSelect,
    fixedSelect,
}) => {
    const [selectedOption, setSelectedOption] = React.useState('');
    const history = useHistory();
    const { id }: any = useParams();
    const [sortByDate, setSortBydate] = useState(false);
    const handleChange = (event: SelectChangeEvent) => {
        setFilterValue && setFilterValue(event.target.value);
        setSelectedOption(event.target.value);
    };

    const handleFilter = () => {
        if (history.location.pathname === `${doctorRoute.FINISHED_TRANSCRIPTIONS}/${id}`) setSortBydate(true);
    };

    useEffect(() => {
        handleFilter();
    }, []);

    return (
        <div className={`table-toolbar ${!isLeftSide ? 'float-right' : ''}`}>
            {textAddNewButton && (
                <button
                    className="btn btn-add-new ml-3 float-right mb-3"
                    onClick={() => handleAction && handleAction(ActionSubject.ADD)}
                >
                    {textAddNewButton}
                </button>
            )}

            {textDeleteButton && (
                <button
                    className="btn btn-delete ml-3 float-right mb-3"
                    disabled={isDisableDeleteButton}
                    onClick={() => handleAction && handleAction(ActionSubject.DELETE)}
                >
                    {textDeleteButton}
                </button>
            )}

            {isShowFilterGroup && !!listFilter?.length && (
                <>
                    {sortByDate ? (
                        <>
                            <FormControl sx={{ width: 180, height: 50, m: 1 }}>
                                <Select
                                    value={selectedOption}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    labelId="demo-select-small-label"
                                    IconComponent={SortByDateIcon}
                                    placeholder="Sort by date"
                                >
                                    {!fixedSelect && (
                                        <MenuItem value="" className="option-selected">
                                            Sort by date
                                        </MenuItem>
                                    )}
                                    <MenuItem value={'asc'}>Oldest to newest</MenuItem>
                                    <MenuItem value={'desc'}>Newest to oldest</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    ) : (
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text
                                    className={`btn-filter float-right px-3  mb-3 ${!isLeftSide ? 'ml-3' : ''}`}
                                >
                                    {placeholderSelect || 'Filter'}
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl sx={{ minWidth: 120 }}>
                                <Select
                                    value={selectedOption}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    MenuProps={{
                                        sx: {
                                            '& .MuiMenu-paper': {
                                                backgroundColor: 'dark.primary',
                                                color: 'text.light',
                                                fontSize: '12px',
                                            },
                                            '& .MuiMenuItem-root:hover': {
                                                backgroundColor: 'primary',
                                                color: 'text.white',
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: '#fff',
                                                color: '#6A7891',
                                                fontStyle: 'italic',
                                            },
                                            '& .MuiMenuItem-root': {
                                                fontSize: '14px',
                                                backgroundColor: '#fff',
                                                color: 'text.white',
                                            },
                                        },
                                    }}
                                    sx={{
                                        color: '#000',
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'gray',
                                        },
                                        '.MuiSvgIcon-root': {
                                            color: '#fff',
                                        },
                                        '&:before': {
                                            borderBottom: `1px solid #000`,
                                        },
                                        '&:hover': {
                                            ':before': {
                                                borderBottom: `1px solid #000`,
                                            },
                                        },
                                        '& .MuiMenuItem-root': {
                                            backgroundColor: 'dark.primary',
                                        },
                                        '& .MuiMenu-paper': {
                                            backgroundColor: 'dark.primary',
                                        },
                                    }}
                                >
                                    {!fixedSelect && (
                                        <MenuItem disabled value="" className="option-selected">
                                            Select State
                                        </MenuItem>
                                    )}
                                    {listFilter?.length &&
                                        listFilter.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                value={
                                                    item.id
                                                        ? item.id.toString()
                                                        : item.value
                                                        ? item.value.toString()
                                                        : undefined
                                                }
                                            >
                                                {item.value}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </InputGroup>
                    )}
                </>
            )}
        </div>
    );
};

export default TableToolbar;
