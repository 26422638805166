import React from 'react';

import trashIcon from '../../../../../common/assets/images/trashIcon.png';

export interface Actions {
    actionDelete: (idEIC: number) => void;
    currentUserId?: number;
    hideAllTrashIcon?: boolean;
    disabledAllTrashIcon?: boolean;
}

interface ActionsColumnFormatterProps {
    row: any;
    actions: Actions;
}

export const DictationsInProgressDetailColumnFormatter: React.FunctionComponent<ActionsColumnFormatterProps> = ({
    row,
    actions,
}): React.ReactElement => {
    return (
        <>
            {!actions?.hideAllTrashIcon ? (
                <div
                    title="Delete"
                    className="delete-icon"
                    onClick={() => {
                        if (actions.disabledAllTrashIcon) {
                            return;
                        }
                        if (actions.currentUserId) {
                            if (actions.currentUserId === row.user.id) {
                                actions.actionDelete(row.id);
                            }
                        } else {
                            actions.actionDelete(row.id);
                        }
                    }}
                >
                    <img src={trashIcon} alt="" />
                </div>
            ) : null}
        </>
    );
};
