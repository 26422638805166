export enum VerificationPage {
    LOGIN = 'LOGIN',
    SIGN_UP = 'SIGN_UP',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    FORGOT_PASS_CODE = 'FORGOT_PASS_CODE',
}

export enum SortType {
    ASC = 'asc',
    DESC = 'desc',
}

export enum ApiCode {
    NOT_FOUND = '404',
    CONFLICT = '409',
}

export enum ViewMode {
    ACCUMULATED_VIEW = 'ACCUMULATED_VIEW',
    MONTH_VIEW = 'MONTH_VIEW',
}

export enum DictationStatus {
    InProgress = 'InProgress',
    Unassign = 'Unassign',
    Submitted = 'Submitted',
    Canceled = 'Canceled',
    Finished = 'Finished',
}

export enum ActionSubject {
    ADD,
    DELETE,
    FILTER,
    UPDATE,
    UPLOAD,
    UNDEFINED,
}

export enum UserRole {
    Admin = 'Admin',
    Assignee = 'Assignee',
    Editor = 'Editor',
    Doctor = 'Doctor',
}

export enum UserStatus {
    Activated = 'Activated',
    Deactivated = 'Deactivated',
}

export enum HttpStatusCode {
    Unauthorized = 401,
}

export enum ActionAccount {
    TURN_ON_RETURN_EMAIL = 'TURN_ON_RETURN_EMAIL',
    TURN_OFF_RETURN_EMAIL = 'TURN_OFF_RETURN_EMAIL',
    ACTIVE_ACCOUNT = 'ACTIVE_ACCOUNT',
    INACTIVE_ACCOUNT = 'INACTIVE_ACCOUNT',
}

export enum ReturnEmailStatus {
    ON = 'ON',
    OFF = 'OFF',
}

export enum AccountStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}
export enum LinkMobileApp {
    APPSTORE = 'APPSTORE',
    CHPLAY = 'CHPLAY',
}
