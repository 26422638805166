import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import doctorDictationsServices from '../../../common/services/doctorDictationServices';
import emptyDictation from '../../../common/assets/images/upload-empty.png';
import { notify } from '../../../common/utils/notify';
import SubHeaderPage from '../../../components/SubHeaderPage/SubHeaderPage';
import TableToolbar from '../../../components/Table/components/TableToolbar/TableToolbar';
import { GlobalState } from '../../../redux';
import { getTurnAroundTimes } from '../../../redux/settings/action';

import FilesContainer, { FileType } from './components/FilesContainer/FilesContainer';
import { PopupUpload } from './components/PopupUpload/PopupUpload';
import './UploadDictationStyles.scss';

const UploadDictation: React.FC = (): React.ReactElement => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state: GlobalState) => state.user);
    const [audioFiles, setAudioFiles] = useState<File[]>([]);
    const [attachmentFiles, setAttachmentFiles] = useState<File[]>([]);
    const [remarks, setRemarks] = useState('');
    const [isClickUpload, setIsClickUpload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowDialog, setShowDialog] = useState(false);
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const [isUploadPage, setIsUploadPage] = useState(false);
    const turnAroundTime = useSelector((state: GlobalState) => state.settings.turnAroundTime.list);

    useEffect(() => {
        if (!turnAroundTime.length) {
            dispatch(
                getTurnAroundTimes({
                    sortBy: '',
                    page: 1,
                    pageSize: 30,
                    sortDirection: '',
                }),
            );
        }
    }, []);

    const handleUploadFiles = async () => {
        setIsClickUpload(true);
        if (!audioFiles.length) {
            return;
        }
        setIsLoading(true);
        const listFileName: string[] = [];
        try {
            const responseCreateDictation = await doctorDictationsServices.createDictation({
                remarks: remarks,
                status: 'Unassign',
                userId: userInfo.id,
            });
            if (responseCreateDictation.success) {
                await Promise.all(
                    audioFiles.map(async (audioFile) => {
                        const responseUploadAudio = await doctorDictationsServices.uploadAudio({
                            file: audioFile,
                            dictationId: responseCreateDictation.data.dictation.id + '',
                        });
                        if (responseUploadAudio?.success === true) {
                            listFileName.push(responseUploadAudio.data.record.fileName);
                        } else {
                            notify.error(`Fail to upload ${audioFile.name}`, 'Error');
                        }
                    }),
                );
                await Promise.all(
                    attachmentFiles.map(async (attachmentFile) => {
                        const responseUploadAttachment = await doctorDictationsServices.uploadAttachment({
                            file: attachmentFile,
                            dictationId: responseCreateDictation.data.dictation.id + '',
                        });
                        if (!responseUploadAttachment?.success) {
                            notify.error(`Fail to upload ${attachmentFile.name}`, 'Error');
                        }
                    }),
                );
            }
            await doctorDictationsServices.uploadSuccessFiles({
                files: listFileName,
                dictationId: responseCreateDictation.data.dictation.id,
            });
            setIsUploadSuccess(true);
            setShowDialog(true);
        } catch (error) {
            setIsUploadSuccess(false);
            setShowDialog(true);
            setIsLoading(false);
        } finally {
            resetPage();
            setIsLoading(false);
        }
    };

    const resetPage = () => {
        setAudioFiles([]);
        setAttachmentFiles([]);
        setRemarks('');
        setIsClickUpload(false);
    };

    const goUploadPage = () => {
        setIsUploadPage(true);
    };

    return (
        <>
            {isUploadPage ? (
                <div className="container">
                    <PopupUpload
                        isShowDialog={isShowDialog}
                        setShowDialog={setShowDialog}
                        isUploadSuccess={isUploadSuccess}
                    />
                    <SubHeaderPage title="Upload Dictation" />
                    <div className="main-content mb-5">
                        <FilesContainer
                            title={FileType.audio}
                            isUpload={isClickUpload}
                            files={audioFiles}
                            setFiles={setAudioFiles}
                            isLoading={isLoading}
                        />
                        <FilesContainer
                            title={FileType.attachment}
                            files={attachmentFiles}
                            setFiles={setAttachmentFiles}
                            isLoading={isLoading}
                        />
                        <div className="main-content__block">
                            <div className="title mt-2 mb-3">Turnaround time</div>
                            <div className="content float-left">
                                <TableToolbar
                                    isShowFilterGroup={true}
                                    listFilter={[
                                        {
                                            value:
                                                turnAroundTime.find((tat) => +tat.id === userInfo.turnAroundTimeId)
                                                    ?.value || '',
                                        },
                                    ]}
                                    fixedSelect={false}
                                    isLeftSide={true}
                                />
                            </div>
                        </div>
                        <div className="main-content__block">
                            <div className="title mt-2 mb-2">Remarks</div>
                            <div className="content float-left">
                                <textarea
                                    className="text-area"
                                    onChange={(e) => setRemarks(e.target.value)}
                                    name="remarks"
                                    rows={3}
                                    value={remarks}
                                />
                            </div>
                        </div>
                        <Button
                            className="blue-button mt-5 upload-dictation-btn"
                            onClick={handleUploadFiles}
                            disabled={isLoading}
                        >
                            Upload Dictation{' '}
                            {isLoading && (
                                <span className="ml-3">
                                    <Spinner animation="border" size="sm" variant="light" />
                                </span>
                            )}
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="empty-page">
                    <div className="empty-element">
                        <img src={emptyDictation} className="empty-uploadDictation" alt="" />
                        <p>Click “upload diction” to upload your audio and files</p>
                        <button onClick={goUploadPage} className="upload-btn">
                            Upload dictation
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default UploadDictation;
