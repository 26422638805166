import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';

import { AccountStatus, ActionAccount, ActionSubject, ReturnEmailStatus } from '../../../../../common/constants/enum';
import { AccountDTO } from '../../../../../common/services/apiTypes';
import appServices from '../../../../../common/services/appServices';
import { notify } from '../../../../../common/utils/notify';

import './ConfirmAccountDialogStyles.scss';

interface Props {
    isShowDialog: boolean;
    setShowDialog: (show: boolean) => void;
    setReloadPageType: (action: ActionSubject) => void;
    accountAction: ActionAccount | undefined;
    rowAccount: AccountDTO;
}

export const ConfirmAccountDialog: React.FunctionComponent<Props> = ({
    isShowDialog,
    setShowDialog,
    setReloadPageType,
    accountAction,
    rowAccount,
}) => {
    const [isLoading, setLoading] = useState<boolean>(false);

    const isInactive =
        accountAction === ActionAccount.ACTIVE_ACCOUNT || accountAction === ActionAccount.TURN_ON_RETURN_EMAIL;
    const textClass = isInactive ? 'text-blue' : 'text-red';

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (accountAction === ActionAccount.ACTIVE_ACCOUNT || accountAction === ActionAccount.INACTIVE_ACCOUNT) {
                const response = await appServices.updateAccountStatus(
                    {
                        accountStatus:
                            accountAction === ActionAccount.ACTIVE_ACCOUNT
                                ? AccountStatus.ACTIVE
                                : AccountStatus.INACTIVE,
                    },
                    rowAccount.id.toString(),
                );
                if (response.success) {
                    setReloadPageType(ActionSubject.UPDATE);
                    notify.success('Account status successfully updated.', 'Success');
                } else {
                    notify.error(response.message, 'Error');
                }
            } else {
                const response = await appServices.updateReturnEmailStatus(
                    {
                        returnEmailStatus:
                            accountAction === ActionAccount.TURN_OFF_RETURN_EMAIL
                                ? ReturnEmailStatus.OFF
                                : ReturnEmailStatus.ON,
                    },
                    rowAccount.id.toString(),
                );
                if (response.success) {
                    setReloadPageType(ActionSubject.UPDATE);
                    notify.success('Return email status successfully updated.', 'Success');
                } else {
                    notify.error(response.message, 'Error');
                }
            }
        } catch (error: any) {
            notify.error(error.message, 'Error');
        } finally {
            setLoading(false);
            setShowDialog(false);
        }
    };

    const renderContent = () => {
        switch (accountAction) {
            case ActionAccount.TURN_OFF_RETURN_EMAIL:
            case ActionAccount.TURN_ON_RETURN_EMAIL:
                return {
                    title: 'Change Return Email Status',
                    content1: 'Do you want to proceed in turning ',
                    content2: ' the return email status for ',
                    acceptButton: 'Yes',
                    rejectButton: 'Cancel',
                };
            case ActionAccount.ACTIVE_ACCOUNT:
                return {
                    title: 'Activate Client Account',
                    content1: 'You have selected to activate ',
                    content2:
                        'Activating this client account will enable all client user registered under this account, and it will allow all client access to the portal. All information related to this account such as historical information and transactions will be made available again.',
                    content3: 'Are you sure you want to continue?',
                    acceptButton: 'Activate',
                    rejectButton: 'Cancel',
                };
            case ActionAccount.INACTIVE_ACCOUNT:
                return {
                    title: 'Deactivate Client Account',
                    content1: 'You have selected to deactivate ',
                    content2:
                        "Deactivating this client account will disable all client user registered under this account, and it will deny all client access to the portal. All information related to this account such as historical information and transactions will be unavailable until they're activated again.",
                    content3: 'Are you sure you want to continue?',
                    acceptButton: 'Deactivate',
                    rejectButton: 'Cancel',
                };
            default:
                return;
        }
    };

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-center"
            centered
            show={isShowDialog}
            onHide={() => setShowDialog(false)}
        >
            <Modal.Header closeButton className={isInactive ? 'model-add' : 'model-delete'}>
                <Modal.Title id="contained-modal-title-center">{renderContent()?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {accountAction === ActionAccount.TURN_ON_RETURN_EMAIL ||
                accountAction === ActionAccount.TURN_OFF_RETURN_EMAIL ? (
                    <p>
                        {renderContent()?.content1}
                        <span className={textClass}>
                            {rowAccount.returnEmailStatus === ReturnEmailStatus.OFF
                                ? ReturnEmailStatus.ON
                                : ReturnEmailStatus.OFF}
                        </span>
                        {renderContent()?.content2}
                        <span className={textClass}>{rowAccount.accountName}</span>?
                    </p>
                ) : (
                    <>
                        <p>
                            {renderContent()?.content1}
                            <span className={textClass}>{rowAccount.accountName}</span>.
                        </p>
                        <p>{renderContent()?.content2}</p>
                        <p>{renderContent()?.content3}</p>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant={isInactive ? 'outline-primary cancel-button' : 'inactive-cancel-button'}
                    onClick={() => setShowDialog(false)}
                    className="px-4 py-1"
                >
                    {renderContent()?.rejectButton}
                </Button>
                <Button
                    variant="primary"
                    className={`px-4 py-1 ${isInactive ? 'save-button' : 'yes-button'}`}
                    onClick={() => handleSubmit()}
                    disabled={isLoading}
                >
                    {renderContent()?.acceptButton}
                    {isLoading && (
                        <span className="ml-2">
                            <Spinner animation="border" size="sm" variant="light" />
                        </span>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
