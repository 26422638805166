import React, { useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { ToolkitContextType } from 'react-bootstrap-table2-toolkit';
import { Spinner } from 'react-bootstrap';

import { Pagination } from '../Pagination/Pagination';

import { Search } from './components/Search/Search';
import customTotalItem from './components/CustomTotalItem/CustomTotalItem';

import './TableStyles.scss';

export interface TableConfig {
    page: number;
    pageSize: number;
    sortBy: string;
    sortDirection: string;
    searchValue?: string;
}

export interface PaginationOptions {
    custom: boolean;
    totalSize: number;
    sizePerPageList: { text: string; value: number }[];
    sizePerPage: number;
    page: number;
    paginationTotalRenderer?: (from: number, to: number, size: number) => JSX.Element;
}

interface TableProps {
    keyField?: string;
    data: any[];
    isDataLoading: boolean;
    getData: (config: any) => void;
    handleUploadAction?: () => void;
    tableConfig: TableConfig;
    paginationOptions?: PaginationOptions;
    columns: any[];
    title?: string;
    rowEvents?: (row: any) => void;
    rowClasses?: string;
    getHandleTableChange?: (type: string, newState: any) => void;
    tableToolbar?: React.ReactNode;
    isHideSearchBar?: boolean;
    selectRow?: any;
    expandRow?: any;
    tableClassName?: string;
    isHidePagination?: boolean;
    defaultInputSearch?: string;
    defaultSorted?: any;
}

const Table: React.FC<TableProps> = (props) => {
    const {
        getData,
        tableConfig,
        title,
        paginationOptions,
        isDataLoading,
        data,
        columns,
        rowEvents,
        rowClasses,
        getHandleTableChange,
        tableToolbar,
        isHideSearchBar,
        selectRow,
        expandRow,
        tableClassName,
        isHidePagination,
        defaultInputSearch,
        defaultSorted,
        handleUploadAction,
    } = props;

    if (paginationOptions) {
        paginationOptions.paginationTotalRenderer = customTotalItem;
    }

    useEffect(() => {
        if (!data.length && !defaultSorted) {
            getData(tableConfig);
        }
    }, []);

    return (
        <>
            <div className={`table-component ${tableClassName}`}>
                <PaginationProvider pagination={paginationOptions && paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => {
                        return (
                            <Pagination
                                isLoading={isDataLoading}
                                paginationProps={paginationProps}
                                isHidePagination={isHidePagination || !data.length}
                            >
                                <ToolkitProvider keyField="id" data={data} columns={columns} search>
                                    {(props: ToolkitContextType) => (
                                        <>
                                            {tableClassName !== 'padding-td' ? (
                                                <div className=" p-0 mt-2 mb-4 home-toolbar">
                                                    {!isHideSearchBar && (
                                                        <Search
                                                            {...props.searchProps}
                                                            defaultInputSearch={defaultInputSearch}
                                                        />
                                                    )}
                                                    {title?.length && (
                                                        <div className="p-0 mb-3">
                                                            <h2 className="mb-0 title-page">{title}</h2>
                                                        </div>
                                                    )}
                                                    <div className="p-0">{tableToolbar}</div>
                                                </div>
                                            ) : (
                                                <div className="template-toolbar">
                                                    {' '}
                                                    <Search
                                                        {...props.searchProps}
                                                        defaultInputSearch={defaultInputSearch}
                                                    />
                                                    <button onClick={handleUploadAction} className="template-btn">
                                                        Upload a template
                                                    </button>
                                                </div>
                                            )}

                                            <div className="card card-custom gutter-b example">
                                                <div className="card-body table-data">
                                                    <BootstrapTable
                                                        wrapperClasses="table-responsive"
                                                        bordered={false}
                                                        classes="table table-head-custom table-vertical-center overflow-hidden"
                                                        bootstrap4
                                                        remote
                                                        rowEvents={{
                                                            onClick: (e, row) => rowEvents && rowEvents(row),
                                                        }}
                                                        rowClasses={rowClasses}
                                                        selectRow={selectRow}
                                                        noDataIndication={
                                                            isDataLoading ? (
                                                                <Spinner animation="border" className="my-2" />
                                                            ) : (
                                                                <p className="no-data-content font-italic my-2">
                                                                    There&apos;s nothing to show here yet.
                                                                </p>
                                                            )
                                                        }
                                                        {...props.baseProps}
                                                        {...paginationTableProps}
                                                        expandRow={expandRow}
                                                        defaultSorted={defaultSorted}
                                                        onTableChange={getHandleTableChange}
                                                    ></BootstrapTable>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </ToolkitProvider>
                            </Pagination>
                        );
                    }}
                </PaginationProvider>
            </div>
        </>
    );
};

export default Table;
