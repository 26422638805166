import { TurnAroundTimeResponse } from '../../common/services/apiTypes';

export const GET_TURN_AROUND_TIMES = 'GET_TURN_AROUND_TIMES';
export const GET_TURN_AROUND_TIMES_SUCCESS = 'GET_TURN_AROUND_TIMES_SUCCESS';
export const GET_TURN_AROUND_TIMES_FAILURE = 'GET_TURN_AROUND_TIMES_FAILURE';
export const CREATE_TURN_AROUND_TIME = 'CREATE_TURN_AROUND_TIME';
export const CREATE_TURN_AROUND_TIME_SUCCESS = 'CREATE_TURN_AROUND_TIME_SUCCESS';
export const CREATE_TURN_AROUND_TIME_FAILURE = 'CREATE_TURN_AROUND_TIME_FAILURE';
export const DELETE_TURN_AROUND_TIME = 'DELETE_TURN_AROUND_TIME';
export const DELETE_TURN_AROUND_TIME_SUCCESS = 'DELETE_TURN_AROUND_TIME_SUCCESS';
export const DELETE_TURN_AROUND_TIME_FAILURE = 'DELETE_TURN_AROUND_TIME_FAILURE';

interface getTurnAroundTimeParams {
    sortBy: string;
    page: number;
    pageSize: number;
    sortDirection?: string;
}

interface getTurnAroundTimeResponse {
    list: TurnAroundTimeResponse[];
    total: number;
    current_page: number;
}

interface getTurnAroundTimesAction {
    type: typeof GET_TURN_AROUND_TIMES;
    payload: getTurnAroundTimeParams;
}

interface getTurnAroundTimesSuccessAction {
    type: typeof GET_TURN_AROUND_TIMES_SUCCESS;
    payload: getTurnAroundTimeResponse;
}

interface getTurnAroundTimesFailureAction {
    type: typeof GET_TURN_AROUND_TIMES_FAILURE;
}

interface createTurnAroundTimeParams extends getTurnAroundTimeParams {
    value: string;
}

interface createTurnAroundTimeAction {
    type: typeof CREATE_TURN_AROUND_TIME;
    payload: createTurnAroundTimeParams;
}

interface createTurnAroundTimeFailureAction {
    type: typeof CREATE_TURN_AROUND_TIME_FAILURE;
}

interface deleteTurnAroundTimeParams extends getTurnAroundTimeParams {
    id: string;
}

interface deleteTurnAroundTimeAction {
    type: typeof DELETE_TURN_AROUND_TIME;
    payload: deleteTurnAroundTimeParams;
}

interface deleteTurnAroundTimeFailureAction {
    type: typeof DELETE_TURN_AROUND_TIME_FAILURE;
}

type SettingsType =
    | getTurnAroundTimesAction
    | getTurnAroundTimesFailureAction
    | getTurnAroundTimesSuccessAction
    | createTurnAroundTimeAction
    | createTurnAroundTimeFailureAction
    | deleteTurnAroundTimeAction
    | deleteTurnAroundTimeFailureAction;

export type {
    getTurnAroundTimeParams,
    getTurnAroundTimeResponse,
    getTurnAroundTimesAction,
    createTurnAroundTimeParams,
    createTurnAroundTimeAction,
    deleteTurnAroundTimeParams,
    deleteTurnAroundTimeAction,
    SettingsType,
};
